export {
    setUser,
    fetchNodes,
    setNodes,
    filterNodes,
    fetchEntries,
    setEntries,
    setEntriesForeign,
    fetchLatestEntries,
    resetFilteredNodesForNewEntry,
    setLoadingOverlayControllerToTrue,
    setLoadingOverlayControllerToFalse,
    fetchOne,
    fetchAnomalies,
    //fixSingleAnomaly,
    fetchEntriesByContent,
    updateLocalNodesAfterAddingANewOne,
    setDataEntriesSymbolTable,
    setNodesSymbolTable,
    filterEntriesOnChipClick,
    handleDeleteChipDuringSearch,
    deleteFromReduxToo,
    setCustomData,
    changeToMode2,
    fetchLatestEntriesMode2,
    changeModeFrom1to3AndBack,
    resetMem,
    changeToSpecificMode,
    setAnomalies,
    removeFromDataentriesAfterDelete,
    setPinpointedNodes,
    fetchEntriesForPinpointedNode,
    fetchLatestEntriesMode3,
    changeADataEntryVote,
    changeADataEntry,
    setEntriesForeignPlusExtraState
 } from './signIn';
