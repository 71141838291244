import React, { Component } from 'react';
import { useEffect } from 'react';

import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import Button from '@material-ui/core/Button';
//import { Icon } from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/Error';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import withStyles from '@material-ui/core/styles/withStyles';
import InputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { memo } from 'react';

//for advanced data entry types
import { useState } from 'react'
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import blue from '@material-ui/core/colors/blue';
import { Stitch } from 'mongodb-stitch-browser-sdk'
import strictUriEncode from 'strict-uri-encode';




const styles1 = theme => ({


  boxForSubmitForm: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    width: '90%',
    margin: 'auto',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'cornflowerblue',
    borderRadius: '5px',
    backgroundColor: '#000011',
    marginTop: '5px',
    color: "#adadad"
  },
  button: {
    margin: '5px',
  },
  checkboxiconOK: {
      margin: theme.spacing.unit,
      fontSize: 32,
      color: '#03ff03'
      },
      erroricon: {
        margin: theme.spacing.unit,
        fontSize: 32,
        color: 'red'
      },
      // dataentryeditorbox : {
      //   display: 'flex',
      //   height: '2em',
      //   width: 'auto',
      //   borderStyle: 'solid',
      //   backgroundColor: '#000011',
      //   borderColor: 'cornflowerblue',
      //   borderWidth: '1px',
      //   margin: '5px',
      //   marginBottom: '0px',
      // },
      // DATAENTRYINPUTOUTERBOX : {
      //   display: 'flex',
      //   height: '50%',
      //   margin: '5px',
      //
      //   borderStyle: 'solid',
      //   backgroundColor: '#000011',
      //   borderColor: 'cornflowerblue',
      //   borderWidth: '1px',
      //   marginTop: '0px',
      //
      // },

  input: {
      color: '#adadad',
      height: '4em', //think 14px for 1em
      textAlign: 'center',
      width: '100%',
      backgroundColor: '',
  },
  inputCrux: {

    whiteSpace: 'pre-line',
    fontSize: '1.4em',
    lineHeight: '1.6em',
    color: "#ececec",
    width: '90%',
    overflow: 'hidden',

  },
  fbutton: {
    backgroundColor: '#84ff90'
  },
  list: {

    backgroundColor: '#ffffff',//'#b1b1ff',//#5353c9
    padding: '0px',
    //opacity: 0.9,
    margin: '20px',
  },
  inputNode: {
      color: '#adadad',
      height: '4em', //think 14px for 1em
      textAlign: 'center',
      width: '100%',
      backgroundColor: '',
      textTransform: 'uppercase'
  },
  inputParent: {
      color: '#adadad',
      height: '4em', //think 14px for 1em
      textAlign: 'center',
      width: '100%',
      backgroundColor: '',
      textTransform: 'uppercase'
  },
  inputDeepFormultiline: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      color: '#adadad',
      width: '100%',
      backgroundColor: '',
      height: 'auto',
  },
  label: {
    color: '#adadad',
  },
  root: {
    marginLeft: '8px',
  },
  adsdialog: {
    backgroundColor:'#000017',
    opacity: 0.9
  },
  NODEINPUTOUTERBOX : {
    display: 'flex',
    alignItems: 'center',
    margin: '5px',

    borderStyle: 'solid',
    backgroundColor: '#000011',
    borderColor: 'cornflowerblue',
    borderWidth: '1px',


  },
  SEARCHITEMSINPUTOUTERBOX : {
    display: 'flex',
    alignItems: 'center',
    margin: '20px',
    textAlign: 'center',

    borderStyle: 'solid',
    backgroundColor: '#000011',
    borderColor: 'cornflowerblue',
    borderWidth: '1px',
    borderRadius: '12px'


  },
  sfouterdiv: {
    backgroundColor: '#000011',
    width: '100%'
  },
  thingsundersearchbarouter: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1em',
    justifyContent: 'center',
    alignItems: 'center'
  }

});



const AdvancedDataentrySelector = memo(({ submitNewEntry,moveToEditor, classes }) => {

const [dt, setDT] = useState(0);
const dts = ["LIST"]

const [listtype, setlisttype] = useState(0);

const listtypes = ["ANIME","GAME", "LIST", "MEAL", "REDDIT COMMUNITY", "YOUTUBE CHANNEL"]
const cruxdeftext =
`Write the crux, the most critical points about this item here.
Keep it less than 80 characters or people will skip it....
Short and sweet, don't waste people's time.

`

const searchTextFieldRef = React.useRef(null);
const [isNewObject, setisNewObject] = useState(false);
const cruxTextFieldRef = React.useRef(null)
const ytlinkRef = React.useRef(null)
const linkRef = React.useRef(null) // needed for lists or for links for example
const [dialogIsOpen, setdialogIsOpen] = useState(false);
//const [showOnlySearchbar, setshowOnlySearchbar] = useState(false);
const [listchosen, setlistchosen] = useState({}); // holds the object info the user clicked on.
const KT = {"GAME": {superfilters: ["Story Score", "Gameplay Score", "GPX Score", "Difficulty Score - Higher is More difficult", "Relaxing Score", "Drug (Addictiveness) Score"], superfilterssleutel: ["story", "gameplay", "gpx", "diff", "relaxing", "drug"]},
            "ANIME": {superfilters: ["Story Score", "Character Score", "GPX Score", "Drug (Addictiveness) Score", "Romance Score", "Comedy Score", "Slice Of Life Score"], superfilterssleutel: ["story", "characters", "gpx", "drug", "romance", "comedy", "sol"]},
            "ANIME OP": {superfilters: ["GPX Score", "Drug (Addictiveness) Score"], superfilterssleutel: ["gpx","drug"]},
            "REDDIT COMMUNITY": {superfilters: ["Wholesome"], superfilterssleutel: ["wholesome"]},
            "MEAL": {superfilters: ["Price Score", "Volume Score", "Health Score", "Yummy Score"], superfilterssleutel: ["price", "volume", "health", "yummy"]},
            "LIST": {superfilters: [], superfilterssleutel: [], showDlink: true},
            "YOUTUBE CHANNEL": {superfilters: [], superfilterssleutel: [], objDims: ["estimatedSubs", "renown"], showDlink: true}
          }

const [autosearchFoundItems, setautosearchFoundItems] = useState([]);
const [finallistdocument, setfinallistdocument] = useState({datatype: "LIST", comprisedOf: [], changes: []})
const [itemcount, setitemcount] = useState(0);
const [superfilters, setsuperfilters] = useState(KT[listtypes[listtype]].superfilters)
const [superfilterssleutel, setsuperfilterssleutel] = useState(KT[listtypes[listtype]].superfilterssleutel)
const superfilterRefs = [React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null),React.useRef(null),]; // created a fixed length array for n superfilters, you will need to at least keep it the same size as the longest amount of superfilters, currently game has 6
function handleClose() {
  setdialogIsOpen(!dialogIsOpen);
}
function clickedListItem(e, i) {//e is the full document information so we need to extract that info here. 


// if(!listtype)//determine and set list type only with first item
// {
//   setlisttype(dts_insidelist[dt_insidelist])
// }
  //console.log(i, autosearchFoundItems.length)
if(i === autosearchFoundItems.length-1) // new item
{


//searchTextFieldRef.current.value = autosearchFoundItems[i].title
setisNewObject(true);
setautosearchFoundItems([])
return
}


searchTextFieldRef.current.value = e.title
setautosearchFoundItems([])
setlistchosen(e)

if(listtypes[listtype] === "LIST" || listtypes[listtype] === "YOUTUBE CHANNEL")// if we click on AN ALREADY EXISTING item we can set the Link searching box text automatically, since we know what it is,
// but be careful, not ALL items like games etc has a link attribute that you set on them, so you need to specifically specify this since List of Lists is special.
{
linkRef.current.value = e.dlink
}

//setshowOnlySearchbar(true)





}

function validateNumberField(str, index) {

if(str.length>2 && str != "100")
{
  //set to 100
  superfilterRefs[index].current.value = parseInt(str.substring(0,2))
}
if(parseInt(str) < 0)
{
  superfilterRefs[index].current.value = 0
}


}

function modifyAndSaveAllFieldsOfItem() {

//Step 1 only save superfilter in changes if user entered it, otherwise use default value.

let i = {};

if(cruxTextFieldRef.current.value != "")
{
  i.notes = cruxTextFieldRef.current.value
}

if(isNewObject) // if new object or game we need to go add a title, only new objects have titles inside changes
{
  i.title = searchTextFieldRef.current.value
  i.dlink = ytlinkRef.current.value
  ytlinkRef.current.value = ""
}
else { // If its an object from the database, go and make relevant changes, including extracting and inserting objective dimensions. 



  if(listtypes[listtype] === "LIST" || listtypes[listtype] === "YOUTUBE CHANNEL")
  {

    i.title = searchTextFieldRef.current.value
    i.dlink = linkRef.current.value

    console.log(linkRef.current.value);
    linkRef.current.value = "";

  }
  console.log(KT[listtypes[listtype]].objDims);

  if(KT[listtypes[listtype]].objDims !== undefined)
  {
    for(let x = 0; x < KT[listtypes[listtype]].objDims .length; x++)
    {
      
      i[KT[listtypes[listtype]].objDims[x]] = listchosen[KT[listtypes[listtype]].objDims[x]]

      
    }
  }

}


for(let x = 0; x < KT[listtypes[listtype]].superfilters.length; x++)
//Notice the length here, it will run for HOW MANY SUPERFILTERS THE CURRENT LISTTYPE HAS, there are actually MORE refs in the array, we had to declare
// it explicitly like taht because hooks were strict in that way.
{
//console.log(superfilterssleutel[x], superfilterRefs[x].current.value)
let sclaricValue = parseInt(superfilterRefs[x].current.value)
  if(isNaN(sclaricValue)) // If the user didn't enter a value, simply don't add it to the final array.
  {
//Do nothing
  }
  else
  {
    i[superfilterssleutel[x]] = parseInt(superfilterRefs[x].current.value)
  }


superfilterRefs[x].current.value = "";//reset to blank for next item to be added

}
cruxTextFieldRef.current.value = "";
searchTextFieldRef.current.value = "";

let newfld = finallistdocument
if(!isNewObject)
{
  newfld.comprisedOf.push(listchosen._id)
}

newfld.changes.push(i)

setfinallistdocument(newfld)

searchTextFieldRef.current.focus();


console.log(finallistdocument)
// if(cruxTextFieldRef.current.value.length === 0)
// {
//
// }
//validate sfs



}

//https://react.nearch.com/brains/brainid/lists/top-anime-ops

async function setRefStatesThenSubmitNewDataentry() {

await moveToEditor(4); //if you don't wait it will call submitNewEntry before that.
finallistdocument.listtype = listtypes[listtype]
submitNewEntry(undefined, finallistdocument)

console.log(finallistdocument)
}


async function searchObjects(t) {
//  if(!isSearching)
  //{
  //  setisSearching(true)
    const client = Stitch.defaultAppClient;
    let s = await client.callFunction("kryTitlesMode3", [t])
    s.push({title: t})
    setautosearchFoundItems(s)
//    setisSearching(false)

//  }

}

function cycleListType() {
  if(listtype === listtypes.length-1){
    console.log("if 1")
    setlisttype(0)
    setsuperfilters(KT[listtypes[0]].superfilters)
    setsuperfilterssleutel(KT[listtypes[0]].superfilterssleutel)

  } else{
    console.log("if 2")
    setsuperfilters(KT[listtypes[listtype+1]].superfilters)
    setsuperfilterssleutel(KT[listtypes[listtype+1]].superfilterssleutel)
    setlisttype(listtype+1)
  }
}
console.log('rendered in func component')
  return (
    <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
    <div className={classes.NODEINPUTOUTERBOX}>
    <div style={{width: '100%', height: '4em'}}>
    <div onClick={() => {dt === dts.length-1 ? setDT(0): setDT(dt+1)}} style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%"}}>
    {dts[dt]}

    </div>

    </div>
    </div>
    {dts[dt] === "LIST" &&
    <div className={classes.NODEINPUTOUTERBOX}>
    <div style={{width: '100%', height: '4em'}}>
    <div onClick={() => {cycleListType()}} style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%"}}>
    {listtypes[listtype] + " LIST"}

    </div>

    </div>
    </div>}
    <Button variant="contained" color="primary" className={classes.button} onClick={() => {setdialogIsOpen(!dialogIsOpen)}} >
             {"GO"}
           </Button>

    {dts[dt] === "LIST" &&
      <Dialog classes={{paper: classes.adsdialog}}open={dialogIsOpen} fullScreen={true} onClose={handleClose} aria-labelledby="simple-dialog-title">

      <div style={{width: '100%', display: 'flex', flexDirection: 'column', height: '4em'}}>
      <div className={classes.SEARCHITEMSINPUTOUTERBOX}>
      <div style={{width: '100%', height: '4em'}}>
      <div style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%"}}>
      <InputBase inputRef={searchTextFieldRef} onClick={()=>{searchTextFieldRef.current.value = ""}} className={classes.input} onChange={(e) => {searchObjects(e.target.value)}} defaultValue="Search Items (Games, Anime, Manga).." placeholder="Search Items (Games, Anime, Manga).." inputProps={{className: classes.input}}/>

      </div>

      </div>
      </div>

      </div>
      <List classes={{
              root: classes.list
            }}>
        {autosearchFoundItems.map((item,i) => (
          <ListItem divider button key={item._id} disabled= {listtypes[listtype] === "LIST" && i === autosearchFoundItems.length-1} onClick={()=>{clickedListItem(item, i)}}>
            {i === autosearchFoundItems.length-1 ? <ListItemText primary={searchTextFieldRef.current.value} secondary={"Add "+searchTextFieldRef.current.value+ " as a new object/game/anime...."} />: <ListItemText primary={item.title + " [" + item.datatype+"]" }/>}
          </ListItem>

        ))}
      </List>
         <div className={classes.thingsundersearchbarouter}>


           <div style={{
           width: "100%",
           margin: "auto",
           marginLeft: '20px',
           marginRight: '20px',
           borderStyle: "solid",
           borderColor: "cornflowerblue",
           borderWidth: "1px",
           marginTop: "1em",
           fontSize: "2em",
           textAlign: "center",
           color: "cornflowerblue",
           borderLeft: "0px",
           borderRight: "0px",
           marginBottom: '2rem',
           fontFamily: "Times New Roman, Helvetica, Arial, sans-serif",
           }
           }>
           {"Crux"}
           </div>


           <div style={{
                  backgroundColor: "#333333",
                  padding: '10px',
                  whiteSpace: 'pre-line',
                  fontSize: '0.7em',
                  margin: '10px 10px 10px 10px',
                  lineHeight: '1.6em',
                  overflow: 'hidden',
                  width: '100%'

                }}
              >
      <InputBase inputRef={cruxTextFieldRef} multiline className={classes.inputCrux} onClick={()=>{if(cruxTextFieldRef.current.value === cruxdeftext){cruxTextFieldRef.current.value=""}}}
      defaultValue=
{cruxdeftext}/>
                </div>

                <div style={{
                width: "100%",
                margin: "auto",
                marginLeft: '20px',
                marginRight: '20px',
                borderStyle: "solid",
                borderColor: "cornflowerblue",
                borderWidth: "1px",
                marginTop: "1em",
                fontSize: "2em",
                textAlign: "center",
                color: "cornflowerblue",
                borderLeft: "0px",
                borderRight: "0px",
                marginBottom: '2rem',
                fontFamily: "Times New Roman, Helvetica, Arial, sans-serif",
                       '&:hover': {
                         //backgroundColor: '#1F1F1F',
                         borderColor: "gold",
                         color: 'gold'
                         //opacity: [0.9, 0.8, 0.7],

                       },

                }
                }>
                {"Superfilters"}
                </div>

                <div className={classes.sfouterdiv}>

                {superfilters.map((item, index) => {
                  return(
                  <div className={classes.SEARCHITEMSINPUTOUTERBOX}>
                  <div style={{width: '100%', height: '4em'}}>
                  <div style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%"}}>
                  <InputBase placeholder={item} inputRef={superfilterRefs[index]} type="number" onChange={(e) => {validateNumberField(e.target.value, index)}} className={classes.input} defaultValue={item} inputProps={{className: classes.input}}/>

                  </div>

                  </div>
                  </div>
                )})}
                </div>
                <div style={{
                width: "100%",
                margin: "auto",
                marginLeft: '20px',
                marginRight: '20px',
                borderStyle: "solid",
                borderColor: "cornflowerblue",
                borderWidth: "1px",
                marginTop: "1em",
                fontSize: "2em",
                textAlign: "center",
                color: "cornflowerblue",
                borderLeft: "0px",
                borderRight: "0px",
                marginBottom: '2rem',
                fontFamily: "Times New Roman, Helvetica, Arial, sans-serif",
                       '&:hover': {
                         //backgroundColor: '#1F1F1F',
                         borderColor: "gold",
                         color: 'gold'
                         //opacity: [0.9, 0.8, 0.7],

                       },

                }
                }>
                {"Extra"}
                </div>
                {listchosen.dlink && (!KT[listtypes[listtype]].showDlink) && <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div className={classes.SEARCHITEMSINPUTOUTERBOX}>
                <div style={{width: '100%', height: '4em'}}>
                <div style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%"}}>
                <InputBase fullWidth placeholder="Youtube video link https://www.youtube.com/...." onClick={()=>{ytlinkRef.current.value = ""}}inputRef={ytlinkRef} className={classes.input} inputProps={{className: classes.input}}/>

                </div>

                </div>
                </div>





                </div>}

                {//Used in the case of lists but especially used in the case of LINKS/SITES objects
                  KT[listtypes[listtype]].showDlink && <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}><div className={classes.SEARCHITEMSINPUTOUTERBOX}>
                                  <div style={{width: '100%', height: '4em'}}>
                                  <div style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%"}}>
                                  <InputBase fullWidth placeholder="Link... (Auto-added when selected via search box)" onClick={()=>{linkRef.current.value = ""}}inputRef={linkRef} className={classes.input} inputProps={{className: classes.input}}/>

                                  </div>

                                  </div>
                                  </div>
                                  </div>

                }


                <div style={{
                width: "100%",
                margin: "auto",
                marginLeft: '20px',
                marginRight: '20px',
                borderStyle: "solid",
                borderColor: "cornflowerblue",
                borderWidth: "1px",
                marginTop: "1em",
                fontSize: "2em",
                textAlign: "center",
                color: "cornflowerblue",
                borderLeft: "0px",
                borderRight: "0px",
                marginBottom: '2rem',
                fontFamily: "Times New Roman, Helvetica, Arial, sans-serif",
                       '&:hover': {
                         //backgroundColor: '#1F1F1F',
                         borderColor: "gold",
                         color: 'gold'
                         //opacity: [0.9, 0.8, 0.7],

                       },

                }
                }>
                {"Finish"}
                </div>
                <Button size="large" fullWidth variant="contained" color="primary" className={classes.button} onClick={() => {modifyAndSaveAllFieldsOfItem(); setitemcount(itemcount+1)}} >
                         {"Add Item To List"}
                       </Button>
                       <Button size="large" fullWidth variant="contained" color="secondary" disabled={itemcount===0} className={classes.fbutton} onClick={() => {setRefStatesThenSubmitNewDataentry()}} >
                                {"Finish List of " + itemcount + " items"}
                              </Button>
         </div>
       </Dialog>
             }
    </div>

  )
});


const NodeTextbox1 = memo(({ validateNODE, nodeInput, nodeInputClicked, validateUNSAFEBASICNode, lockInNodeAndParentAfterClickOnIndividualNeurotag,editNode, classes }) => {
  return (

    <div className={classes.NODEINPUTOUTERBOX}>
    {(validateNODE) ? <CheckBoxIcon className={classes.checkboxiconOK} /> : <ErrorIcon className={classes.erroricon} />}
     <InputBase inputRef={nodeInput} readOnly= {editNode ? false : lockInNodeAndParentAfterClickOnIndividualNeurotag} className={classes.input} defaultValue="Please type in node name here..." inputProps={{className: classes.inputNode}} onClick={nodeInputClicked} onChange={validateUNSAFEBASICNode} />
    {(validateNODE) ? <CheckBoxIcon className={classes.checkboxiconOK} /> : <ErrorIcon className={classes.erroricon} />}
    </div>

  )
});

const TitleTextbox1 = memo(({ validateTITLE, titleInput, titleInputClicked, validateUNSAFEBASICTitle, classes }) => {
  return (

    <div id="haxel2" className={classes.NODEINPUTOUTERBOX}>
    {((validateTITLE === true)) ? <CheckBoxIcon className={classes.checkboxiconOK} /> : <ErrorIcon className={classes.erroricon} />}
     <InputBase  inputRef={titleInput} className={classes.input} defaultValue="Please type in title here..." inputProps={{className: classes.input}} onClick={titleInputClicked} onChange={validateUNSAFEBASICTitle} />
   {((validateTITLE === true)) ? <CheckBoxIcon className={classes.checkboxiconOK} /> : <ErrorIcon className={classes.erroricon} />}
    </div>

  )
});

// const TimeTextbox1= memo(({ validateTIMESPENT, timeInput, minutesInputClicked, validateUNSAFEBASICTime, classes }) => {
//   return (
//     <div className={classes.NODEINPUTOUTERBOX}>
//     {((validateTIMESPENT === true)) ? <CheckBoxIcon className={classes.checkboxiconOK} /> : <ErrorIcon className={classes.erroricon} />}
//      <InputBase  inputRef={timeInput} className={classes.input} defaultValue="Please type in minutes spent on this node here..." inputProps={{className: classes.input}} onClick={minutesInputClicked} onChange={validateUNSAFEBASICTime} />
//    {((validateTIMESPENT === true)) ? <CheckBoxIcon className={classes.checkboxiconOK} /> : <ErrorIcon className={classes.erroricon} />}
//     </div>
//
//
//   )
// });


const ParentTextbox1= memo(({ validatePARENT, parentInput, parentInputClicked, validateUNSAFEBASICParent, keyPressedAtParentInput, lockInNodeAndParentAfterClickOnIndividualNeurotag,editNode, classes }) => {
  return (
<div className={classes.NODEINPUTOUTERBOX}>
{((validatePARENT === true)) ? <CheckBoxIcon className={classes.checkboxiconOK} /> : <ErrorIcon className={classes.erroricon} />}
 <InputBase inputRef={parentInput} readOnly= {editNode ? false : lockInNodeAndParentAfterClickOnIndividualNeurotag} className={classes.input} defaultValue="Please type in the parent node here..." inputProps={{className: classes.inputParent}} onClick={parentInputClicked} onChange={ (event) => {validateUNSAFEBASICParent(event)}} />
 {((validatePARENT === true)) ? <CheckBoxIcon className={classes.checkboxiconOK} /> : <ErrorIcon className={classes.erroricon} />}
</div>

)
});



// const DataentryTextbox1= memo(({ insertCode, clear, dataentryInput, validateUNSAFEBASICDataEntry, classes }) => {
//   return (
//     <div id="multilineinputouterdiv">
//     <div className={classes.dataentryeditorbox}>
//     <Button variant="contained" size="small" color="primary" className={classes.margin} onClick={insertCode}>
//         {"<code>"}
//       </Button>
//       <Button variant="contained" size="small" color="primary" className={classes.margin} onClick={clear}>
//           {"CLEAR"}
//      </Button>
//     </div>
//
//     <div className={classes.DATAENTRYINPUTOUTERBOX}>
//      <InputBase inputRef={dataentryInput} multiline={true} rows={23} className={classes.inputDeepFormultiline} defaultValue="Please type in your data entry here." inputProps={{className: classes.inputDeepFormultiline}} onChange={validateUNSAFEBASICDataEntry}/>
//
//     </div>
//     </div>
//
//
// )
// });

const MyNewEntryBox = React.memo(function MyNewEntryBoxMemo(props) {

  const { classes } = props;

  const [state, setState] = React.useState({
     checkedA: props.editor === 1 ? props.newEntryPrevCheckboxOrNew : props.prevPrivateCheckBox,
     checkedB: props.editor === 1 ? props.newEntryPrevFavCheckboxOrNew : props.prevFavCheckBox,
     checkedC: props.editor === 1 ? props.newEntryPrevTop10CheckboxOrNew : props.prevTop10CheckBox
     //checkedD: props.editor === 1 ? props.newEntryPrevforbidCommentsCheckboxOrNew : props.prevForbidCommentsCheckbox


   });

   const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  useEffect(() => {
    if(props.editMode){

      props.nodeInput.current.value = props.rowToEdit.node;
      props.titleInput.current.value = props.rowToEdit.title;
      //props.timeInput.current.value = props.rowToEdit.timespent;
      props.parentInput.current.value = props.rowToEdit.parent;


      props.validateUNSAFEBASICNode();
      //props.validateUNSAFEBASICTime();
      props.validateUNSAFEBASICTitle();
      props.validateUNSAFEBASICParent();
    }

    if(props.editNode){
      props.nodeInput.current.value = "Start typing in the node you want to edit and click on it above."
      props.parentInput.current.value = "Enter a new parent for the above node."

    }

    // if(props.editor != 2)
    // {
    //   props.privateCheckbox.current.checked = props.prevPrivateCheckBox
    // }


  }, [])

    return (
      <div className={classes.boxForSubmitForm}>


              <NodeTextbox1 validateNODE={props.validateNODE} nodeInput={props.nodeInput} nodeInputClicked={props.nodeInputClicked} validateUNSAFEBASICNode={props.validateUNSAFEBASICNode} lockInNodeAndParentAfterClickOnIndividualNeurotag={props.lockInNodeAndParentAfterClickOnIndividualNeurotag} editNode={props.editNode} classes={classes}/>
              {(!props.editNode) ? <TitleTextbox1 validateTITLE={props.validateTITLE} titleInput={props.titleInput} titleInputClicked={props.titleInputClicked} validateUNSAFEBASICTitle={props.validateUNSAFEBASICTitle} classes={classes}/> : false}
              <ParentTextbox1 validatePARENT={props.validatePARENT} parentInput={props.parentInput} parentInputClicked={props.parentInputClicked} validateUNSAFEBASICParent={props.validateUNSAFEBASICParent} lockInNodeAndParentAfterClickOnIndividualNeurotag={props.lockInNodeAndParentAfterClickOnIndividualNeurotag} editNode={props.editNode} classes={classes}/>



              {(!props.editNode) ? <div className={classes.NODEINPUTOUTERBOX}>
              <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkedA}
              onChange={handleChange('checkedA')}
              value="checkedA"
              inputRef={props.privateCheckbox}
            />
          }
          label="Private Entry"
          classes={{
        root: classes.root,
        label: classes.label, // class name, e.g. `classes-nesting-label-x`
      }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkedB}
              onChange={handleChange('checkedB')}
              value="checkedB"
              inputRef={props.favCheckbox}
            />
          }
          label="Favorite"
          classes={{
        root: classes.root,
        label: classes.label, // class name, e.g. `classes-nesting-label-x`
      }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkedC}
              onChange={handleChange('checkedC')}
              value="checkedC"
              inputRef={props.top10Checkbox}
            />
          }
          label="Top 10"
          classes={{
        root: classes.root,
        label: classes.label, // class name, e.g. `classes-nesting-label-x`
      }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkedD}
              onChange={handleChange('checkedD')}
              value="checkedD"
              inputRef={props.advancedDataentryCheckbox}
            />
          }
          label="Advanced Data Type"
          classes={{
        root: classes.root,
        label: classes.label, // class name, e.g. `classes-nesting-label-x`
      }}
        />
        </FormGroup>

        </div> : false}

                {state.checkedD && <AdvancedDataentrySelector classes={classes} submitNewEntry={props.submitNewEntry} moveToEditor={props.moveToEditor}/> }
               {!state.checkedD ? (props.editMode) ? <Button variant="contained" color="primary" disabled={!(props.validateTITLE && props.validateNODE && props.validatePARENT) } className={classes.button} onClick={()=>{props.moveToEditor(3);}} >
                   EDIT
                 </Button> : (props.editNode) ?
                 <Button variant="contained" color="primary" className={classes.button} onClick={props.clickedToDeepChangeNodeOrParent} disabled={!props.lockInNodeAndParentAfterClickOnIndividualNeurotag}>
                     EDIT NODE
                   </Button> :
                 // <Button variant="contained" color="primary" disabled={!(props.validateTITLE && props.validateNODE && props.validatePARENT && props.validateTIMESPENT && props.validateDATAENTRY) } className={classes.button} onClick={props.submitNewEntry} >
                 //          SUBMIT
                 //        </Button>}
                        <Button variant="contained" color="primary" disabled={!(props.validateTITLE && props.validateNODE && props.validatePARENT) } className={classes.button} onClick={() => {props.moveToEditor(1)}} >
                                 NEXT
                               </Button> :false}
               </div>
  )

});

MyNewEntryBox.whyDidYouRender = true
MyNewEntryBox.propTypes = {
  validateNODE: PropTypes.bool,
  nodeInput: PropTypes.object,
  nodeInputClicked: PropTypes.func,
  validateUNSAFEBASICNode: PropTypes.func,

  validateTITLE: PropTypes.bool,
  titleInput: PropTypes.object,
  titleInputClicked: PropTypes.func,
  validateUNSAFEBASICTitle: PropTypes.func,

  validatePARENT: PropTypes.bool,
  parentInput: PropTypes.object,
  parentInputClicked: PropTypes.func,
  validateUNSAFEBASICParent: PropTypes.func,

  insertCode: PropTypes.func,
  clear: PropTypes.func,
  validateUNSAFEBASICDataEntry: PropTypes.func,
  editMode: PropTypes.bool,
  editEntry: PropTypes.func,
  submitNewEntry: PropTypes.func,
  lockInNodeAndParentAfterClickOnIndividualNeurotag: PropTypes.bool,


};


export default withErrorHandler( withStyles(styles1)(MyNewEntryBox) );
