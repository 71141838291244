import React, { Component } from 'react';
import { connect } from 'react-redux';

import Aux from '../beesaux/beesaux';

const layout = props => {
        return (
            <Aux>
                    {props.children}
            </Aux>
        )
}
export default connect()( layout );
