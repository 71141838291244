import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Aux from '../../hoc/beesaux/beesaux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import { connect } from 'react-redux';
import { Stitch } from 'mongodb-stitch-browser-sdk'
import {
  Redirect
} from 'react-router-dom';
import "./App.css"
import BubbleChart from '@weknow/react-bubble-chart-d3';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import * as actions from '../../store/actions/index';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';



//const client = Stitch.initializeDefaultAppClient('ntn-dxcvz');

const styles = theme => ({

  tablediv: {
    display: 'flex',
    justifyContent: 'center',
    width: '90%',
    fontSize: '1.5em',
    color: '#adadad',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
  fontSize: '1rem',
  },


  },
  bbchart:{
    display: 'grid',
    width: 'fit-content',
    margin: 'auto',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderColor: '#79a8ff',
    backgroundColor: '#000011',
    borderRadius: '50%',

    [theme.breakpoints.down('sm')]: {

      display: 'grid',
      width: '90%',
      margin: 'auto',
      justifyContent: 'center',
      borderStyle: 'solid',
      borderColor: '#79a8ff',
      backgroundColor: 'black',
      borderRadius: '50%',
      fontSize: '1rem',
  }
  },

  chip: {
    margin: theme.spacing.unit,
    backgroundColor: '#5a25de',
    color: 'white',

    [theme.breakpoints.down('sm')]: {
    margin: '0px',
    fontSize: '9px'
    },


    '&:hover': {
  backgroundColor:'#510bff',
  },
  '&:focus': {
    backgroundColor: '#5a25de',
  },

  },

  dheader: {
width: "90%",
margin: "auto",
borderStyle: "solid",
borderColor: "#79a8ff",
marginTop: "1em",
fontSize: "2rem",
textAlign: "center",
color: "#79a8ff",
borderLeft: "none!important",
borderRight: "none",
marginBottom: '2rem',
},
header: {
width: "90%",
margin: "auto",
borderStyle: "solid",
borderColor: "#fdf748",
marginTop: "1em",
fontSize: "2rem",
textAlign: "center",
color: "#fdf748",
borderLeft: "none!important",
borderRight: "none",
marginBottom: "1em",
},
table: {
  width: "90%",

},
tc: {
  '&:hover': {
cursor: 'pointer'
},
// '&:focus': {
//   backgroundColor: '#5a25de',
// }
}
});


// var s = [{
// _id: "as1",
// nodename : "MONGODB",
// parent: "PROGRAMMING",
// owner_id: "83r22e3awqe",
// children: ["PROGRAMMING"],
// n: 1,
// },
// {
// _id: "as2",
// nodename : "PROGRAMMING",
// parent: "ROOT",
// owner_id: "83r22e3awqe",
// children: ["MONGODB"],
// n: 3,
// },
// {
// _id: "as3",
// nodename : "ROOT",
// parent: "SUPERROOT",
// owner_id: "83r22e3awqe",
// children: ["PROGRAMMING"]
// n: 5,
// },
// {
// _id: "as4",
// nodename : "MONGODB STITCH",
// parent: "MONGODB",
// owner_id: "83r22e3awqe",
// n: 1,
// },
// ]



export class Profile extends Component {
  state = {


    depth1: false,
 }
//Signin function here


componentDidMount(){

  //this.getCustomUserData();

  if(this.props.mode === 1)
  {
  this.getDepth1Nodes();
  }
  if(this.props.mode === 2)
  {
    this.props.fetchNodesRef(2, this.props.another_owner_id)
    this.Mode2_getNumberOfContribs();
    this.getDepth1NodesMode2();
    this.Mode2_getCustomUserData();

  }
  this.getBrainInfo();

}

// bubbleClick = (label) =>{
//   console.log("Custom bubble click func")
//
// }
// assd = (node) =>
// {
// if(node.children.length === 0)
// {
//   return node.n
// }
// let tot = node.n;
//
// for(let x = 0; x < node.children; x++)
// {
// let indexOfNodeName = binarySearchFindNodeThatMatchesPefectly(this.props.nodes_symboltable[node.children[x].nodename.charAt(0)], node.children[x].nodename)
// tot += assd(node.children ->>>>>>> node.children needs to be a list of nodes that are the children of this node. )
// }
// return tot
//
//
// }

// getCustomUserData = async () => {
//   const client = Stitch.defaultAppClient;
//
//
//   //let s = await client.callFunction("getCustomUserData");
//   //this.setState({userCustomData: s});
//
//   //this.props.setEntriesRef(s.top10, [])
// }

Mode2_getCustomUserData = async () => {
  const client = Stitch.defaultAppClient;


  let s = await client.callFunction("Mode2_getCustomUserData", [this.props.another_owner_id]);


  this.props.setCustomDataRef(s[0]);

}

Mode2_getNumberOfContribs = async () => {
  const client = Stitch.defaultAppClient;

  let s = await client.callFunction("Mode2_getContribs", [this.props.another_owner_id]);
  this.setState({contribs: s});

}


getDepth1Nodes = async () => {

const client = Stitch.defaultAppClient;

let s = await client.callFunction("graphLookupTests");


try {

  let p = s.map(function (param) {
    if(param.self_dataentries)
    {
      return {label: param.nodename, value: param.self_dataentries + param.totaldataentries}
    }
    else {
      return {label: param.nodename, value: param.totaldataentries}
    }
  });

this.setState({depth1: p});
} catch (e) {

  return
}






}

getDepth1NodesMode2 = async () => {

const client = Stitch.defaultAppClient;

let s = await client.callFunction("Mode2_getDevelopingBrainAreas", [this.props.another_owner_id]);


try {
  let totalentries = 0
  let summer = 0;
  let p = s.map(function (param) {

    if(param.self_dataentries)
    {
      summer = param.self_dataentries + param.totaldataentries
      totalentries += summer
      return {label: param.nodename, value: summer}
    }
    else {
      totalentries+=param.totaldataentries
      return {label: param.nodename, value: param.totaldataentries}
    }
  });

this.setState({depth1: p, tot: totalentries});
console.log(p, totalentries)
} catch (e) {

  return
}






}

getBrainInfo = () => {

  if(this.props.mode === 1) // just get all the shit from memory.
  {
    let obj = Object.values(this.props.dataentries_symboltable);
    let tot = 0
    let contribs = 0;
    obj.forEach(item => {

      if(item.length > 0) {

        tot+=item.length
        item.forEach(subitem => {
          if(subitem.private === false)
          {
            contribs++;
          }
        })
      }; //Get total entries.
  });

  this.setState({tot: tot, contribs: contribs});
  }
  if(this.props.mode === 2)
  {

  }
}

bubbleClick = (name) =>
{

  switch(this.props.mode) {
  case 1:
    // code block
    {
      this.props.handleClickOnEntryNeurotag("", {node: name, parent: "ROOT"})
      this.props.onClickedOnSomeNodeInTree("", name)
    }
    break;
  case 2:
    // code blockkar
    {


        this.props.handleClickOnEntryNeurotag("", {node: name, parent: "ROOT"})
      this.props.onClickedOnSomeNodeInTree("", name)
      this.props.setLoadingOverlayControllerToTrueRef();

    }
    break;
  default:
    // code block
}
  //console.log(this.props.another_owner_id)
}





render () {
    const { classes } = this.props;
    //this.assd();
    //let texttodisplay = this.state.confirmationsuccess ? <p>Account confirmation successful. Please wait...</p> : <p>Confirming account...</p>
    //let successorerror = this.state.confirmationerror ? <p>Error, confirmation process failed.</p> : texttodisplay

    if(this.state.confirmationsuccess)
    {
    return <Redirect to="/signin" />
    }

/*
<div className="parent">
<div className="chart">
<ResponsiveBubble
root={data}
margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
identity="name"
value="loc"
colors={{ scheme: 'nivo' }}
padding={6}
labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 0.8 ] ] }}
borderWidth={2}
borderColor={{ from: 'color' }}
defs={[
{
id: 'lines',
type: 'patternLines',
background: 'none',
color: 'inherit',
rotation: -45,
lineWidth: 5,
spacing: 8
}
]}
fill={[ { match: { depth: 1 }, id: 'lines' } ]}
isInteractive={false}
animate={false}
motionStiffness={90}
motionDamping={40}
ondClick={(e, m)=> { console.log(e, m)}}
/></div></div>
*/
    return (

            <div>
            <div className={classes.dheader}>Basic Information</div>
            <div className={classes.tablediv}>
            <table className={classes.table}>
              <tr>
                <td>Username</td>
                <td>{this.props.custom_data.username}</td>
              </tr>
              <tr>
                <td >Total Contributions To Humanity</td>
                <td style={{color: "rgba(253, 247, 72, 1)", textDecoration: 'underline', fontSize:'3em'}} onClick={()=>{ if(this.props.mode === 2){this.props.fetchlatestentriesmode2Ref(0, this.props.custom_data_anotheruser.owner_id);} if(this.props.mode === 1){this.props.fetchlatestentriesmode2Ref(0, this.props.custom_data.owner_id);} this.props.appbarFuncClickedOnLatestEntries() }}><div className={classes.tc}>{this.state.contribs}</div></td>
              </tr>
              <tr>
                <td>Total Entries In Brain</td>
                <td style={{textDecoration: 'underline', color:'#5a25de', fontSize:'3em'}}>{this.state.tot}</td>
              </tr>
              <Tooltip title="AP is updated once a day." aria-label="Add">
              <tr>


                             <td>Actualized Potential</td>
                <td style={{textDecoration: 'underline', color: '#11de0d', fontSize:'3em'}}>{(this.props.mode === 1) ? this.props.custom_data.karma.reduce(function(acc, val) { return acc + val; }, 0) : this.props.custom_data_anotheruser.karma.reduce(function(acc, val) { return acc + val; }, 0)}</td>

              </tr>
                              </Tooltip>
            </table>
            </div>
            <div className={classes.dheader}>Developing Brain Areas</div>
            {
              <div className={classes.bbchart}>
              <BubbleChart
                graph= {{
                  zoom: 1 ,
                  offsetX: 0,
                  offsetY: 0,
                }}
                width={!isWidthUp('md', this.props.width) ? 300 : 900}
                height={!isWidthUp('md', this.props.width) ? 300: 900}
                padding={!isWidthUp('md', this.props.width) ? 20 : 60} // optional value, number that set the padding between bubbles
                showLegend={false} // optional value, pass false to disable the legend.
                legendPercentage={0} // number that represent the % of with that legend going to use.
                legendFont={{
                      family: 'Arial',
                      size: 7,
                      color: '#000',
                      weight: 'bold',
                    }}
                valueFont={!isWidthUp('md', this.props.width) ?{
                      family: 'Arial',
                      size: 5,
                      color: '#000',
                      weight: 'bold',
                    } : {
                          family: 'Arial',
                          size: 10,
                          color: '#000',
                          weight: 'bold',
                        }}
                labelFont={!isWidthUp('md', this.props.width) ?{
                      family: 'Arial',
                      size: 5,
                      color: '#000',
                      weight: 'bold',
                    } : {
                          family: 'Arial',
                          size: 10,
                          color: '#000',
                          weight: 'bold',
                        }}
                //Custom bubble/legend click functions such as searching using the label, redirecting to other page
                 bubbleClickFun={this.bubbleClick}
      //           legendClickFun={this.bubbleClick}
                data={this.state.depth1}
              />
              </div>

            }
            <div className={classes.header}>My Top 10 Entries</div>
        </div>
    );
 }
 }




const mapStateToProps = state => {
    return {
      dataentries_symboltable: state.signIn.dataentries_symboltable,
      mode: state.signIn.mode,
      custom_data: state.signIn.custom_data,
      another_owner_id: state.signIn.another_owner_id,
      custom_data_anotheruser: state.signIn.custom_data_anotheruser
    }
  }
const mapDispatchToProps = dispatch => {
    return {
      setEntriesRef: (entries, filterednodes) => dispatch(actions.setEntries(entries, filterednodes)),
      setCustomDataRef: (st) => dispatch(actions.setCustomData(st)),
      setLoadingOverlayControllerToTrueRef: () => dispatch(actions.setLoadingOverlayControllerToTrue()),
      fetchNodesRef: (mode, ownerid) => dispatch(actions.fetchNodes(mode, ownerid)),
      fetchlatestentriesmode2Ref: (whichtoget,ownerid) => dispatch(actions.fetchLatestEntriesMode2(whichtoget,ownerid)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler( withStyles(styles)(withWidth()(Profile)) ));
