export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export function shuffle(sourceArray) {
    for (var i = 0; i < sourceArray.length - 1; i++) {
        var j = i + Math.floor(Math.random() * (sourceArray.length - i));

        var temp = sourceArray[j];
        sourceArray[j] = sourceArray[i];
        sourceArray[i] = temp;
    }
    return sourceArray;
};


export function randomSampleFromObject(obj) {
  var keys = Object.keys(obj)
return obj[keys[ keys.length * Math.random() << 0]];
};

export function binarySearchForFindingDataEntryByNodeName(sortedArr, element, dataentryfilterornodefilter=0){

/*
VERY IMPORTANT: USE THE FOLLOWING TO TEST WITH
console.log(desymboltable["P"][22] );
console.log(binarySearchForFindingDataEntryByNodeName(desymboltable["P"], desymboltable["P"][22] ));
*/



  let startIndex = 1;
  let endIndex = sortedArr.length - 1;



  while (startIndex <= endIndex) {
    const middleIndex = startIndex + Math.floor((endIndex - startIndex) / 2);

    let extract = dataentryfilterornodefilter === 0 ? sortedArr[middleIndex].node.substring(0, element.length) : sortedArr[middleIndex].nodename.substring(0, element.length)

    if (element === extract) {
      //console.log("count", count);
      return middleIndex;
    }

    //console.log('sortedArr[middleIndex].node.substring(0, element.length)', sortedArr[middleIndex].node.substring(0, element.length), element)
    if (extract < element) {
      // console.log('CONDITION PASSED')
      startIndex = middleIndex + 1;
    } else {
      endIndex = middleIndex - 1;
    }
  }


}

export function binarySearchForDataEntryEditingAndDeleting(sortedArr, element){

/*
VERY IMPORTANT: USE THE FOLLOWING TO TEST WITH
console.log(desymboltable["P"][22] );
console.log(binarySearchForFindingDataEntryByNodeName(desymboltable["P"], desymboltable["P"][22] ));
*/



  let startIndex = 0;
  let endIndex = sortedArr.length - 1;



  while (startIndex <= endIndex) {
    const middleIndex = startIndex + Math.floor((endIndex - startIndex) / 2);

    let extract = sortedArr[middleIndex].node
    //console.log("(element.node === extract) && (element._id === sortedArr[middleIndex]._id, sortedArr[middleIndex])", element.node, extract, element._id, sortedArr[middleIndex]._id, sortedArr[middleIndex])
    if ((element.node === extract)) {


      if(sortedArr[middleIndex].date > element.date)
      {
        startIndex = middleIndex + 1;
      }
      else {
        endIndex = middleIndex - 1;
      }
      if(element._id === sortedArr[middleIndex]._id)
      {
        return middleIndex;
      }
      continue;


    }
  //  console.log('sortedArr[middleIndex].node.substring(0, element.length)', sortedArr[middleIndex].node.substring(0, element.length), element)
    if (extract < element.node) {
      // console.log('CONDITION PASSED')
      startIndex = middleIndex + 1;
    } else {
      endIndex = middleIndex - 1;
    }
  }


}

export function binarySearchFindNodeThatMatchesPefectly(sortedArr, element){

/*
VERY IMPORTANT: USE THE FOLLOWING TO TEST WITH
console.log(desymboltable["P"][22] );
console.log(binarySearchForFindingDataEntryByNodeName(desymboltable["P"], desymboltable["P"][22] ));
*/




// if(sortedArr.length === 2)
// {
//   if(element === "ROOT")
//   {
//     return 0;
//   }
//   //Since the only case where there the list of nodes is one would be if only the root is parent, handle this case
//   //separately otherwise the binary search will not be able to work
// }


  //let count = 0;

  let startIndex = 0;
  let endIndex = sortedArr.length - 1;



  while (startIndex <= endIndex) {
    //count++;
    const middleIndex = startIndex + Math.floor((endIndex - startIndex) / 2);

    //console.log("middleindex", middleIndex);


    if (element === sortedArr[middleIndex].nodename) {
  //  console.log("count", count);
      //console.log("found match ", element)
      return middleIndex;
    }

    //console.log('sortedArr[middleIndex].node.substring(0, element.length)', sortedArr[middleIndex].node.substring(0, element.length), element)
    if (sortedArr[middleIndex].nodename < element) {
      // console.log('CONDITION PASSED')
      startIndex = middleIndex + 1;
    } else {
      endIndex = middleIndex - 1;
    }
  }

}


// export function binarySearchFindWhereToInsertNodeNonPerformant(sortedArr, element){
//
//
//
//   let startIndex = 1;
//   let endIndex = sortedArr.length
//
// try {
//
//   for(let i = startIndex; i < endIndex; i++)
//   {
//
//     if(element < sortedArr[i].nodename)
//     {
//       return i
//     }
//   }
//
//   return startIndex
//
//
// } catch (e) {
//   console.log(e)
// }
//
//
// }

export function binarySearchFindWhereToInsertNode(sortedArr, element){

/*
IMPROTANT:

DO NOT ALLOW USE OF WHERE TO INSERT NODE FOR OVERWRITING AN EXISTING NODE, ONLY USE FOR RIGHT OR LEFT INSERTION!!!

Several conditions exist, and each of the possible conditions must be accounted for.
Turn into numbers in order to simplify problem

[1, 3, 4, 5, 7, 9, 10]

The first case is 8, the current element is bigger than the insertelement and the currentelement - 1 is smaller than the insertelement. INSERT TO LEFT, insert at current index -1
The second case is we end up at 7, so the current index is smaller than the insertelement and the currentelement+1 is BUGGER than the insert element, rightside insert, insert at currentindex
The third case is a prepend case: The current element is bigger than the insertelement and we are currently at the start. -> Insert at 0 -> Prepend
The fourth case is the append case, where the current element is smaller than the insertelement and we are currently at the end -> append, insert at length

*/


  //let count = 0;

  if(sortedArr.length === 2)
  //This if is hardcoded to compensate for the initial setting of for example [["ROOT"], {nodename: ROOT}]
  {
    if(element > sortedArr[1].nodename)
    {
      return 2
    }
    else {
      return 1
    }
  }

  if(sortedArr.length === 1)
  //This if is hardcoded to compensate for the initial setting this.props.nodes containing only {nodename: ROOT}
  //this.nodes_symboltable should never have less than two entries, it is not possible, if it does, there is something
  //structurally wrong and we need to reburm
  {

    if(element > sortedArr[0].nodename)
    {
      return 1
    }
    else {
      return 0
    }
  }

  // if(sortedArr.length === 1)
  // //This if is hardcoded to compensate for props.nodes only containing {nodename: ROOT}, in the begininning
  // // remember the node here might actually need to be updated from ROOT to ROOT with children including the new node.
  // {
  //   if(element > sortedArr[1].nodename)
  //   {
  //     return 2
  //   }
  //   else {
  //     return 1
  //   }
  // }

  let startIndex = 1;
  let endIndex = sortedArr.length - 1;



  while (startIndex <= endIndex) {
  //  count++;
    const middleIndex = startIndex + Math.floor((endIndex - startIndex) / 2);

    console.log("middleindex", middleIndex);

    if((middleIndex === 1) && (element < sortedArr[middleIndex].nodename) ) //case 3
    {
      console.log("prepend case used.", element, sortedArr[middleIndex].nodename)

      return 1;
    }
    if((middleIndex === sortedArr.length-1) && (element > sortedArr[middleIndex].nodename) ) //case 4
    {
      console.log("append case used.", sortedArr.length-1, )
  //  console.log("count: ", count)
      return sortedArr.length;
    }

    if ((element < sortedArr[middleIndex].nodename) && (sortedArr[middleIndex-1].nodename < element)) {
      //console.log("count: ", count)
      return middleIndex;
    }

    if ((element > sortedArr[middleIndex].nodename) && (sortedArr[middleIndex+1].nodename > element)) {
      //console.log("count: ", count)
      return middleIndex+1;
    }

    //console.log('sortedArr[middleIndex].node.substring(0, element.length)', sortedArr[middleIndex].node.substring(0, element.length), element)
    if (sortedArr[middleIndex].nodename < element) {
       console.log('CONDITION PASSED')
      startIndex = middleIndex + 1;
    } else {
      endIndex = middleIndex - 1;
    }
  }

  console.log("binarySearchFindWhereToInsertNode returned undefined")

}



export function binarySearchFindWhereToInsertDataentryPerformant(sortedArr, element){

/*

Several conditions exist, and each of the possible conditions must be accounted for.
Turn into numbers in order to simplify problem

[1, 3, 4, 5, 7, 9, 10]

The first case is 8, the current element is bigger than the insertelement and the currentelement - 1 is smaller than the insertelement. INSERT TO LEFT, insert at current index -1
The second case is we end up at 7, so the current index is smaller than the insertelement and the currentelement+1 is BUGGER than the insert element, rightside insert, insert at currentindex
The third case is a prepend case: The current element is bigger than the insertelement and we are currently at the start. -> Insert at 0 -> Prepend
The fourth case is the append case, where the current element is smaller than the insertelement and we are currently at the end -> append, insert at length

*/


  let startIndex = 0;
  let endIndex = sortedArr.length - 1;



  while (startIndex <= endIndex) {
    const middleIndex = startIndex + Math.floor((endIndex - startIndex) / 2);

    //console.log("middleindex", middleIndex);



    if((middleIndex === 0) && (element <= sortedArr[middleIndex].node) ) //case 3
    {
      //console.log("prepend case used.")
      return 0;
    }
    if((middleIndex === sortedArr.length-1) && (element > sortedArr[middleIndex].node) ) //case 4
    {
    //  console.log("append case used.", sortedArr.length-1, )
      return sortedArr.length;
    }

    if ((element <= sortedArr[middleIndex].node) && (sortedArr[middleIndex-1].node < element)) {

      // the node you are searching for is alphabetically the same or eariler AND the elem to the left is alphabetically earlier

  //    console.log("element", element, "sortedArr[middleIndex].node", sortedArr[middleIndex].node, "sortedArr[middleIndex-1].node", sortedArr[middleIndex-1].node,  )
      return middleIndex;
    }

    // if ((element >= sortedArr[middleIndex].node) && (sortedArr[middleIndex+1].node > element)) {
    //   console.log("fourth if", middleIndex+1)
    //   // it was equal to in this case AND the element is alpahbetically earlier than the ndoe, insert
    //     console.log("element", element, "sortedArr[middleIndex].node", sortedArr[middleIndex].node, "sortedArr[middleIndex+1].node", sortedArr[middleIndex+1].node  )
    //   return middleIndex+1;
    // }

    //console.log('sortedArr[middleIndex].node.substring(0, element.length)', sortedArr[middleIndex].node.substring(0, element.length), element)
    if (sortedArr[middleIndex].node < element) {
      // console.log('CONDITION PASSED')
      startIndex = middleIndex + 1;
    } else {
      endIndex = middleIndex - 1;
    }
  }

}

export function binarySearchFindANodeThatMatchesFirstTwoCharactersOfOurNode(sortedArr, element){

/*
VERY IMPORTANT: USE THE FOLLOWING TO TEST WITH
console.log(desymboltable["P"][22] );
console.log(binarySearchForFindingDataEntryByNodeName(desymboltable["P"], desymboltable["P"][22] ));
*/

// sortedArr = [["GAME PROGRAMMING", "GAMES", "GARBAGE COLLECTION", "GARDENING", "GENERAL INVESTMENT", "GIT", "GIT AND GITHUB THE PRACTICAL GUIDE", "GIT COMMANDS", "GITHUB", "GITHUB ACTIONS", "GOATS", "GOOGLE CHROME", "GOOGLE NOTEBOOK", "GRADIENT DESCENT", "GRAPHQL", "GUN CONTROL", "GUNS"],
//
// {_id: "5eb697715e175be51a6b9f66", nodename: "GAME PROGRAMMING", parent: "PROGRAMMING", children: Array(0), owner_id: "5d8fea983b3aec8b3a45c1d1"},
// {_id: "5e1db0521c9d4400008b7ca8", nodename: "GAMES", parent: "ROOT", children: Array(2), owner_id: "5d8fea983b3aec8b3a45c1d1"},
// {_id: "5e9bb63e00ec860a0c3a0ba4", nodename: "GARBAGE COLLECTION", parent: "PROGRAMMING", children: Array(0), owner_id: "5d8fea983b3aec8b3a45c1d1"},
// {_id: "5fad20f5cdc8a73fbdf2c0b0", nodename: "GARDENING", parent: "HORTICULTURE", children: Array(0), owner_id: "5d8fea983b3aec8b3a45c1d1"},
// {_id: "5dc98580424643c34e5f2b34", nodename: "GENERAL INVESTMENT", parent: "INVESTMENT", children: Array(0), owner_id: "5d8fea983b3aec8b3a45c1d1"},
// {_id: "5e01f6a4115c873bbffdb205", nodename: "GIT", parent: "VERSION CONTROL", children: Array(1), owner_id: "5d8fea983b3aec8b3a45c1d1"},
// {_id: "5f8e0b3ab399e22d293ae01b", nodename: "GIT AND GITHUB THE PRACTICAL GUIDE", parent: "PROGRAMMING COURSES", children: Array(0), owner_id: "5d8fea983b3aec8b3a45c1d1"},
// {_id: "5da5ed008ad8fb6f30b18d5f", nodename: "GIT COMMANDS", parent: "GIT", children: Array(0), owner_id: "5d8fea983b3aec8b3a45c1d1"},];
//
// element = "GAY";



//  let count = 0;

  let startIndex = 1;
  let endIndex = sortedArr.length - 1;



  while (startIndex <= endIndex) {
//    count++;
    const middleIndex = startIndex + Math.floor((endIndex - startIndex) / 2);

    //console.log("middleindex", middleIndex);

  //  console.log(element.substring(0,2), sortedArr[middleIndex].nodename.substring(0, 2))
    if (element.substring(0,2) === sortedArr[middleIndex].nodename.substring(0, 2)) {
      //console.log("count", count);
      //console.log("found match ", element)
      return middleIndex;
    }

    //console.log('sortedArr[middleIndex].node.substring(0, element.length)', sortedArr[middleIndex].node.substring(0, element.length), element)
    if (sortedArr[middleIndex].nodename < element) {
      // console.log('CONDITION PASSED')
      startIndex = middleIndex + 1;
    } else {
      endIndex = middleIndex - 1;
    }
  }

}


export function binarySearchFindWhereToInsertDataentry(sortedArr, element){

/*
VERY IMPORTANT: USE THE FOLLOWING TO TEST WITH
console.log(desymboltable["P"][22] );
console.log(binarySearchForFindingDataEntryByNodeName(desymboltable["P"], desymboltable["P"][22] ));
*/


  let count = 0;

  let startIndex = 0;
  let endIndex = sortedArr.length - 1;



  while (startIndex <= endIndex) {
//    count++;
    const middleIndex = startIndex + Math.floor((endIndex - startIndex) / 2);

    //console.log("middleindex", middleIndex);


    if (element === sortedArr[middleIndex].node) {
      //console.log("count", count);
      //console.log("found match ", element)
      return middleIndex;
    }

    //console.log('sortedArr[middleIndex].node.substring(0, element.length)', sortedArr[middleIndex].node.substring(0, element.length), element)
    if (sortedArr[middleIndex].node < element) {
      // console.log('CONDITION PASSED')
      startIndex = middleIndex + 1;
    } else {
      endIndex = middleIndex - 1;
    }
  }

}

export function patternizedCompressor(rawwithouttitdate, patterns){
  let compressedObj = {
    b: [],
    e: rawwithouttitdate.entityMap
  }
  let letfontlookuptable = {
  }
  let fontlookuptableindorder = [];
  //console.log(letfontlookuptable["fontfamily-Helvetica Neue"]);
  let lookuptableindex = 0;

  for(let x = 2; x < rawwithouttitdate.blocks.length; x++) // run through all the elements in blocks except the first two that are kept open
  {
    for(let y = 0; y < patterns.length+1; y++) // try to match a pattern and break. Need to go to plus one, to know when we exhausted all the patterns
    {


      if(y < patterns.length) // pattern 1 deals with unstyled with no other characteristics or pattern 1 with no other characteristics except some offsets and only offsets
      //if it contains depth or any other characteristic it will not apply.
      {
        //console.log(this.props.patterns[y].type, rawwithouttitdate.blocks[x].type)
        if(patterns[y].type != rawwithouttitdate.blocks[x].type)
        {
          //console.log("1")
          continue;
        }
        if(patterns[y].depth != rawwithouttitdate.blocks[x].depth)
        {
          //console.log("2")
          continue;
        }
        if("{}" != JSON.stringify(rawwithouttitdate.blocks[x].data))
        {
          // Once the program flow reaches here, it will be pattern 1 but with an x amount of offsets, compensate here.
          continue;
        }
        if(rawwithouttitdate.blocks[x].entityRanges.length != 0)
        {
          continue;
        }
        if(patterns[y].inlineStyleRanges.length != rawwithouttitdate.blocks[x].inlineStyleRanges.length)
        {
          // if it reaches this stage and contains inlineStyleRanges with length that is higher than one,
          //
          var arrayofallinlinestylechanges = [];
          for(let z = 0; z < rawwithouttitdate.blocks[x].inlineStyleRanges.length; z++)
          {

            arrayofallinlinestylechanges.push( rawwithouttitdate.blocks[x].inlineStyleRanges[z].offset);
            arrayofallinlinestylechanges.push(rawwithouttitdate.blocks[x].inlineStyleRanges[z].length);
            if(letfontlookuptable[rawwithouttitdate.blocks[x].inlineStyleRanges[z].style] === undefined)//if the style does not exist yet use it
            {
              letfontlookuptable[rawwithouttitdate.blocks[x].inlineStyleRanges[z].style] = lookuptableindex
              arrayofallinlinestylechanges.push(lookuptableindex)
              fontlookuptableindorder.push(rawwithouttitdate.blocks[x].inlineStyleRanges[z].style);
              lookuptableindex++; // these lookup table indexes will eventually be reused to get the keys back again.

            }
            else {
              arrayofallinlinestylechanges.push(letfontlookuptable[rawwithouttitdate.blocks[x].inlineStyleRanges[z].style])
            }

          }
          compressedObj.b.push({k:rawwithouttitdate.blocks[x].key, t: rawwithouttitdate.blocks[x].text, p: y+1, i: arrayofallinlinestylechanges})
          break
        }

        // else pattern 1 found!
        //console.log("pattern 1 recognized!")
        compressedObj.b.push({k: rawwithouttitdate.blocks[x].key, t: rawwithouttitdate.blocks[x].text, p: y+1})
        break; // once a pattern established you may break
      }
      if(y === patterns.length) // no pattern could be applied, use in bulk form because we don't know.
      {

        compressedObj.b.push({k: rawwithouttitdate.blocks[x].key, t: rawwithouttitdate.blocks[x].text, y: rawwithouttitdate.blocks[x].type, d: rawwithouttitdate.blocks[x].depth, i: rawwithouttitdate.blocks[x].inlineStyleRanges, n: [...rawwithouttitdate.blocks[x].entityRanges], a: {...rawwithouttitdate.blocks[x].data}, p: patterns.length+1});
        break
      }



    }

  }
  if(fontlookuptableindorder.length > 0)
  {
    compressedObj.l = fontlookuptableindorder;
  }
  if("{}" != JSON.stringify(rawwithouttitdate.entityMap))
  {
    compressedObj.e = rawwithouttitdate.entityMap
  }

    return compressedObj
}

export function patternizedDecompressor(compressed, patterns) {

let basicForm = {
  blocks: [{}, {}],
  entityMap: {}
}


let com = JSON.parse(compressed)

if("{}" != JSON.stringify(com.e))
{
  basicForm.entityMap = com.e
}

for(let x = 0; x < com.b.length; x++)
{

if(com.b[x].p < patterns.length+1)
// used to teset whether the current line is pattern compressed or not, if not we handle it in the else case.
{
  let newObj = {...patterns[com.b[x].p-1]}

  newObj.text = com.b[x].t
  newObj.key = com.b[x].k;

  //console.log(com.b[x].t)
  //basicForm.blocks[x+2].text = com.b[x].t;
  //console.log(basicForm.blocks[x+2].text)

  if(com.b[x].i != undefined)
  {
    //replace i with using the keys saved
    let newI = []
    try {

      //console.log(com.l);
      for(let y = 0; y < com.b[x].i.length; y+=3 )
      {
        newI.push({offset: com.b[x].i[y], length: com.b[x].i[y+1], style: com.l[com.b[x].i[y+2]]})

      }
    } catch (e) {
      console.error(e)

    }
    newObj.inlineStyleRanges = newI;
  }

  basicForm.blocks.push(newObj);
}
else {
  // is a strange pattern and must be handled separately.
    let newObj = {key: com.b[x].k, text: com.b[x].t, type: com.b[x].y, depth: com.b[x].d, inlineStyleRanges: com.b[x].i, entityRanges: com.b[x].n, data: com.b[x].a}
    basicForm.blocks.push(newObj);

}


}
//console.log(basicForm);
return basicForm






}
