import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
//import asyncComponent from './hoc/asyncComponent/asyncComponent';
import Layout from './hoc/Layout/Layout';
//import * as actions from './store/actions/index';
//import SignUp from './containers/SignUp/SignUp';
import SignIn from './containers/SignIn/SignIn';
//import Index from './pages/index';
import Alerts from './containers/Alerts/Alerts';
//import Confirm from './containers/Confirm/Confirm';
//import Profile from './containers/Profile/Profile';
//import ResetPassword from './containers/ResetPassword/ResetPassword';
import withRoot from './withRoot';
import loadable from "@loadable/component";
const SignUp = loadable(() => import("./containers/SignUp/SignUp"),
  {
    fallback: "Loading..."
  }
);
const Confirm = loadable(() => import("./containers/Confirm/Confirm"),
  {
    fallback: "Loading..."
  }
);

const ScreenComponent = loadable(() => import('./containers/ScreenContainer/ScreenContainer'),
  {
    fallback: "Loading..."
  }
);

const AudienceComponent = loadable(() => import('./containers/AudienceContainer/Audience'),
  {
    fallback: "Loading..."
  }
);
// const Profile = loadable(() => import("./containers/Profile/Profile"),
//   {
//     fallback: "Loading..."
//   }
// );
const ResetPassword = loadable(() => import("./containers/ResetPassword/ResetPassword"),
  {
    fallback: "Loading..."
  }
);

// const Alerts = loadable(() => import("./containers/Alerts/Alerts"),
//   {
//     fallback: "Loading..."
//   }
// );
//import Practice from './containers/Practice/Practice';
//import ResultsPage from './components/ResultsPage/ResultsPage'
//          <Route path="/profile" component={Profile} />

const app = props => {
      let routes = (
        <Switch>
          <Route path="/signup" component={SignUp} />
          <Route path="/main" component={Alerts} />
          <Route path="/signin" component={SignIn} />
          <Route path="/confirm" component={Confirm} />
          <Route path="/reset" component={ResetPassword} />
          <Route path="/screen1" component={ScreenComponent} />
          <Route path="/audience" component={AudienceComponent} />
          <Route path="/" component={SignIn} />
        </Switch>
      );
      return (
          <Layout>
            {routes}
          </Layout>
      );

    }



const mapStateToProps = state => {
  return {
    // settingsHaveBeenSetRef: state.mainMenu.settingsHaveBeenSet,
    // //currentQuestionRef: state.practice.currentQuestionNumber,
    // totalQuestionsRef: state.mainMenu.numberOfQuestions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default withRouter( connect(mapStateToProps, mapDispatchToProps)( withRoot(app) ) );
