export const FETCH_ALERTS = 'FETCH_ALERTS';
export const FETCH_ALERTS_CLOSED= 'FETCH_ALERTS_CLOSED';
export const SET_ALERTS = 'SET_ALERTS';
export const SET_AND_APPEND_CLOSED_ALERTS_TOO= 'SET_AND_APPEND_CLOSED_ALERTS_TOO';
export const SET_USER = 'SET_USER';
export const ADDITION_TO_TRANSFORMEDALERT = 'ADDITION_TO_TRANSFORMEDALERT';
export const UPDATE_TO_TRANSFORMEDALERT = 'UPDATE_TO_TRANSFORMEDALERT';
export const DELETE_TO_TRANSFORMEDALERT = 'DELETE_TO_TRANSFORMEDALERT';
export const FETCH_NODES = 'FETCH_NODES';
export const SET_NODES = 'SET_NODES';
export const FILTER_NODES = 'FILTER_NODES';
export const SET_NODES_PINPOINTMODE3 = 'SET_NODES_PINPOINTMODE3';
export const FETCH_ENTRIES = 'FETCH_ENTRIES';
export const SET_ENTRIES = 'SET_ENTRIES';
export const FETCH_LATESTENTRIES = 'FETCH_LATESTENTRIES';
export const RESET_FILTEREDNODES = 'RESET_FILTEREDNODES';
export const ACTIVATE_LOADINGOVERLAY = 'ACTIVATE_LOADINGOVERLAY';
export const DEACTIVATE_LOADINGOVERLAY = 'DEACTIVATE_LOADINGOVERLAY';
export const FETCH_ONE = 'FETCH_ONE';
export const FETCH_ANOMALIES = 'FETCH_ANOMALIES';
export const SET_ANOMALIES = 'SET_ANOMALIES';
//export const FIX_SINGLE_ANOMALY = 'FIX_SINGLE_ANOMALY';
export const CALL_SOME_FUNCTION_FROM_STITCH = 'CALL_SOME_FUNCTION_FROM_STITCH';
export const FETCH_ENTRIES_BY_CONTENT = 'FETCH_ENTRIES_BY_CONTENT';
export const UPDATE_NODE_AFTER_NEW_NODE_CREATED = 'UPDATE_NODE_AFTER_NEW_NODE_CREATED'; //is needed because after you create a new node it will not show up in a search
export const SET_DATAENTRIES_SYMBOL_TABLE = 'SET_DATAENTRIES_SYMBOL_TABLE';
export const SET_NODES_SYMBOL_TABLE = 'SET_NODES_SYMBOL_TABLE';
export const FILTER_ENTRIES_ON_CHIP_CLICK = 'FILTER_ENTRIES_ON_CHIP_CLICK';
export const HANDLE_DELETE_CHIP_DURING_SEARCH = 'HANDLE_DELETE_CHIP_DURING_SEARCH';
export const DELETE_FROM_REDUX_TOO = 'DELETE_FROM_REDUX_TOO';
export const SET_CUSTOMDATA = 'SET_CUSTOMDATA';
export const CHANGE_TO_MODE_2 = 'CHANGE_TO_MODE_2';
export const FETCH_LATESTENTRIES_MODE2 = 'FETCH_LATESTENTRIES_MODE2';
export const CHANGEMODEFROM1TO3ANDBACK = 'CHANGEMODEFROM1TO3ANDBACK';
export const RESETMEM = 'RESETMEM';
export const CHANGE_TO_SPECIFIC_MODE = 'CHANGE_TO_SPECIFIC_MODE';
export const REMOVE_FROM_DE_AFTER_DELETE = 'REMOVE_FROM_DE_AFTER_DELETE';
export const SET_ENTRIES_FOREIGN = 'SET_ENTRIES_FOREIGN';
export const FETCH_ENTRIES_FOR_PINPOINTEDNODE = 'FETCH_ENTRIES_FOR_PINPOINTEDNODE';
export const FETCH_LATESTENTRIES_MODE3 = 'FETCH_LATESTENTRIES_MODE3';
export const CHANGE_A_DATAENTRY_VOTE = 'CHANGE_A_DATAENTRY_VOTE';//for dataentry vote updates
export const CHANGE_A_DATAENTRY = 'CHANGE_A_DATAENTRY';//for dataentry vote updates
export const SET_ENTRIES_FOREIGN_PLUS_EXSTRA_STATE = "SET_ENTRIES_FOREIGN_PLUS_EXSTRA_STATE"






//since search use local nodes fetched in the beginning for performance/.
