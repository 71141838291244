import * as actionTypes from './actionTypes';
import { Stitch } from 'mongodb-stitch-browser-sdk'
import { openDB } from 'idb/with-async-ittr.js';
import { binarySearchForFindingDataEntryByNodeName } from '../../shared/utility';
// import DOMPurify from 'dompurify';
//import lzwCompress from 'lzwcompress';

// export const selectGrade = (grade) => {
//     return {
//         type: actionTypes.SELECT_GRADE,
//         grade: grade,
//     };
// };
// export const fetchAlerts = () => {
//       const client = Stitch.defaultAppClient;
//     return dispatch => {
//       client.callFunction("kryPrioriteitAlerts").then(Result => {
//         //console.log("fetchAlert after getting ", Result)
//         dispatch(setAlerts(Result))
//       }).catch( error => {
//                       console.log('Unable to retrieve questions. Error: ', error)});
//     };
// };

// export const callSomeFunctionFromStitch = () => {
//       const client = Stitch.defaultAppClient;
//     return dispatch => {
//       client.callFunction("kryAlleNodeName").then(Result => {
//         //console.log("fetchAlert after getting ", Result)
//         dispatch(setAlerts(Result))
//       }).catch( error => {
//                       console.log('Unable to retrieve questions. Error: ', error)});
//     };
// };

// export const fetchAlertsClosed = () => {
//       const client = Stitch.defaultAppClient;
//     return dispatch => {
//       client.callFunction("kryPriorityAlertsClosed").then(Result => {
//         //console.log("fetchClosedAlerts after getting ", Result)
//         dispatch(setAndAppendClosedAlertsToo(Result))
//       }).catch( error => {
//                       console.log('Unable to retrieve questions. Error: ', error)});
//     };
// };
async function burnNodesToIndexedDB(Result) {
        const db = await openDB('Nodes', 1, {
            upgrade(db) {
              // Create a store of objects
              const store = db.createObjectStore('nodes', {
                // The 'id' property of the object will be the key.
                keyPath: 'id',
                autoIncrement: true,
                // If it isn't explicitly set, create a value by auto incrementing.
              });
              // Create an index on the 'date' property of the objects.
            },
          });

          const dbnodesorganized = await openDB('NodesOrganized', 1, {
              upgrade(dbnodesorganized) {
                // Create a store of objects
                const store = dbnodesorganized.createObjectStore('no', {
                  // The 'id' property of the object will be the key.
                  keyPath: 'id',
                  autoIncrement: true,
                  // If it isn't explicitly set, create a value by auto incrementing.
                });
                // Create an index on the 'date' property of the objects.
              },
            });

        const nodesToPutInIndexedDB = [];
        //const tx = db.transaction('nodes', 'readwrite');

        let obj = {};
        let counter = 0;
        let currentSymbolOrLetter = "";
        let idasstring = "";

        for(let y in Result)
        {
          idasstring = Result[y]._id.toString();
          // nodesToPutInIndexedDB.push(tx.store.add({
          //   _id: idasstring,
          //   nodename: Result[y].nodename,
          //   parent: Result[y].parent,
          //   children: Result[y].children,
          //   owner_id: Result[y].owner_id
          // }))
          nodesToPutInIndexedDB.push(
            {
              _id: idasstring,
              nodename: Result[y].nodename,
              parent: Result[y].parent,
              children: Result[y].children,
              owner_id: Result[y].owner_id
            }
          );
          //console.log(Result[y]._id.toString());
          if(currentSymbolOrLetter != Result[y].nodename.charAt(0) && (counter != 0))
          {

            //obj[currentSymbolOrLetter] = [...arr];
            //arr = [[], ]; //clear array for use for new symbol or letter
            counter = 0;
            // tx it
            currentSymbolOrLetter = Result[y].nodename.charAt(0).toString();
          }
          if(obj[Result[y].nodename.charAt(0)]) // if there is an array get it to push to it.
          {
            Result[y]._id = idasstring;
            obj[Result[y].nodename.charAt(0)].push(Result[y])
            obj[Result[y].nodename.charAt(0)][0].push(Result[y].nodename);
            counter++;
          }
          else { // no array at that letter yet.
            obj[Result[y].nodename.charAt(0)] = [[], ];
            Result[y]._id = idasstring;
            obj[Result[y].nodename.charAt(0)].push(Result[y]);
            obj[Result[y].nodename.charAt(0)][0].push(Result[y].nodename);
            counter++;
          }

        }

        obj.id = 1;

        await dbnodesorganized.put('no', obj);

        await db.add('nodes', {id: 1, nodes: nodesToPutInIndexedDB});

        return obj;




        //console.log("array for transaction", nodesToPutInIndexedDB)
      }

export const fetchNodes = (mode=1, ownerid) => {
      const client = Stitch.defaultAppClient;
    return dispatch => {
      if(mode === 1)
      {
        client.callFunction("kryAlleNodeName").then(Result => {
          //console.log("fetchNodes after getting ", Result)
          dispatch(setNodes(Result)); //Updates nodes for use in memory
          // this is where the database interaction needs to be updated.
          //console.log("Result", Result) // Result gets an array of objects, so all we gotta do is run through all
          //all elements in the array first and make a suitable new array that we can give to the transaaction.
          burnNodesToIndexedDB(Result).then(nodesymboltable => {

            dispatch(setNodesSymbolTable(nodesymboltable));
          });
        }).catch( error => {
                        console.log('Unable to retrieve questions. Error: ', error)});

      }
      else {
        client.callFunction("Mode2_kryAlleNodeName", [ownerid]).then(Result => {
          //console.log("fetchNodes after getting ", Result)
          dispatch(setNodes(Result)); //Updates nodes for use in memory
          // this is where the database interaction needs to be updated.
          //console.log("Result", Result) // Result gets an array of objects, so all we gotta do is run through all
          //all elements in the array first and make a suitable new array that we can give to the transaaction.
          console.log(Result);
        }).catch( error => {
                        console.log('Unable to retrieve questions. Error: ', error)});
      }


    };
};

export const fetchEntries = (nodes, filt, dataentries_st, dataentries, nodes_st, filterednodes) => {

  const client = Stitch.defaultAppClient;

  let fil = filt;
  fil = fil.toUpperCase();

  //console.log("filtered nodes", filterednodes)


    return dispatch => {

      if(!(Object.keys(dataentries_st).length === 0 && dataentries_st.constructor === Object)) //if empty not created yet for some reason
      {





        let chosensymbolbatch = (dataentries && dataentries.length > 0) && (fil.length > 1) ? dataentries : dataentries_st[fil.charAt(0)];
        //let filtnodes = (filterednodes && filterednodes.length > 0) && (fil.length > 2) ? filterednodes: nodes_st[fil.charAt(0)];





        if(!chosensymbolbatch) // return if it tried to access something like ] or . or symbol that is strange
        {
          return;
        }
        if(fil.length === 1) // searched first character, no need to filter, just return, nodes will not work though
        {

          //let foundnodeatindex = binarySearchForFindingDataEntryByNodeName(nodes_st[fil.charAt(0)], fil, 1 )


          dispatch(setEntries(chosensymbolbatch, nodes_st[fil.charAt(0)][0]))
          return;
        }
        else {
          let iterations = 0;

          // chosensymbolbatch = chosensymbolbatch.filter(function(element) {
          //   iterations++;
          // let reusedcondition = element.node.substring(0, fil.length) === fil.toUpperCase();
          //   if((element.node != newnode) && ( reusedcondition ))
          //   {
          //     filtnodes.push(element.node);
          //     newnode= element.node;
          //   }
          //   return (reusedcondition);
          // });
          let keeptrackfiltnodes = {};
          let filtnodes = [];
          //will reach here on searches of P + 1 more character, for P only will return immediately.
          let foundnodeatindex = binarySearchForFindingDataEntryByNodeName(chosensymbolbatch, fil )

          if(foundnodeatindex === undefined)
            return;
        //     console.log("found at index ", foundnodeatindex)

          //now let's find the entries that are actually applicable.
          let totheleft = (foundnodeatindex === 0) ? 0 : foundnodeatindex;
        //  console.log("totheleft", totheleft)

          let totheright =  (foundnodeatindex === chosensymbolbatch.length-1) ? chosensymbolbatch.length-1 : foundnodeatindex;
          //console.log("totheright", totheright)
          let startingelement = totheleft
          let endingelement = totheright
          let leftendflag = false;
          let rightendflag = false;


          while(true){
            iterations++;

            if(leftendflag === false)
            {
            let leftcondition = chosensymbolbatch[totheleft].node.substring(0, fil.length) === fil
              if(leftcondition)
              {

                if(keeptrackfiltnodes[chosensymbolbatch[totheleft].node] === undefined)
                {filtnodes.push(chosensymbolbatch[totheleft].node);}
                keeptrackfiltnodes[chosensymbolbatch[totheleft].node] = true;

                startingelement = totheleft;
                if(totheleft > 0)
                {
                totheleft--;
                }
                else {
                  leftendflag = true;
                }


              }
              else {
                leftendflag = true;
                //filtnodes.push(chosensymbolbatch[totheleft].node);
              }
            }
            if(rightendflag === false)
            {
              let rightcondition = chosensymbolbatch[totheright].node.substring(0, fil.length) === fil;
              if(rightcondition)
              {

                if(keeptrackfiltnodes[chosensymbolbatch[totheright].node] != true)
                {filtnodes.push(chosensymbolbatch[totheright].node);}
                keeptrackfiltnodes[chosensymbolbatch[totheright].node] = true;

                endingelement = totheright;

                if(totheright < chosensymbolbatch.length-1)
                {
                totheright++;
                }
                else {
                  rightendflag = true;
                }
              }
              else {
                rightendflag = true;
                //filtnodes.push(chosensymbolbatch[totheleft].node);
              }
            }

            if(rightendflag === true && leftendflag === true)
            {
        //          console.log("broke out of while at", startingelement, endingelement );
              break;
            }

          }

          //filtnodes = Object.keys(filtnodes)

          //console.log("found one of those mofos at ", foundnodeatindex)

          //console.log(desymboltable["P"][22] );
          //console.log();
        //        console.log("Used ", iterations, "iterations");

          dispatch(setEntries(chosensymbolbatch.slice(startingelement, endingelement+1), filtnodes))
        }

      }
      else { //fetch from cloud

        var lucky = nodes.filter(function(element) {
          return element.nodename.substring(0, fil.length) === fil;
        });
        var step2 = [];
        lucky.forEach(myFunction);
        function myFunction(item, index) {
          step2.push(item.nodename);
        }

        client.callFunction("kryDataEntries", [step2]).then(Result => {
          //console.log("fetchEntries after getting ", Result)
          if(Result != 1)
          {
          dispatch(setEntries(Result, step2))
          }
          else
          {
            return
          }

        }).catch( error => {
                        console.log('Unable to retrieve questions. Error: ', error)});
      }


      //check if we have a dest



    };
};

//fetchEntriesByContent


export const fetchEntriesByContent = (texttosearch, filt, mode, someoneelsesbrain) => {
      const client = Stitch.defaultAppClient;
      let fil = filt;
    return dispatch => {
      client.callFunction("soekDataEntriesViaInhoud", [texttosearch, mode]).then(Result => {


        if(Result != 1)
        {
        dispatch(setEntriesForeign(Result, []))
        }
        else
        {
          return
        }

      }).catch( error => {
                      console.log('Unable to retrieve questions. Error: ', error)});
    };
};

export const fetchEntriesForPinpointedNode = (nodeinfo, superfilters) => {
      const client = Stitch.defaultAppClient;
    return dispatch => {
      client.callFunction("kryDataentriesForPinpointedNode", [nodeinfo, superfilters]).then(Result => {

        if(Result != 1)
        {
        dispatch(setEntriesForeignPlusExtraState(Result, [], nodeinfo))
        }
        else
        {
          return
        }

      }).catch( error => {
                      console.log('Unable to retrieve questions. Error: ', error)});
    };
};

export const fetchLatestEntries = (currentlyviewing) => {
      const client = Stitch.defaultAppClient;
    return dispatch => {

      client.callFunction("kryLast20Entries", [currentlyviewing]).then(Result => {
        //console.log("fetchLatiesEntries after getting ", Result)
        // let step2 = []
        // for(let y in Result)
        // {
        //   step2.push(Result[y].node)



        if(Result != 1)
        {
        dispatch(setEntriesForeign(Result, []))
        }
        else
        {
          return
        }
        // }

      }).catch( error => {
                      console.log('Unable to retrieve questions. Error: ', error)})
                      dispatch(setLoadingOverlayControllerToFalse());
    };
};

export const fetchLatestEntriesMode2 = (currentlyviewing, ownerid) => {
      const client = Stitch.defaultAppClient;
    return dispatch => {

      client.callFunction("Mode2_kryLaste20Entries", [currentlyviewing, ownerid]).then(Result => {
        //console.log("fetchLatiesEntries after getting ", Result)
        // let step2 = []
        // for(let y in Result)
        // {
        //   step2.push(Result[y].node)



        if(Result != 1)
        {
        dispatch(setEntriesForeign(Result, []))
        }
        else
        {
          return
        }
        // }

      }).catch( error => {
                      console.log('Unable to retrieve questions. Error: ', error)});
    };
};

export const fetchLatestEntriesMode3 = (m) => {
      const client = Stitch.defaultAppClient;
    return dispatch => {

      client.callFunction("kryLast20EntriesMode3", [m]).then(Result => {

        if(Result != 1)
        {
        dispatch(setEntriesForeign(Result, []))
        }
        else
        {
          return
        }

      }).catch( error => {
                      console.log('Unable to retrieve questions. Error: ', error)});



                      client.callFunction("kryNeuroactivityNodes", [m]).then(Result => {

                        if(Result != 1)
                        {
                        dispatch(setPinpointedNodes(Result, []))
                        }
                        else
                        {
                          return
                        }

                      }).catch( error => {
                                      console.log('Unable to retrieve questions. Error: ', error)});

    };




};

// export const fetchFavorites = (custom_data) => {
//       const client = Stitch.defaultAppClient;
//     return dispatch => {
//
//
//
//       client.callFunction("kryGunstelinge").then(Result => {
//         //console.log("fetchLatiesEntries after getting ", Result)
//         // let step2 = []
//         // for(let y in Result)
//         // {
//         //   step2.push(Result[y].node)
//
//         try
//         {
//         if(Result[0].lzw != undefined)
//         {
//
//             for(let x in Result)
//             {
//             Result[x].lzw = lzwCompress.unpack(Result[x].lzw);
//             //console.error("def[x].lzw",   def[x].lzw)
//             Result[x].lzw = DOMPurify.sanitize(Result[x].lzw);
//             //console.error("DOMPurify.sanitize(def[x].lzw)", DOMPurify.sanitize(def[x].lzw))
//             Result[x].lzw = JSON.parse(Result[x].lzw);
//             //console.error("JSON.parse(def[x].lzw)", def[x].lzw)
//             }
//
//         }
//
//         }
//         catch(e){
//           console.error(e)
//         }
//
//         if(Result != 1)
//         {
//
//
//         dispatch(setEntries(Result, []))
//         }
//         else
//         {
//           return
//         }
//         // }
//
//       }).catch( error => {
//                       console.log('Unable to retrieve questions. Error: ', error)});
//     };
// };
export const fetchOne = (fetchThis, ownerid, mode) => {
      const client = Stitch.defaultAppClient;
    return dispatch => {

      var step2 = [];
      step2.push(fetchThis);

      client.callFunction("kryDataEntriesOfAnotherUser", [step2, ownerid, 2], ).then(Result => {


        if(Result != 1)
        {
        dispatch(setEntriesForeign(Result, step2))
        }
        else
        {
          return
        }
        //console.log("fetchEntries after getting ", Result)

      }).catch( error => {
                      console.log('Unable to retrieve questions. Error: ', error)});
    };
};

export const fetchAnomalies = () => {
      const client = Stitch.defaultAppClient;
    return dispatch => {

      client.callFunction("kryAnomalies").then(Result => {
        //console.log("Fetched anomalies bro", Result)

        dispatch(setAnomalies(Result))
        return Result;
      }).catch( error => {
                      console.log('Unable to retrieve anomlies ', error)});
    };
};

// export const fixSingleAnomaly = (objid, nodeToFix) => {
//       const client = Stitch.defaultAppClient;
//     return dispatch => {
//       //console.log("arguments to opdateerfix", [nodeToFix] )
//       client.callFunction("opdateerFixSingleAnomaly", [objid, nodeToFix]).then(Result => {
//
//         //console.log("Result of fixing Single Anomaly", Result);
//
//         //dispatch(setAnomalies(Result))
//       }).catch( error => {
//                       console.log('Unable to retrieve anomlies ', error)});
//     };
// };

// export const setAndAppendClosedAlertsToo= (alerts) => {
//     return {
//       type: actionTypes.SET_AND_APPEND_CLOSED_ALERTS_TOO,
//       alerts: alerts,
//     };
// };

export const setUser = (user) => {
    return {
      type: actionTypes.SET_USER,
      user: user,
    };
};

export const changeModeFrom1to3AndBack = () => {
    return {
      type: actionTypes.CHANGEMODEFROM1TO3ANDBACK
    };
};

export const changeToSpecificMode = (mo) => {
    return {
      type: actionTypes.CHANGE_TO_SPECIFIC_MODE,
      def: mo
    };
};

export const changeToMode2 = (ownerid) => {
    return {
      type: actionTypes.CHANGE_TO_MODE_2,
      newownerid: ownerid,
    };
};


export const setLoadingOverlayControllerToTrue = () => {
    return {
      type: actionTypes.ACTIVATE_LOADINGOVERLAY,
    };
};

export const setLoadingOverlayControllerToFalse = () => {
    return {
      type: actionTypes.DEACTIVATE_LOADINGOVERLAY,
    };
};

// export const additionToTransformedAlert = (newAlert) => {
//     return {
//       type: actionTypes.ADDITION_TO_TRANSFORMEDALERT,
//       newAlert: newAlert,
//     };
// };
// export const updateToTransformedAlert = (newAlert, oldAlert) => {
//     return {
//       type: actionTypes.UPDATE_TO_TRANSFORMEDALERT,
//       newAlert: newAlert,
//       oldAlert: oldAlert,
//     };
// };
// export const deleteToTransformedAlert = (oldAlert) => {
//     return {
//       type: actionTypes.DELETE_TO_TRANSFORMEDALERT,
//       oldAlert: oldAlert,
//     };
// };
export const resetFilteredNodesForNewEntry = () => {
    return {
      type: actionTypes.RESET_FILTEREDNODES,
    };
};
// export const setAlerts = (alerts) => {
//     return {
//       type: actionTypes.SET_ALERTS,
//       alerts: alerts,
//     };
// };

export const setNodes = (nodes) => {
    return {
      type: actionTypes.SET_NODES,
      nodes: nodes,
    };
};



export const setPinpointedNodes = (pinpointednodes) => {
    return {
      type: actionTypes.SET_NODES_PINPOINTMODE3,
      pinpointednodes: pinpointednodes,
    };
};

export const setEntries = (entries, filterednodes) => {
    return {
      type: actionTypes.SET_ENTRIES,
      entries: entries,
      filterednodes: filterednodes,
    };
};
export const setEntriesForeign = (entries, filterednodes) => {
    return {
      type: actionTypes.SET_ENTRIES_FOREIGN,
      entries: entries,
      filterednodes: filterednodes,
    };
};
export const setEntriesForeignPlusExtraState = (entries, filterednodes, newstatevalue) => {
  //function will also save the node we click on so we can use it alter during the fulter mode.
    return {
      type: actionTypes.SET_ENTRIES_FOREIGN_PLUS_EXSTRA_STATE,
      entries: entries,
      filterednodes: filterednodes,
      newstatevalue: newstatevalue
    };
};
export const setAnomalies = (anomalies) => {
    return {
      type: actionTypes.SET_ANOMALIES,
      anomalies: anomalies,
    };
};
export const filterNodes = (filter, searchornot) => {
    return {
      type: actionTypes.FILTER_NODES,
      filter: filter,
      searchornot: searchornot,
    };
};

export const filterNodesPinpointMode3 = (filter, searchornot) => {
    return {
      type: actionTypes.FILTER_NODES,
      filter: filter,
      searchornot: searchornot,
    };
}
export const filterEntriesOnChipClick = (dataentry) => {
    return {
      type: actionTypes.FILTER_ENTRIES_ON_CHIP_CLICK,
      dataentry: dataentry,
    };
};
export const deleteFromReduxToo = () => {
    return {
      type: actionTypes.DELETE_FROM_REDUX_TOO
    };
};


export const updateLocalNodesAfterAddingANewOne= (newnode) => {
    return {
      type: actionTypes.UPDATE_NODE_AFTER_NEW_NODE_CREATED,
      nodetoupdate: newnode,
    };
};

export const setDataEntriesSymbolTable = (symboltable) => {
    return {
      type: actionTypes.SET_DATAENTRIES_SYMBOL_TABLE,
      symboltable: symboltable,
      mode: 1

    };
};

export const setCustomData = (s) => {
    return {
      type: actionTypes.SET_CUSTOMDATA,
      customdata: s,
      mode: 1

    };
};

export const resetMem = () => {
    return {
      type: actionTypes.RESETMEM,

    };
};

export const changeADataEntryVote = (id, v, vwei, vote) => {
    return {
      type: actionTypes.CHANGE_A_DATAENTRY_VOTE,
      oneToFilterOut: id,
      votcha: v,
      vwei: vwei,
      vote: vote

    };
};

export const changeADataEntry = (index, key, value) => {
    return {
      type: actionTypes.CHANGE_A_DATAENTRY,
      index: index,
      key: key,
      value: value,
    };
};



export const removeFromDataentriesAfterDelete = (idtodelete) => {
    return {
      type: actionTypes.REMOVE_FROM_DE_AFTER_DELETE,
      def: idtodelete

    };
};

export const setNodesSymbolTable = (nodesymboltable) => {
    return {
      type: actionTypes.SET_NODES_SYMBOL_TABLE,
      nodesymboltable: nodesymboltable,
    };
};

export const handleDeleteChipDuringSearch = (oneToFilterOut) => {
    return {
      type: actionTypes.HANDLE_DELETE_CHIP_DURING_SEARCH,
      oneToFilterOut: oneToFilterOut,
    };
};
