import React, { Component } from 'react';
import Aux from '../../hoc/beesaux/beesaux';

const withErrorHandler = ( WrappedComponent ) => {
    return props => {
          console.log("ERRORHANDLER SOME ERROR HAPPENED");
            return (
                <Aux>
                    <WrappedComponent {...props} />
                </Aux>
            );
    }
}

export default withErrorHandler;
