import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';

import Aux from '../../hoc/beesaux/beesaux';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import withRoot from '../../withRoot';
//import * as actions from '../../store/actions/index';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
//import { Icon } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MenuIcon from '@material-ui/icons/Menu';
import HowToVote from '@material-ui/icons/HowToVote';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import * as actions from '../../store/actions/index';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import LoadingOverlay from 'react-loading-overlay';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import Chip from '@material-ui/core/Chip';
import MaterialTable from 'material-table';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import MySnackbarContentWrapper from '../../components/MySnackbarContentWrapper'
import SearchBar from '@opuscapita/react-searchbar';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import { fade } from '@material-ui/core/styles/colorManipulator';
import SendIcon from '@material-ui/icons/Send';
import Tree from 'react-tree-graph';
import './App.css';
import Fab from '@material-ui/core/Fab';
import Bees from './account_tree-24px.svg'
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MyAppBar from '../../components/MyAppBar/MyAppBar'
//import MyNewEntryBox from '../../components/MyNewEntryBox/MyNewEntryBox'
import EditorComponent from '../EditorComponent/EditorComponent'
import Profile from '../Profile/Profile'
import { useState, useEffect } from 'react'
// import YouTubeIcon from '@material-ui/icons/YouTube';
//import lzwCompress from 'lzwcompress';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { binarySearchForFindingDataEntryByNodeName,
          binarySearchFindNodeThatMatchesPefectly,
          binarySearchFindWhereToInsertDataentry,
          binarySearchFindWhereToInsertNode,
          binarySearchFindWhereToInsertDataentryPerformant,
          binarySearchForDataEntryEditingAndDeleting, patternizedCompressor, patternizedDecompressor} from '../../shared/utility';

import {
Redirect
} from 'react-router-dom';
import { Stitch } from 'mongodb-stitch-browser-sdk'
import { BSON } from 'mongodb-stitch-browser-sdk';
import { memo } from 'react';
import { openDB, deleteDB } from 'idb/with-async-ittr.js';
//import { createMuiTheme } from '@material-ui/core/styles';
//import { Route, Redirect } from 'react-router';

//import './../../shared/prism.css'
//import './../../shared/prism.js'
//import { defineCustomElements } from 'snippet-highlight/dist/loader';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import loadable from "@loadable/component";

import Link from '@material-ui/core/Link';

import strictUriEncode from 'strict-uri-encode';
import { VariableSizeList as List } from 'react-window';
import MyNewEntryBox from '../../components/MyNewEntryBox/MyNewEntryBox';

const Info = loadable(() => import("../../components/Info/Info"),
  {
    fallback: "Loading..."
  }
);
const Notifications = loadable(() => import("../../containers/Notifications/Notifications"),
  {
    fallback: "Loading..."
  }
);
const Propagation = loadable(() => import("../../containers/Propagation/Propagation"),
  {
    fallback: "Loading..."
  }
);

// const EntryQVotingDialog = loadable(() => import('../../components/EntryQVotingDialog/EntryQVotingDialog'),
//   {
//     fallback: "Loading..."
//   }
// );

const SuperPowers = loadable(() => import('../../components/Superpowers/SuperPowers'),
  {
    fallback: "Loading..."
  }
);

const Control = loadable(() => import('../../components/Control/Control'),
  {
    fallback: "Loading..."
  }
);

// const MyNewEntryBox = loadable(() => import('../../components/MyNewEntryBox/MyNewEntryBox'),
//   {
//     fallback: "Loading..."
//   }
// );



const ForMe = loadable(() => import('../../components/ForMe/ForMe'),
  {
    fallback: "Loading..."
  }
);


//

//import jsesc from 'jsesc';

// const  = loadable(() => import("../../components/MyNewEntryBox/MyNewEntryBox"),
//   {
//     fallback: "Loading..."
//   }
// );

//defineCustomElements(window);



// let teststring = "Hello this is line 1
// and this is line 2
// and this is line 3";

const MyBoxForNodes = memo(({ filtered_nodes , insertingNewEntry, handleClickOnIndividualNeurotagWhenInsertingNewEntry,editNode, classes }) => {
  return (

    <div className={((insertingNewEntry) || (editNode)) ? classes.boxForFoundNodes2 : false}>
    {filtered_nodes.map(row => (
      <Chip
    name={row}
    label={row}
    className={classes.chip}
    clickable
    color="#5a25de"
    onClick={() => handleClickOnIndividualNeurotagWhenInsertingNewEntry(row)}
    />
         ))}

    </div>

  )
});

const MyBoxForPinpointedNodes = memo(({ pinpointednodes , f, classes, fetchEntriesForPinpointedNodeRef }) => {
  return (

    <div className={classes.boxForFoundNodes2}>
    {pinpointednodes.map(row => (

      <Badge classes={{badge: classes.badge}} badgeContent={row.entries} max={9999} color="error">

      <Chip
    name={row._id}
    label={row._id}
    className={classes.chip}
    clickable
    color="#5a25de"
    onClick={() => {fetchEntriesForPinpointedNodeRef(row._id, [0, 1])}}
    variant="outlined"
    />

   </Badge>


         ))}

    </div>

  )
});


const QualityRB= memo(({ valueQuality, setValueQuality, fetchEntriesForPinpointedNodeRef,nodedetail, valueTimeframe, classes }) => {
  return (
    <FormControl component="fieldset" classes={{root: classes.badge}}>
              <FormLabel classes={{root: classes.radiolabel, focused: classes.radiolabel}} >Post Quality</FormLabel>
              <RadioGroup
                aria-label="Quality"
                name="postqual1"
                className={classes.group}
                value={valueQuality}
                onChange={(e)=> {setValueQuality(e.target.value); fetchEntriesForPinpointedNodeRef(nodedetail, [parseInt(e.target.value), parseInt(valueTimeframe)])}}
              >
                <FormControlLabel classes={{label: classes.radiolabel}} value="1" control={<Radio />} label="Useful" />
                <FormControlLabel classes={{label: classes.radiolabel}} value="2" control={<Radio />} label="Excellent" />
                <FormControlLabel classes={{label: classes.radiolabel}} value="3" control={<Radio />} label="Funny" />
                <FormControlLabel classes={{label: classes.radiolabel}} value="6" control={<Radio />} label="Normal" />
              </RadioGroup>
            </FormControl>

)
});

const TimeframeRB= memo(({ valueQuality,setValueTimeframe, fetchEntriesForPinpointedNodeRef,nodedetail, valueTimeframe, classes }) => {
  return (
    <FormControl component="fieldset" classes={{root: classes.badge}}>
              <FormLabel classes={{root: classes.radiolabel,focused: classes.radiolabel}} >Timespan</FormLabel>
              <RadioGroup
                aria-label="Timespan"
                name="timespan1"
                className={classes.group}
                value={valueTimeframe}
                onChange={(e)=> {setValueTimeframe(e.target.value); fetchEntriesForPinpointedNodeRef(nodedetail, [parseInt(valueQuality), parseInt(e.target.value)])}}
              >
                <FormControlLabel classes={{label: classes.radiolabel}} value="1" control={<Radio />} label="Last 24 hrs" />
                <FormControlLabel classes={{label: classes.radiolabel}} value="2" control={<Radio />} label="Last 1 week" />
                <FormControlLabel classes={{label: classes.radiolabel}} value="3" control={<Radio />} label="Last 1 month" />
              </RadioGroup>
            </FormControl>
)
});

const SearchForRB= memo(({ thethree, settypeselected,setdialogIsOpen, classes }) => {
  return (
    <FormControl component="fieldset" classes={{root: classes.badge}}>
             <FormLabel classes={{root: classes.radiolabel,focused: classes.radiolabel}} >Search For...</FormLabel>
             <RadioGroup
               aria-label="Timespan"
               name="timespan1"
               className={classes.group}
               value={thethree[settypeselected]}
               onChange={(e)=> {if(e.target.value==="OTHER"){setdialogIsOpen(true); return }; settypeselected(e.target.value)}}
             >
               <FormControlLabel classes={{label: classes.radiolabel}} value="NOTE" control={<Radio />} label="Notes" />
               <FormControlLabel classes={{label: classes.radiolabel}} value="ARTICLE" control={<Radio />} label="Articles" />
               <FormControlLabel classes={{label: classes.radiolabel}} value="GAME" control={<Radio />} label="Games" />
               <FormControlLabel classes={{label: classes.radiolabel}} value="OTHER" control={<Radio />} label="Other..." />
             </RadioGroup>
           </FormControl>
)
});


const MyBoxForSuperFilters = memo(({ classes, fetchEntriesForPinpointedNodeRef, nodedetail,setEntriesRef, dataentries_cache }) => {


  const [shouldDisplaySF, setShouldDisplaySF] = React.useState(false);
  const [valueQuality, setValueQuality] = React.useState('0');
  const [valueTimeframe, setValueTimeframe] = React.useState('1');
  //const [valueSearchFor, setvalueSearchFor] = React.useState('1');
  const [dialogIsOpen, setdialogIsOpen] = useState(false);
  //const [valueLength, setValueLength] = React.useState('0');
  //const [valueLevel, setValueLevel] = React.useState('0');

  const thethree = {"NOTE": 1, "ARTICLE": 2, "GAME": 3, "OTHER": 4}
  const [typeselected, settypeselected] = React.useState('1');
  const CSKT = {"GAME": {superfilters: ["Story Score", "Gameplay Score", "GPX Score", "Difficulty Score", "Relaxing Score", "Drug Score"], superfilterssleutel: ["story", "gameplay", "gpx", "diff", "relaxing", "drug"], sf_details: {grades: ["story", "gameplay", "gpx", "diff", "relaxing", "drug"], enums: [{name: "Genre", superfilterssleutel: "genre", enums: ["Open World","RPG","Action", "Adventure", "Sports", "Platformer", "Shooter", "Racing"]}, {name: "Subgenre", superfilterssleutel: "subgenre", enums: ["Open World","RPG","Action", "Adventure", "Sports", "Platformer", "Shooter", "Racing"]}], onetimetraits: ["Hidden Gem"]}},
              "ANIME": {superfilters: ["Story Score", "Character Score", "GPX Score", "Drug (Addictiveness) Score", "Romance Score", "Comedy Score", "Slice Of Life Score"], superfilterssleutel: ["story", "characters", "gpx", "drug", "romance", "comedy", "sol"], sf_details: {grades: ["story", "characters", "gpx", "drug", "romance", "comedy", "sol"], enums: [], onetimetraits: ["Hidden Gem"]}},
              "ANIME OP": {superfilters: ["GPX Score", "Drug (Addictiveness) Score"], superfilterssleutel: ["gpx","drug"]},
              "ARTICLE": {superfilters: ["Scrolls"], superfilterssleutel: ["contenttype"], sf_details: {grades: ["scrolls", "comments"], enums: [{name: "Content Type", superfilterssleutel: 'contenttype', enums: ["HOW TO X","INTRODUCTION TO X", "CHEATSHEET", "LISTICLE", "Q&A", "USEFUL LINKS", "IN DEPTH ON X"]}, ], onetimetraits: []}},



            }
const [enumsstates, setenumsstates] = React.useState(['50', '50']); // Must equal longest enums at least.

const [valueGrades, setValueGrades] = React.useState('0');

function handleClose() {
  setdialogIsOpen(!dialogIsOpen);
}


  return (
    <div>
    {shouldDisplaySF && <div className={classes.boxForFoundNodes2}>





    <QualityRB valueQuality={valueQuality} setValueQuality={setValueQuality} fetchEntriesForPinpointedNodeRef={fetchEntriesForPinpointedNodeRef} nodedetail={nodedetail} valueTimeframe={valueTimeframe} classes={classes}/>
    <TimeframeRB valueQuality={valueQuality} setValueTimeframe={setValueTimeframe} fetchEntriesForPinpointedNodeRef={fetchEntriesForPinpointedNodeRef} nodedetail={nodedetail} valueTimeframe={valueTimeframe} classes={classes}/>
    <SearchForRB thethree={thethree} typeselected={typeselected} settypeselected={settypeselected} setdialogIsOpen={setdialogIsOpen} classes={classes}/>



                      <Dialog classes={{paper: classes.adsdialog}}open={dialogIsOpen} fullScreen={true} onClose={handleClose} aria-labelledby="simple-dialog-title">

                         <div className={classes.thingsundersearchbarouter}>
                           <div className={classes.header}>
                           {"Content"}
                           </div>


                           <FormControl component="fieldset" classes={{root: classes.badge}}>
                                    <RadioGroup
                                      aria-label="Timespan"
                                      name="timespan1"
                                      className={classes.group}
                                      value={thethree[settypeselected]}
                                      onChange={(e)=> {settypeselected(e.target.value); setdialogIsOpen(false); }}
                                    >
                                      <FormControlLabel classes={{label: classes.radiolabel}} value="PERSON" control={<Radio />} label="People" />
                                      <FormControlLabel classes={{label: classes.radiolabel}} value="YOUTUBE CHANNEL" control={<Radio />} label="Youtube Channels" />
                                      <FormControlLabel classes={{label: classes.radiolabel}} value="ANIME" control={<Radio />} label="Anime" />
                                    </RadioGroup>
                                  </FormControl>




                         </div>
                       </Dialog>




  {CSKT[typeselected] != undefined && <FormControl component="fieldset" classes={{root: classes.badge}}>
            <FormLabel classes={{root: classes.radiolabel,focused: classes.radiolabel}} >Sort By</FormLabel>
            <RadioGroup
              aria-label="Sort by Grade"
              name="sortbygrade1"
              className={classes.group}
              value={valueGrades}
              onChange={(e)=> {
                const sf = CSKT[typeselected].sf_details.grades[parseInt(e.target.value)]
                //virtualizedsorting
                // let biggest = 0
                // let biggestArray = [1]
                // for(let x = 0; x < dataentries_cache.length; x++)
                // {
                //   if(dataentries_cache[x][sf] > biggest)
                //   {
                //     biggestArray[0] = dataentries_cache[x]
                //     biggest = dataentries_cache[x][sf]
                //   }
                //
                // }

                 const filteroutunknowns = dataentries_cache.filter(function(e) { return e[sf] != undefined})// Officaial nearcher objects absoluitely needs to have all the sf ratings present to increase performance OR filter it out first like here.
                 const sorted = filteroutunknowns.sort(function(a, b) { return b[sf] - a[sf] })
                 //console.log(sf, sorted)
                 setEntriesRef(sorted,[])

                 setValueGrades(e.target.value)


              }}
            >
            {
              CSKT[typeselected].sf_details.grades.map((row,index)=><FormControlLabel classes={{label: classes.radiolabel}} value={index.toString()} control={<Radio />} label={CSKT[typeselected].superfilters[index]} />)
            }
            </RadioGroup>
          </FormControl>}

                            {
                              CSKT[typeselected] != undefined && CSKT[typeselected].sf_details.enums.map((row,index)=>
                              <FormControl component="fieldset" classes={{root: classes.badge}}>
                              <FormLabel classes={{root: classes.radiolabel,focused: classes.radiolabel}} >{CSKT[typeselected].sf_details.enums[index].name}</FormLabel>
                              <RadioGroup
                              aria-label="Sort by Grade"
                              name="sortbygrade1"
                              className={classes.group}
                              value={enumsstates[index]}
                              onChange={(e)=> {
                                let z = [...enumsstates];
                                z[index] = e.target.value
                                setenumsstates(z)

                                let x = row.enums[parseInt(e.target.value)];
                                let y = row.superfilterssleutel

                                if(dataentries_cache != undefined)
                                {
                                  const filtereddataentries = dataentries_cache.filter(obj => { return obj[y] === x;});
                                  setEntriesRef(filtereddataentries,[])
                                }




                              }}
                              >
                              {
                                CSKT[typeselected].sf_details.enums[index].enums.map((row,index2)=><FormControlLabel classes={{label: classes.radiolabel}} value={index2.toString()} control={<Radio />} label={CSKT[typeselected].sf_details.enums[index].enums[index2]} />)
                              }
                              </RadioGroup>
                              </FormControl>)
                            }
                            {
                              CSKT[typeselected] != undefined && <FormControl component="fieldset" classes={{root: classes.badge}}>
                                                  <FormLabel classes={{root: classes.radiolabel,focused: classes.radiolabel}} >Special Characteristics</FormLabel>
                                                  <RadioGroup
                                                    aria-label="Sort by Grade"
                                                    name="sortbygrade1"
                                                    className={classes.group}
                                                    value={valueTimeframe}
                                                    onChange={(e)=> {

                                                    }}
                                                  >
                                                  {
                                                    CSKT[typeselected].sf_details.onetimetraits.map((row,index)=><FormControlLabel classes={{label: classes.radiolabel}} value="0" control={<Radio />} label={CSKT[typeselected].sf_details.onetimetraits[index]} />)
                                                  }
                                                  </RadioGroup>
                                                </FormControl>
                            }





</div>}
{(nodedetail != null) && <div className={classes.entryoptions}><div className={classes.hoverusername} onClick={() => {setShouldDisplaySF(!shouldDisplaySF)}}>Super Filters</div></div>}
    </div>

  )
});

const SFLookup = {
  "GAME": {
    "DIFF": {
      "EASY": {
        leftSideBC: '#47F53B',
        rightSideBC: '#9EFF8F',
      },
      "NORMAL": {
        leftSideBC: '#FF9933',
        rightSideBC: '#FCAE79'
      },
      "HARD": {
        leftSideBC: '#FF0000',
        rightSideBC: '#F24444'
      }
    }
  },
  "ARTICLE": {
    "CONTENTTYPE": {
      "HOW TO X": {
        backgroundColor: '#8FFF93',
        color: 'black'
      },
      "INTRODUCTION TO X": {
        backgroundColor: '#14992A',
        color: 'white'
      },
      "IN DEPTH ON X": {
        backgroundColor: 'black',
        color: 'white'
      },
      "CHEATSHEET": {
        backgroundColor: '#FDFFF7',
        color: 'black'
      },
      "USEFUL LINKS": {
        backgroundColor: '#1A0DAB',
        color: 'white'
      },
      "Q&A": {
        backgroundColor: '#FF26DB',
        color: 'white'
      },
      "LISTICLE": {
        backgroundColor: '#33FFFF',
        color: 'black'
      }
    }
  },
  "LEVEL": {
    "BEGINNER": {
      backgroundColor: '#5EFF00',
      color: 'black'
    },
    "INTERMEDIATE": {
      backgroundColor: '#FF6F00',
      color: 'black'
    },
    "ADVANCED": {
      backgroundColor: '#E60000',
      color: '#E6E3E3'
    },
    "EXPERT": {
      backgroundColor: '#990000',
      color: '#CCCACA'
    }
  },
  "RENOWN": {
    "OBSCURE": {
      backgroundColor: '#333333',
      color: 'white'
    },
    "LITTLE-KNOWN": {
      backgroundColor: '#5D914A',
      color: 'white',
      opacity: 0.7
    },
    "RISING STAR": {
      backgroundColor: '#92FF5C',
      color: 'black',
      opacity: 0.9
    },
    "FAMOUS": {
      backgroundColor: '#00FF00',
      color: 'black'
    },
    "SUPERSTAR": {
      backgroundColor: '#FFFF00',
      color: 'black'
    },
  },
}

//    onDelete={(event)=> {this.handleDelete(row)}}
//classes={{underlineHover: classes.hoverusername}}

const SuperfiltersEntry = (props) => {

const {row} = props


if(row.datatype === "ARTICLE")
{
  return (

    <div
    style={{
      width: '100%',
      paddingTop: '3%',
      margin: '10px 10px 10px 10px',
      display: 'flex',
      flexWrap: 'wrap',
      opacity: 0.9
    }}
    >
    {
      row.scrolls != undefined && <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#D4E1F5",color: 'black',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.scrolls + " scrolls"}</div></div></div>
    }
    {
      row.level != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: SFLookup.LEVEL[row.level].backgroundColor,color: SFLookup.LEVEL[row.level].color,margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.level}</div></div></div> : false
    }
    {
      row.contenttype != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: SFLookup.ARTICLE.CONTENTTYPE[row.contenttype].backgroundColor,color: SFLookup.ARTICLE.CONTENTTYPE[row.contenttype].color,margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.contenttype}</div></div></div> : false
    }
    </div>

  )
}






if(row.datatype === "GAME")
{
  let diff = "NORMAL"; //We set to nromal by default, value only fields need to be interpreted unfortunately.
  if(row.diff > 60)
  {
    diff = "HARD"
  }
  if(row.diff < 40){
    diff = "EASY"
  }

  return (

    <div
    style={{
      width: '100%',
      paddingTop: '70px',
      margin: '10px 10px 10px 10px',
      display: 'flex',

      flexWrap: 'wrap'
    }}
    >
    {
      row.final != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "black",color: 'white',margin:'5px 5px 5px 5px',width: 'fit-content', textAlign: 'center', borderStyle: 'solid', borderRadius: '1px', borderColor: 'white'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>FINAL</div></div><div style={{ backgroundColor: "white",color: 'black', padding: '9px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.final}</div></div> : false
    }
    {
      row.story != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#5F59FF",color: '#EBEBEB',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>STORY</div></div><div style={{ backgroundColor: "#4400FF",color: '#EBEBEB', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.story}</div></div> : false
    }
    {
      row.gameplay != undefined ? <div onClick={()=>{}} style={{ display: 'flex', alignItems: 'center', backgroundColor: "#FF3D3D",color: '#EBEBEB',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center', height: 'fit-content'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>GAMEPLAY</div></div><div style={{ backgroundColor: "#C90000",color: '#EBEBEB', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.gameplay}</div></div> : false
    }
    {
      row.gpx != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "black", border: '1px solid white', color: 'white',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '7px 8px 7px 12px', textAlign: 'center', fontSize: '0.9rem', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>GPX</div></div><div style={{backgroundColor: 'white', height: '100%', borderRadius: '5px 2px 2px 5px',}}><div style={{ backgroundColor: "white",color: 'black', padding: '7px 8px 7px 8px', borderRadius: '5px',width: 'fit-content', fontSize: '0.9rem', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.gpx}</div></div></div> : false
    }
    {
      row.diff != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: SFLookup.GAME.DIFF[diff].leftSideBC,color: 'black',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{diff}</div></div><div style={{ backgroundColor: SFLookup.GAME.DIFF[diff].rightSideBC,color: 'black', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.diff}</div></div> : false
    }
    {
      row.time != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#BCC7D9",color: 'black',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>TIME</div></div><div style={{ backgroundColor: "#5A5F69",color: '#EBEBEB', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.time}</div></div> : false
    }
    {
      row.relaxing != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#006600",color: '#EBEBEB',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>RELAXING</div></div><div style={{ backgroundColor: "#28C928",color: 'black', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.relaxing}</div></div> : false
    }
    {
      row.drug != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#D80073",color: '#EBEBEB',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>DRUG</div></div><div style={{ backgroundColor: "#FF7DF2",color: 'black', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.drug}</div></div> : false
    }
    </div>

  )
}

if(row.datatype === "ANIME")
{

  return (

    <div
    style={{
      width: '100%',
      paddingTop: '70px',
      margin: '10px 10px 10px 10px',
      display: 'flex',
      flexWrap: 'wrap'
    }}
    >
    {
      row.story != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#5F59FF",color: '#EBEBEB',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>STORY</div></div><div style={{ backgroundColor: "#4400FF",color: '#EBEBEB', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.story}</div></div> : false
    }
    {
      row.characters != undefined ? <div  style={{ display: 'flex', alignItems: 'center', backgroundColor: "#FF3D3D",color: '#EBEBEB',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center', height: 'fit-content'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>CHARACTERS</div></div><div style={{ backgroundColor: "#C90000",color: '#EBEBEB', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.characters}</div></div> : false
    }
    {
      row.gpx && <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "black", border: '1px solid white', color: 'white',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '7px 8px 7px 12px', textAlign: 'center', fontSize: '0.9rem', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>GPX</div></div><div style={{backgroundColor: 'white', height: '100%', borderRadius: '5px 2px 2px 5px',}}><div style={{ backgroundColor: "white",color: 'black', padding: '7px 8px 7px 8px', borderRadius: '5px',width: 'fit-content', fontSize: '0.9rem', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.gpx}</div></div></div>
    }
    {
      row.drug != undefined ? <div  style={{ display: 'flex', alignItems: 'center', backgroundColor: "#D80073",color: '#EBEBEB',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>DRUG</div></div><div style={{ backgroundColor: "#FF7DF2",color: 'black', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.drug}</div></div> : false
    }
    {
      row.romance != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#FF0000",color: '#EBEBEB',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>ROMANCE</div></div><div style={{ backgroundColor: "#960000",color: 'white', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.romance}</div></div> : false
    }
    {
      row.comedy != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#33FF33",color: 'black',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>COMEDY</div></div><div style={{ backgroundColor: "#007500",color: 'white', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.comedy}</div></div> : false
    }
    {
      row.sol != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#00FFFF",color: 'black',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>SOL</div></div><div style={{ backgroundColor: "#00BABA",color: 'black', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.sol}</div></div> : false
    }
    {
      row.ecchi != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#00FFFF",color: '#EBEBEB',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '8px 8px 8px 12px', textAlign: 'center', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>ecchi</div></div><div style={{ backgroundColor: "#00BABA",color: 'black', padding: '9px', borderRadius: '5px',width: 'fit-content', fontSize: '13px', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.ecchi}</div></div> : false
    }
    </div>

  )
}

if(row.datatype === "REDDIT COMMUNITY")
{
  return (

    <div
    style={{
      width: '100%',
      paddingTop: '3%',
      margin: '10px 10px 10px 10px',
      display: 'flex',
      flexWrap: 'wrap',
      opacity: 0.9
    }}
    >
    {
      row.wholesome != undefined ? <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "black", border: '1px solid white', color: 'white',margin:'5px 5px 5px 5px', borderRadius: '5px',width: 'fit-content', textAlign: 'center'}}><div><div style={{padding: '7px 8px 7px 12px', textAlign: 'center', fontSize: '0.9rem', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>WHOLESOME</div></div><div style={{backgroundColor: 'white', height: '100%', borderRadius: '5px 2px 2px 5px',}}><div style={{ backgroundColor: "white",color: 'black', padding: '7px 8px 7px 8px', borderRadius: '5px',width: 'fit-content', fontSize: '0.9rem', fontFamily: `'Helvetica', 'Arial', sans-serif`}}>{row.wholesome}</div></div></div> : false //For reddit community
    }
    </div>

  )
}

else {
  return(<div></div>)
}





}

const Entry = ({ index, style, data }) => {
//console.log(data)
 let row = data.data[index];
// let classes = data.classes
// let isMod3 = data.isMod3
// let mode = data.mode
// let isNotDesktop = data.isNotDesktop

const { classes, isMod3, mode, isNotDesktop, classifyEntryQuality, viewUser, handleClickOnEntryNeurotag, wantToRead, handleClickOnBox2Summary, handleEdit, openShouldDeleteDialog, propagate, calEntVotDial, removeFromDataentriesAfterDelete } = data



//return (<div style={style} key={index} >{index+ "ass"}</div>);

  let superdiv = false
  let box = classes.box
  let box1 = classes.box1
  let box11 = classes.box11
  let box12 = classes.box12
  let box13 = classes.box13
  let box2 = classes.box2
  let box3 = classes.box3
  let qclass = undefined;
  let qclasstext = undefined
  let chip = classes.chip
  let box03 = classes.box03
  let box01 = classes.box01
  let box02 = classes.box02
  let box04 = classes.box04
  let box05 = classes.box05


  // return <div className={classes.test}><div >{"ASSSSS"}</div></div>

if(isMod3)
{
switch (row.qclass) {
//case undefined:
//css to use
case undefined:
break;
case 1:
box = classes.boxUSEFUL;
box1 = classes.box1USEFUL;
box11 = classes.box11USEFUL;
box12 = classes.box12USEFUL;
box13 = classes.box13FUNNY; //can be reused
box2 = classes.box2USEFUL;
box3 = classes.box3USEFUL;
qclass = classes.qclassUSEFUL;
chip = classes.chipUSEFUL
qclasstext = "USEFUL"


box03 = classes.box03LOST
box01 = classes.box01WON
box02 = classes.box02LOST
box04 = classes.box04LOST
box05 = classes.box05LOST
break;
case 2:
box = classes.boxALL;
box1 = classes.box1EXC;
box11 = classes.box11EXC;
box12 = classes.box12EXC;
box13 = classes.box13FUNNY;
box2 = classes.box2EXC;
box3 = classes.box3EXC;
qclass = classes.qclassEXC;
qclasstext = "EXCELLENT"
chip = classes.chip // reused

box03 = classes.box03LOST
box01 = classes.box01LOST
box02 = classes.box02WON
box04 = classes.box04LOST
box05 = classes.box05LOST
break;
case 3:
box = classes.boxALL;
box1 = classes.box1FUNNY;
box11 = classes.box11FUNNY;
box12 = classes.box12FUNNY;
box13 = classes.box13FUNNY;
box2 = classes.box2FUNNY;
box3 = classes.box3FUNNY;
qclass = classes.qclassFUNNY;
qclasstext = "FUNNY"
chip = classes.chipFUNNY
box03 = classes.box03WON
box01 = classes.box01LOST
box02 = classes.box02LOST
box04 = classes.box04LOST
box05 = classes.box05LOST




break;
case 4:
box = classes.boxSP;
box1 = classes.box1SP;
box11 = classes.box11SP;
box12 = classes.box12SP;
box13 = classes.box13SP;
box2 = classes.box2SP;
box3 = classes.box3SP;
qclass = classes.qclassSP;
qclasstext = "SHITPOST"
chip = classes.chipSP
box03 = classes.box03LOST
box01 = classes.box01LOST
box02 = classes.box02LOST
box04 = classes.box04WON
box05 = classes.box05LOST



break;
case 5:
superdiv = classes.superdiv
qclass = classes.qclassLOVELESS;
qclasstext = "LOVELESS"
box01 = classes.box01LOST
box02 = classes.box02LOST
box03 = classes.box03LOST
box04 = classes.box04LOST
box05 = classes.box05WON
break;
default:
//if none of these, loveless detected
}
}
return (
         <div style={style} className={superdiv}>

       {(isMod3 && qclass != undefined) && <div className={classes.tabdiv}><div className={qclass}>{qclasstext}</div></div>}

         <div key={row._id} className={box}  >
          {(isMod3) &&
            <div className={classes.box0}>

          <div className={box01 } onClick={()=>{classifyEntryQuality(1, row._id)} } >{row.u ? row.u : "0"}</div>
          <div className={box02} onClick={()=>{classifyEntryQuality(2, row._id)} } >{row.e ? row.e : "0"}</div>
          <div className={box03} onClick={()=>{classifyEntryQuality(3, row._id)} } >{row.f ? row.f : "0"}</div>
          <div className={box04} onClick={()=>{classifyEntryQuality(4, row._id)} }>{row.s ? row.s : "0"}</div>
          <div className={box05} onClick={()=>{classifyEntryQuality(5, row._id)} }>{row.l ? row.l : "0"}</div>
          </div>}
           <div className={box1}>
             <div className={box11}>{row.date && row.date.toString().substring(4, 15)}</div>
             <div className={box12}>
             <Link
  component="button"
  variant="body2"
  onClick={() => {
    viewUser(row)
  }}
  color='inherit'
  classes={{underlineHover: classes.hoverusername}}
>
  {row.username}
</Link>

             </div>
             <div className={box13}>      <Chip
   label={row.node}
   className={chip}
   color="#5a25de"
   onClick={(event) => {handleClickOnEntryNeurotag(event, row)}}
 /></div>
           </div>

           {row.dlink && row.dlink.includes("https://www.youtube.com/watch?v=") ? <iframe width="50%" height="100%" src={"https://www.youtube-nocookie.com/embed/" + row.dlink.replace("https://www.youtube.com/watch?v=", "")} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>: 
                      <div className={box2} onClick={()=>{if(row.dataentry !== undefined){wantToRead(row, index)}}}>{row.title}
                      {
                      (row.idea != undefined) && <div className={classes.box2summary} onClick={handleClickOnBox2Summary}>{
                     `${row.idea}
                     `
                    }             
                    <Link href={row.dlink} color="secondary" target="_blank" rel="noreferrer"> direct linky</Link>
             
                      </div>
                      }
           
           
           
           
                      {
                         <SuperfiltersEntry row={row}/>
                      }
           
                      </div> } 
           


           <div className={box3}  >

      {(mode === 1) ?
           <div className={classes.divaroundButtons}  >

           <div className={classes.divAroundACCOUNTTREE} onClick={(event)=> {handleClickOnEntryNeurotag(event, row)}}>
           {isNotDesktop ? <div id="isolateaccounttreecss"><i className="material-icons-round md-36" id="material-icons-round md-36mobile">account_tree_rounded</i></div> : <i className="material-icons-round md-36">account_tree_rounded</i>}

           </div>
           <div className={classes.divAroundPencil} onClick={(event)=> {handleEdit(event, row, index)}}>
           {isNotDesktop ? <i className="material-icons-round md-36" id="editsmall">editr</i> :  <i className="material-icons-round md-36" id="edit">editr</i>}

           </div>
           <div className={classes.divAroundTrashcan} onClick={(event)=> {openShouldDeleteDialog(row);}}>
           <i className="material-icons-round md-36" id="trashcan">delete_forever</i>
           </div>




           {row.private === false ? <Tooltip title="View As Normie" aria-label="Add">
                          <div className={classes.divAroundViewAsNormie} onClick={() => { window.open('https://brain.nearcher.com/'+ row.owner_id + "/" + strictUriEncode(row.title), '_blank'); }}>
                          <i className="material-icons-round md-36" id="seeasnormie">pageview</i>
                          </div>
                          </Tooltip> : false}


           </div> : false }

           {isMod3 && <Aux>
            <Tooltip title="Propagate To Followers" aria-label="Add">
           <div className={classes.divAroundSend} onClick={()=>{propagate(row._id)}}>
          <SendIcon className={classes.sendicon}/>
           </div></Tooltip>

           <Tooltip title="Activate Superpower Card" aria-label="Add">
                      <div className={classes.divAroundVote} onClick={()=> {calEntVotDial(row._id, index, row);}}>
                     <HowToVote className={classes.voteicon}/>
                      </div>
                      </Tooltip>

                      <Tooltip title="Hide" aria-label="hide">
                      <div className={classes.divAroundVote} onClick={()=> {
                        removeFromDataentriesAfterDelete(row._id);
                        
                        }}>
                     <VisibilityOff className={classes.voteicon}/>
                      </div>
                      </Tooltip>                      
                      
                      </Aux>}

      </div>

         </div></div>
       )

}

//const piesang = [1,2,3,4,5,6,7,8,9,10,11]
//const itemSizes = {1: 270, 2: 150, 3: 150, 4: 150, 5: 150, undefined: 150}
const Entries = (props) => {

  const { width, dataentries,listRef, itemCount, classes, isMod3, mode, isNotDesktop,classifyEntryQuality, viewUser, handleClickOnEntryNeurotag, wantToRead, handleClickOnBox2Summary, handleEdit, openShouldDeleteDialog, propagate, calEntVotDial, removeFromDataentriesAfterDelete } = props


  // const [itemCount, setitemCount] = useState(itemcount);
  //
  let rowHeights = dataentries.map((row) => {if(row.qclass === 1){return 270} return 150});
  //let getItemSize = index => rowHeights[index]

  // useEffect(() => {
  //   rowHeights = dataentries.map((row) => {if(row.qclass === 1){return 270} return 150});
  //   getItemSize = index => rowHeights[index]
  //   console.log("Entries Render")
  // });

  //if qclass is 1, 200 else 150
  const getItemSize = index => 270

  //const rowHeights = dataentries.map((row) => {if(row.qclass === 1){return 270} return 150});
  //const getItemSize = index => 250
  //const getItemSize = index => rowHeights[index]

  return (
    <List
      height={window.innerHeight * 0.9}
      itemCount={itemCount}
      ref={listRef}
      itemSize={getItemSize} //150 works very well for fixed lists except for USEFUL entries
      width={width}
      itemData={{data: dataentries, classes: classes, isMod3: isMod3, mode: mode, isNotDesktop: isNotDesktop, classifyEntryQuality: classifyEntryQuality, viewUser: viewUser, handleClickOnEntryNeurotag: handleClickOnEntryNeurotag, wantToRead: wantToRead, handleClickOnBox2Summary:handleClickOnBox2Summary, handleEdit:handleEdit, openShouldDeleteDialog:openShouldDeleteDialog, propagate:propagate, calEntVotDial:calEntVotDial, removeFromDataentriesAfterDelete:removeFromDataentriesAfterDelete}}
    >
      {Entry}
    </List>
  )
}


const styles = theme => ({
  adsdialog: {
    backgroundColor:'#000017',
    opacity: 0.9
  },
anomalydivAllEntries: {
"marginTop":"10px",
backgroundColor: '#000011',
},
  anomalydivOneEntry: {
    display: 'flex',
    width: '90%',
    margin: 'auto',
    marginTop: '5px',
  },
  anomalyEntryBox1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#adadad',
    flexGrow: '1',
    width: '25%',
    "borderStyle":"solid",
    "borderWidth":"1px",
    "borderColor":"cornflowerblue",



  },
  anomalyEntryBox2: {
    display: 'flex',
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'aqua',
    backgroundColor: '#000011',
    "borderStyle":"solid",
    "borderWidth":"1px",
    "borderColor":"cornflowerblue",




  },
  anomaliesEntriesNumber: {
"width":"90%",
"margin":"auto",
"display":"flex",
"marginTop":"5px",
color: '#adadad',
justifyContent: 'center',
textAlign: 'center',
fontSize: '2em',


  },
  backbuttonfromtreemode: {

    backgroundColor: '#4500ff!important',
    margin: '5px',
  },
  badge: {
   margin: theme.spacing.unit * 2,
   [theme.breakpoints.down('sm')]: {
 margin: theme.spacing.unit,
 marginTop: '5px'
 },

  },
  superdiv: {
    opacity: 0.2
  },
  box: {
    display: 'flex',
    color: 'grey',
    width: '90%',
    height: '200px',
    margin: 'auto',
    marginTop: '30px',
    borderColor: 'cornflowerblue',
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
  width: '99%',
  },

  },
  boxALL: {
    display: 'flex',
    color: '#a9a9a9',
    width: '90%',
    height: '200px',
    margin: 'auto',
    borderColor: 'cornflowerblue',
    marginBottom: '15px',
    backgroundColor: '#1F1F1F',
    borderRadius: '0px 25px 25px 0px',
    [theme.breakpoints.down('sm')]: {
  width: '100%',
  },

  },
  boxUSEFUL: {
    display: 'flex',
    color: '#a9a9a9',
    width: '90%',
    height: '200px',
    margin: 'auto',
    borderColor: 'cornflowerblue',
    marginBottom: '15px',
    backgroundColor: '#1F1F1F',
    borderRadius: '0px 25px 25px 0px',
    boxShadow: '0 0 10px #ffff66',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',

  width: '100%',
  },

  },
  boxSP: {
    display: 'flex',
    color: '#a9a9a9',
    width: '90%',
    height: '200px',
    margin: 'auto',
    borderColor: 'cornflowerblue',
    marginBottom: '15px',
    background: "linear-gradient(\n90deg, #100801, #381c00)",
    borderRadius: '0px 25px 25px 0px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',

  width: '100%',
  },

  },
  boxForFoundNodes: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'grey',
    width: '90%',
    height: 'auto',
    margin: 'auto',
    marginTop: '15px',
    marginBottom: '15px',
    borderColor: 'cornflowerblue',
    borderStyle: 'solid',
    borderRadius: '10px',
    borderWidth: '2px',
    flexWrap: 'wrap',
    overflow: 'hidden',


  },
  boxForFoundNodes2: {
    display: 'flex',
    justifyContent: 'center',
    color: 'grey',
    width: '90%',
    height: 'auto',
    margin: 'auto',
    marginTop: '15px',
    marginBottom: '15px',
    borderColor: 'cornflowerblue',
    borderStyle: 'solid',
    borderRadius: '10px',
    borderWidth: '2px',
    flexWrap: 'wrap',

  },
  box0: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '1%',
    textAlign: "center",
    color: 'black',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      opacity: 0.8,
  flexGrow: 0,
  width: '5%'
  },

  },
  box01: {flexGrow: 1,backgroundColor:"#fff700",display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: 0.8},
  box02: {flexGrow: 1,backgroundColor:"#9152FF",display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: 0.8},
  box03: {flexGrow: 1,backgroundColor:"#56ff42",display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: 0.8},
  box04: {flexGrow: 1,backgroundColor:"#ad6214",display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: 0.8},
  box05: {flexGrow: 1,backgroundColor:"#bc0000", display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: 0.8},

  box01WON: {flexGrow: 1,backgroundColor:"#ffff66", display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 20px #feff00"},
  box02WON: {flexGrow: 1,backgroundColor:"#BB87FF", display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 20px #a775ff"},
  box03WON: {flexGrow: 1,backgroundColor:"#4dff64", display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 20px #4dff64"},
  box04WON: {flexGrow: 1,backgroundColor:"#ad6214", display: 'flex', alignItems: 'center', justifyContent: 'center'},
  box05WON: {flexGrow: 1,backgroundColor:"#bc0000", display: 'flex', alignItems: 'center', justifyContent: 'center'},

  box01LOST: {flexGrow: 1,backgroundColor:"#fff700", display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: '0.2'},
  box02LOST: {flexGrow: 1,backgroundColor:"#c812c8", display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: '0.2'},
  box03LOST: {flexGrow: 1,backgroundColor:"#56ff42", display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: '0.2'},
  box04LOST: {flexGrow: 1,backgroundColor:"#ad6214", display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: '0.2'},
  box05LOST: {flexGrow: 1,backgroundColor:"#bc0000", display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: '0.2'},







  box1: {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '22%',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'cornflowerblue'
  },
  box1FUNNY: {
  display: 'flex',
  flexDirection: 'column',
  width: '23%',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#1e9a1e',
  [theme.breakpoints.down('sm')]: {
width: '25%'
},
  },
  box1USEFUL: {
  display: 'flex',
  flexDirection: 'column',
  width: '23%',
  borderStyle: 'solid',
  borderColor: '#FFFF66',
  borderWidth: '1px',
  [theme.breakpoints.down('sm')]: {
width: '25%'
},
  },
  box1EXC: {
  display: 'flex',
  flexDirection: 'column',
  width: '23%',
  borderStyle: 'solid',
  borderColor: '#4400FF',
  borderWidth: '1px',
  [theme.breakpoints.down('sm')]: {
width: '25%'
},
  },
  box1SP: {
  display: 'flex',
  flexDirection: 'column',
  width: '23%',
  [theme.breakpoints.down('sm')]: {
width: '25%'
},
  },


  box11: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  borderColor: 'cornflowerblue',
  textAlign: 'center',

  },
  box11FUNNY: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  borderColor: '#1e9a1e',
  textAlign: 'center',

  },
  box11USEFUL: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  borderColor: '#FFFF66',
  textAlign: 'center',
  },
  box11EXC: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  borderColor: '#F4400FF',
  textAlign: 'center',
  },

  box11SP: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  textAlign: 'center',
  },

  box12: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  borderColor: 'cornflowerblue',
  borderStyle: 'solid',
  borderLeft: '0px',
  borderRight: '0px',
  borderBottom: '0px',
  borderTop: '1px',
  [theme.breakpoints.down('sm')]: {
overflow: 'hidden'
},
  '&:hover': {
font:'silver',
}
  },
  box12FUNNY: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  borderColor: '#1e9a1e',
  borderStyle: 'solid',
  borderLeft: '0px',
  borderRight: '0px',
  borderBottom: 'solid 1px #1e9a1e',
  borderTop: '1px',
  [theme.breakpoints.down('sm')]: {
overflow: 'hidden'
},
  '&:hover': {
font:'silver',
}
  },

  box12USEFUL: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  borderColor: '#FFFF66',
  borderStyle: 'solid',
  borderLeft: '0px',
  borderRight: '0px',
  borderBottom: 'solid 1px #FFFF66',
  borderTop: '1px',
  [theme.breakpoints.down('sm')]: {
overflow: 'hidden'
},
  '&:hover': {
font:'silver',
}
  },
  box12EXC: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  borderColor: '#4400FF',
  borderStyle: 'solid',
  borderLeft: '0px',
  borderRight: '0px',
  borderBottom: 'solid 1px #4400FF',
  borderTop: '1px',
  [theme.breakpoints.down('sm')]: {
overflow: 'hidden'
},
  '&:hover': {
font:'silver',
}
  },

  box12SP: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  [theme.breakpoints.down('sm')]: {
overflow: 'hidden'
},
  '&:hover': {
font:'silver',
}
  },

  box13: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  backgroundColor: '000011',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRight: '0px',
  borderLeft: '0px',
  borderBottom: '0px',
  borderColor: 'cornflowerblue',
  overflow: 'hidden',
  },



  box13FUNNY: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  overflow: 'hidden',
  },
  box13SP: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  overflow: 'hidden',
  },

  box2: {
  width: '50%',
  fontSize: '1.4em',
  flexGrow: 0,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderLeft: '0px',
  borderRight: '0px',
  whiteSpace: 'initial',
  overflow: 'hidden',
  borderColor: 'cornflowerblue',
  paddingLeft: '5px',
  '&:hover': {
backgroundColor:'#510bff',
},
[theme.breakpoints.down('sm')]: {
  fontSize: '1em'
},

  },
  box2FUNNY: {
  width: '50%',
  fontSize: '1.4em',
  borderStyle: 'solid',
  borderColor: '#1e9a1e',
  borderTop: '1px',
  borderRight: '0px',
  borderBottom: 'solid 1px #1e9a1e',
  whiteSpace: 'initial',
  overflow: 'hidden',
  paddingLeft: '5px',
  borderLeft: '0px',
  '&:hover': {
backgroundColor:'#510bff',
},
[theme.breakpoints.down('sm')]: {
  fontSize: '1em',
  width: '52%'
},

  },
  box2USEFUL: {
  width: '50%',
  fontSize: '1.4em',
  borderStyle: 'solid',
  borderColor: '#FFFF66',
  borderTop: '1px',
  borderRight: '0px',
  borderBottom: 'solid 1px #FFFF66',
  whiteSpace: 'initial',
  overflow: 'hidden',
  paddingLeft: '5px',
  borderLeft: '0px',
  '&:hover': {
backgroundColor:'#510bff',
},
[theme.breakpoints.down('sm')]: {
  fontSize: '1em',
  width: '52%'
},

  },
  box2EXC: {
  width: '50%',
  fontSize: '1.4em',
  borderStyle: 'solid',
  borderColor: '#4400FF',
  borderTop: '1px',
  borderRight: '0px',
  borderBottom: 'solid 1px #4400FF',
  whiteSpace: 'initial',
  overflow: 'hidden',
  paddingLeft: '5px',
  borderLeft: '0px',
  '&:hover': {
backgroundColor:'#510bff',
},
[theme.breakpoints.down('sm')]: {
  fontSize: '1em',
  width: '52%'
},

  },

  box2SP: {
  width: '50%',
  fontSize: '1.4em',
  whiteSpace: 'initial',
  overflow: 'hidden',
  paddingLeft: '5px',
  '&:hover': {
backgroundColor:'#510bff',
},
[theme.breakpoints.down('sm')]: {
  fontSize: '1em',
  width: '52%'
},

  },

  box2summary: {
    backgroundColor: "#333333",
    borderColor: 'black',
    borderRadius: '10px',
    padding: '10px',
    whiteSpace: 'pre-line',
    fontSize: '0.7em',
    width: 'fit-content',
    margin: '10px 10px 10px 20px',
    color: '#cbcbcb'
  },

  box3: {
    display: 'flex',
    flexGrow: 1,
  width: '25%',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'cornflowerblue',
  borderRadius: '0px 25px 25px 0px',
  [theme.breakpoints.down('sm')]: {
display: 'flex',
flexDirection: 'column',
width: '20%'
},
  },
  box3FUNNY: {
    display: 'flex',
  width: '25%',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#1e9a1e',
  borderRadius: '0px 25px 25px 0px',
  [theme.breakpoints.down('sm')]: {
display: 'flex',
flexDirection: 'column',
width: '18%',
borderRadius: '0px',
},
  },
  box3USEFUL: {
    display: 'flex',
  width: '25%',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#FFFF66',
  borderRadius: '0px 25px 25px 0px',
  [theme.breakpoints.down('sm')]: {
display: 'flex',
flexDirection: 'column',
width: '18%',
borderRadius: '0px',
},
  },
  button: {
    margin: '5px',
  },

  box3EXC: {
    display: 'flex',
  width: '25%',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#4400FF',
  borderRadius: '0px 25px 25px 0px',
  [theme.breakpoints.down('sm')]: {
display: 'flex',
flexDirection: 'column',
width: '18%',
borderRadius: '0px',
},
  },

  box3SP: {
    display: 'flex',
  width: '25%',
  [theme.breakpoints.down('sm')]: {
display: 'flex',
flexDirection: 'column',
width: '18%',
},
  },
  button: {
    margin: '5px',
  },

  checkboxiconOK: {
      margin: theme.spacing.unit,
      fontSize: 32,
      color: '#03ff03'
      },
  chip: {
    margin: theme.spacing.unit,
    backgroundColor: '#5a25de',
    color: 'white',

    [theme.breakpoints.down('sm')]: {
    margin: '0px',
    fontSize: '8px'
    },


    '&:hover': {
  backgroundColor:'#510bff',
  },
  '&:focus': {
    backgroundColor: '#5a25de',
  },

  },
  chipFUNNY: {
    margin: theme.spacing.unit,
    backgroundColor: '#4dff64',
    color: 'black',

    [theme.breakpoints.down('sm')]: {
    margin: '0px',
    fontSize: '8px'
    },


    '&:hover': {
  backgroundColor:'#510bff',
  },
  '&:focus': {
    backgroundColor: '#5a25de',
  },

  },
  chipUSEFUL: {
    margin: theme.spacing.unit,
    backgroundColor: '#FFFF66',
    color: 'black',

    [theme.breakpoints.down('sm')]: {
    margin: '0px',
    fontSize: '8px'
    },


    '&:hover': {
  backgroundColor:'#510bff',
  },
  '&:focus': {
    backgroundColor: '#5a25de',
  },

  },

  chipSP: {
    margin: theme.spacing.unit,
    backgroundColor: '#CC6600',
    color: 'black',

    [theme.breakpoints.down('sm')]: {
    margin: '0px',
    fontSize: '8px'
    },


    '&:hover': {
  backgroundColor:'#510bff',
  },
  '&:focus': {
    backgroundColor: '#5a25de',
  },

  },
  controlheader: {
    width: "90%",
    margin: "auto",
    borderStyle: "solid",
    borderColor: "white",
    marginTop: "2em",
    marginBottom: '4rem',
    fontSize: "2rem",
    textAlign: "center",
    color: "white",
    borderLeft: "none!important",
    borderRight: "none",

  },
   // disappear:{display: 'none'}.
   divaroundButtons: {
      "width":"100%",
      "height":"100%",
      "display":"flex",
      "marginLeft":"25px",
      "marginRight":"25px",
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: '0px',
      },
   },
   divForTwoButtonsAtTree: {

     display: 'flex',
     width: '100%',
     justifyContent: 'space-between',
     [theme.breakpoints.down('sm')]: {
    "position":"fixed",
    },
   },
   divAfterRootdiv: {
     width: '100%',
     height: '100%',
     backgroundColor: '#000011'
   },
   divAfterRootdivDuringArticle: {
     width: '100%',
     height: '100%',
     backgroundColor: '#FFFFFF'
   },
   divAroundACCOUNTTREE: {
     display: 'flex',
     width: 'auto',
     height: '50%',
     justifyContent: 'center',
     alignItems: 'center',
     margin: 'auto',
     borderStyle: 'solid',
     borderColor: '#6495ed',
     borderRadius: '10px',
     borderWidth: '1px',
     backgroundColor: '#000011',
     paddingLeft: '8px',
     paddingRight: '8px',
     overflow: 'hidden',
     '&:hover': {
       backgroundColor: 'pink',
       cursor: 'pointer',

     },
     [theme.breakpoints.down('sm')]: {
    borderStyle: 'solid',
    borderColor: '#6495ed',
    borderWidth: '1px',
    backgroundColor: '#000011',
    borderRadius: '0px',
    margin: '0px',
    },

   },
   divAroundTrashcan: {
     display: 'flex',
     width: 'auto',
     height: '50%',
     justifyContent: 'center',
     alignItems: 'center',
     margin: 'auto',
     borderStyle: 'solid',
     borderColor: '#F44336',
     borderRadius: '10px',
     borderWidth: '1px',
     backgroundColor: '#000011',
     paddingLeft: '8px',
     paddingRight: '8px',
     '&:hover': {
       backgroundColor: 'pink',
       cursor: 'pointer'
     },
     [theme.breakpoints.down('sm')]: {
    borderStyle: 'solid',
    borderColor: '#F44336',
    borderWidth: '1px',
    backgroundColor: '#000011',
    margin: '0px',
    borderRadius: '0px',
    },

   },
   divAroundSend: {
     display: 'flex',
     width: 'auto',
     height: '50%',
     justifyContent: 'center',
     alignItems: 'center',
     margin: 'auto',
     borderStyle: 'solid',
     borderColor: '#56ff42',
     borderRadius: '10px',
     borderWidth: '1px',
     paddingLeft: '8px',
     paddingRight: '8px',
     '&:hover': {
       backgroundColor: '#132f0b',
       cursor: 'pointer'
     },
     [theme.breakpoints.down('sm')]: {
       width: '100%',
    margin: '0px',
    borderRadius: '0px',
    },

   },
   divAroundVote: {
     display: 'flex',
     width: 'auto',
     height: '50%',
     justifyContent: 'center',
     alignItems: 'center',
     margin: 'auto',
     borderStyle: 'solid',
     borderColor: 'white',
     borderRadius: '10px',
     borderWidth: '1px',
     paddingLeft: '8px',
     paddingRight: '8px',
     '&:hover': {
       backgroundColor: 'grey',
       cursor: 'pointer'
     },
     [theme.breakpoints.down('sm')]: {
       width: '100%',
    margin: '0px',
    borderRadius: '0px',
    },

   },
   divAroundPencil: {
     display: 'flex',
     width: 'auto',
     height: '50%',
     justifyContent: 'center',
     alignItems: 'center',
     margin: 'auto',
     borderStyle: 'solid',
     borderColor: '#58ff56',
     borderRadius: '10px',
     borderWidth: '1px',
     backgroundColor: '#000011',
     paddingLeft: '8px',
     paddingRight: '8px',
     '&:hover': {
       backgroundColor: '#069405',
       cursor: 'pointer'
     },
     [theme.breakpoints.down('sm')]: {
    borderStyle: 'solid',
    borderColor: '#58ff56',
    borderWidth: '1px',
    backgroundColor: '#000011',
    margin: '0px',
    borderRadius: '0px',
    },

   },
   divAroundViewAsNormie: {
     display: 'flex',
     width: 'auto',
     height: '50%',
     justifyContent: 'center',
     alignItems: 'center',
     margin: 'auto',
     borderStyle: 'solid',
     borderColor: '#4cfeff',
     borderRadius: '10px',
     borderWidth: '1px',
     backgroundColor: '#000011',
     paddingLeft: '8px',
     paddingRight: '8px',
     '&:hover': {
       backgroundColor: '#c2ffff',
       cursor: 'pointer'
     },
     [theme.breakpoints.down('sm')]: {
    borderStyle: 'solid',
    borderColor: '#4cfeff',
    borderWidth: '1px',
    backgroundColor: '#000011',
    margin: '0px',
    borderRadius: '0px',
    },
   },
   entryoptions:
     {display:"flex",
     justifyContent:"right",
     margin:"auto",
     width:"90%",
     padding:"10px",
     color:"darkgray",
     textDecoration:"underline",
     marginBottom: '-10px'
    },
   erroricon: {
     margin: theme.spacing.unit,
     fontSize: 32,
     color: 'red'
   },
   forceresync: {
     "width": "90%",
     "margin": "auto",
     "display": "flex",
     "padding": "5px",
     "marginTop": "2rem",
     "justifyContent": "center",
     "borderTopStyle": "solid",
     "borderTopWidth": "1px",
     "borderTopColor": "cornflowerblue",
     "borderBottomStyle": "solid",
     "borderBottomWidth": "1px",
     "borderBottomColor": "cornflowerblue",
     marginBottom: "2em"
   },
   grow: {
      flexGrow: 1,
    },
    hoverusername: {

      '&:hover': {
          color: '#afafaf',
        cursor: 'pointer'
      },
    },
    hs: {
      width: "90%",
      margin: "auto",
      borderStyle: "solid",
      borderColor: "red",
      marginTop: "1em",
      fontSize: "2rem",
      textAlign: "center",
      color: "red",
      borderLeft: "none!important",
      borderRight: "none",
      marginBottom: "1em",
    },
    header: {
      width: "100%",
      margin: "auto",
      marginLeft: '20px',
      marginRight: '20px',
      borderStyle: "solid",
      borderColor: "cornflowerblue",
      borderWidth: "1px",
      marginTop: "1em",
      fontSize: "2em",
      textAlign: "center",
      color: "cornflowerblue",
      borderLeft: "0px",
      borderRight: "0px",
      marginBottom: '2rem',
      fontFamily: "Times New Roman, Helvetica, Arial, sans-serif",
    },
    propa: {
      width: "90%",
      margin: "auto",
      borderStyle: "solid",
      borderColor: "#56ff42",
      marginTop: "1em",
      fontSize: "2rem",
      textAlign: "center",
      color: "#56ff42",
      borderLeft: "none!important",
      borderRight: "none",
      marginBottom: "1em",
    },

  input: {
      color: '#adadad',
      backgroundColor: 'black',
      height: '4em', //think 14px for 1em
      textAlign: 'center',
      width: '100%',
      backgroundColor: ''
  },
  // inputFixAnomaly: {
  //     color: '#adadad',
  //     backgroundColor: 'red',
  //     height: '4em', //think 14px for 1em
  //     textAlign: 'center',
  //     width: '100%',
  //     backgroundColor: ''
  // },


  loadingoverlay: {
    backgroundColor: '#000011'
  },

  nextpreviousbutndiv: {
    "width": "90%",
    "margin": "auto",
    "display": "flex",
    "padding": "5px",
    "marginTop": "20px",
    "justifyContent": "space-between",
    "borderTopStyle": "solid",
    "borderTopWidth": "1px",
    "borderTopColor": "cornflowerblue",
    "borderBottomStyle": "solid",
    "borderBottomWidth": "1px",
    "borderBottomColor": "cornflowerblue",
    marginBottom: "2em"
  },
  qclassFUNNY:
{display:"flex",justifyContent:"center",
width:"23%",
height:"100%",
backgroundColor:"#4DFF64",
alignItems:"center",
color:"black",
borderStyle:"solid",
borderColor:"#209917",
borderWidth:"1px",
fontStyle:"italic",
marginLeft:"2%",
borderRadius: '5px',

[theme.breakpoints.down('sm')]: {
width: '25%',
marginLeft: '5%'
}
},
qclassEXC:
{display:"flex",justifyContent:"center",
width:"23%",
height:"100%",
backgroundColor:"#9152FF",
alignItems:"center",
color:"white",
borderStyle:"solid",
borderColor:"#9152FF",
borderWidth:"1px",
fontStyle:"italic",
marginLeft:"2%",
borderRadius: '5px',
boxShadow: "0 0 10px #a775ff",
fontSize: '0.9em',

[theme.breakpoints.down('sm')]: {
width: '25%',
marginLeft: '5%'
}
},
qclassUSEFUL:
{display:"flex",justifyContent:"center",
width:"23%",
height:"100%",
backgroundColor:"#FFFF66",
alignItems:"center",
color:"black",
borderStyle:"solid",
borderColor:"#FFFF66",
borderWidth:"1px",
fontStyle:"italic",
marginLeft:"2%",
borderRadius: '5px',
boxShadow: '0 0 10px #ffff66',

[theme.breakpoints.down('sm')]: {
width: '25%',
marginLeft: '5%'
}
},
qclassSP:
{display:"flex",justifyContent:"center",
width:"23%",
height:"100%",
backgroundColor:"#0F0800",
alignItems:"center",
color:"#CC6600",
borderWidth:"1px",
fontStyle:"italic",
marginLeft:"2%",
borderRadius: '5px',
[theme.breakpoints.down('sm')]: {
width: '25%',
marginLeft: '5%'
}
},
qclassLOVELESS:
{display:"flex",justifyContent:"center",
width:"23%",
height:"100%",
alignItems:"center",
color:"white",
borderStyle:"solid",
borderColor:"cornflowerblue",
borderWidth:"1px",
fontStyle:"italic",
marginLeft:"2%",
borderRadius: '5px',

[theme.breakpoints.down('sm')]: {
width: '25%',
marginLeft: '5%'
}
},
radiolabel: {
  color: 'white!important'
},

root: {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
},


searchbar: {
  display: 'flex',
  justifyContent: 'flex-end',

},
search: {
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: fade(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: fade(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing.unit,
    width: 'auto',
  },
},
searchIcon: {
  width: theme.spacing.unit * 9,
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
sendicon: {
  margin: theme.spacing.unit,
  fontSize: 32,
  color: '#56ff42'
},
synergyctrls: {
  textAlign: "center",
paddingBottom: "2em"
},
tabdiv:
{display:"flex",width:"90%",height:"40px",margin:"auto", marginTop: '15px',
[theme.breakpoints.down('sm')]: {
width: '100%',
alignItems: 'end',
height: "30px"
}
},

textField: {
  marginLeft: theme.spacing.unit,
  marginRight: theme.spacing.unit,
},
topdiv: {
  color: 'red',
  backgroundColor: '#000011',
  height: '100%',
  overflow: 'hidden'
},
topDivDuringDisplayArticle:{
  color: 'red',
  backgroundColor: '#ffffff',
  height: '100%'
},
TreeBorder: {
  display: 'flex',
  justifyContent: 'center',
},
voteicon: {
  margin: theme.spacing.unit,
  fontSize: 32,
  color: 'white'
},

});





export class Alerts extends Component {


  state = {

   currentlyshownanomaly: 0,
   currentlyviewedlatestentries: 0,
   //localdataentries: [...this.props.dataentries],
  // typedin: '',
   wantToRead: '',
   wantToEdit: '',
   showSuccessMsg : false,
   showErrorMsg: false,
   showErrorMsgLogicalInconsistency: false,
   showSuccessfullyDeletedMsg: false,
   showSuccessfullyEditedEntryMessage: false,
   articleMode: false,
   entrySearchMode: false,
   newDataEntryMode: false,
   insertingNewEntry: false,
   validationsuccessful: false,
   validateNODE: false,
   validateTITLE: false,
   validateMISSINGPARENT: false,
   dataForTree: undefined,
   nodeThatWeWantToSeeTheFamilyOf: undefined, //for quick access to prevent use of fors or indexOf
   treemode: false,
   selectedNodeOnTree: "",
   labelWidth: 0,
   currentlyActiveID: '',
   editMode: false,
   //logouttriggered: false,
   lockInNodeAndParentAfterClickOnIndividualNeurotag: false,
   isOnline: false,
   reburnDB: false,
   editNode: false,
   displayGeneralMessage: true,
   generalmessage: "Database update complete.",
   generalerrormessage: "General Error Message",
   localLoadingOverlayControl: false,
   nodeClickedDuringEditNode: false,
   editorState: EditorState.createEmpty(),
   editor: 0, // 1, Making, 2, Reading, 3 Editing state.
   savedEditor: false,
   profileMode: false,
   currAppBarMode: "",
   aM: false,//about mode
   openYTKarmaDialog: false,
   openEQVoteDialog: false,
   openManageFeedDialog: false


   //clickedonindividualneurotagwheninsertingentry: {}, //saves the neurotag clicked on when inserting new entry to compare later to make sure user didn;t change it after pressing the button.

 }
 constructor(props) {
   super(props);
    //this.getCustomUserData = this.getCustomUserData.bind(this)
   this.nodeInput = React.createRef();
   this.titleInput = React.createRef();
   //this.timeInput = React.createRef();
   this.privateCheckbox = React.createRef();
   this.favCheckbox = React.createRef();
   this.top10Checkbox = React.createRef();
   this.dataentryInput = React.createRef();
   this.parentInput = React.createRef();
   this.missingparentInput = React.createRef();

   this.karmaYTInput = React.createRef();
   this.listRef = React.createRef();
   //this.forbidCommentsCheckbox = React.createRef();


 }




















// text1(e){
// let string = "I can't have a bees vir my seun.";
// let answ1 = "can't have";
// let answ2 = "my seun";
//
// if(e.target.value === "can't have"){
// this.setState({textbox1: true})
// console.log("can't have seikou")
// }
// else {
//   console.log(e.target.value)
// console.log("false");
//
// }
//
// console.log(this.state.textbox1)
// }

// compressedKeyObjectMaker = (rawwithouttitdate) =>{
//   let compressedObj = {
//     b: [],
//     e: rawwithouttitdate.entityMap
//   }
//
//   for(let x = 2; x < rawwithouttitdate.blocks.length; x++)
//   {
//     compressedObj.b.push({k: rawwithouttitdate.blocks[x].key, t: rawwithouttitdate.blocks[x].text, y: rawwithouttitdate.blocks[x].type, d: rawwithouttitdate.blocks[x].depth, i: rawwithouttitdate.blocks[x].inlineStyleRanges, n: rawwithouttitdate.blocks[x].entityRanges, a: rawwithouttitdate.blocks[x].data})
//
//   }
//   return compressedObj
// }

// patternizedCompressor = (rawwithouttitdate) => {
//
//   let compressedObj = {
//     b: [],
//     e: rawwithouttitdate.entityMap
//   }
//   let letfontlookuptable = {
//   }
//   let fontlookuptableindorder = [];
//   //console.log(letfontlookuptable["fontfamily-Helvetica Neue"]);
//   let lookuptableindex = 0;
//
//   for(let x = 2; x < rawwithouttitdate.blocks.length; x++) // run through all the elements in blocks except the first two that are kept open
//   {
//     for(let y = 0; y < this.props.patterns.length+1; y++) // try to match a pattern and break. Need to go to plus one, to know when we exhausted all the patterns
//     {
//
//
//       if(y < this.props.patterns.length) // pattern 1 deals with unstyled with no other characteristics or pattern 1 with no other characteristics except some offsets and only offsets
//       //if it contains depth or any other characteristic it will not apply.
//       {
//         //console.log(this.props.patterns[y].type, rawwithouttitdate.blocks[x].type)
//         if(this.props.patterns[y].type != rawwithouttitdate.blocks[x].type)
//         {
//           //console.log("1")
//           continue;
//         }
//         if(this.props.patterns[y].depth != rawwithouttitdate.blocks[x].depth)
//         {
//           //console.log("2")
//           continue;
//         }
//         if("{}" != JSON.stringify(rawwithouttitdate.blocks[x].data))
//         {
//           // Once the program flow reaches here, it will be pattern 1 but with an x amount of offsets, compensate here.
//           continue;
//         }
//         if(rawwithouttitdate.blocks[x].entityRanges.length != 0)
//         {
//           continue;
//         }
//         if(this.props.patterns[y].inlineStyleRanges.length != rawwithouttitdate.blocks[x].inlineStyleRanges.length)
//         {
//           // if it reaches this stage and contains inlineStyleRanges with length that is higher than one,
//           //
//           var arrayofallinlinestylechanges = [];
//           for(let z = 0; z < rawwithouttitdate.blocks[x].inlineStyleRanges.length; z++)
//           {
//
//             arrayofallinlinestylechanges.push( rawwithouttitdate.blocks[x].inlineStyleRanges[z].offset);
//             arrayofallinlinestylechanges.push(rawwithouttitdate.blocks[x].inlineStyleRanges[z].length);
//             if(letfontlookuptable[rawwithouttitdate.blocks[x].inlineStyleRanges[z].style] === undefined)//if the style does not exist yet use it
//             {
//               letfontlookuptable[rawwithouttitdate.blocks[x].inlineStyleRanges[z].style] = lookuptableindex
//               arrayofallinlinestylechanges.push(lookuptableindex)
//               fontlookuptableindorder.push(rawwithouttitdate.blocks[x].inlineStyleRanges[z].style);
//               lookuptableindex++; // these lookup table indexes will eventually be reused to get the keys back again.
//
//             }
//             else {
//               arrayofallinlinestylechanges.push(letfontlookuptable[rawwithouttitdate.blocks[x].inlineStyleRanges[z].style])
//             }
//
//           }
//           compressedObj.b.push({k:rawwithouttitdate.blocks[x].key, t: rawwithouttitdate.blocks[x].text, p: y+1, i: arrayofallinlinestylechanges})
//           break
//         }
//
//         // else pattern 1 found!
//         //console.log("pattern 1 recognized!")
//         compressedObj.b.push({k: rawwithouttitdate.blocks[x].key, t: rawwithouttitdate.blocks[x].text, p: y+1})
//         break; // once a pattern established you may break
//       }
//       if(y === this.props.patterns.length) // no pattern could be applied, use in bulk form because we don't know.
//       {
//
//         compressedObj.b.push({k: rawwithouttitdate.blocks[x].key, t: rawwithouttitdate.blocks[x].text, y: rawwithouttitdate.blocks[x].type, d: rawwithouttitdate.blocks[x].depth, i: rawwithouttitdate.blocks[x].inlineStyleRanges, n: [...rawwithouttitdate.blocks[x].entityRanges], a: {...rawwithouttitdate.blocks[x].data}, p: this.props.patterns.length+1});
//         break
//       }
//
//
//
//     }
//
//   }
//   if(fontlookuptableindorder.length > 0)
//   {
//     compressedObj.l = fontlookuptableindorder;
//   }
//   if("{}" != JSON.stringify(rawwithouttitdate.entityMap))
//   {
//     compressedObj.e = rawwithouttitdate.entityMap
//   }
//
//     return compressedObj
// }






  // patterns: [{
  //   1: {//completely unstyled pattern p1, no offsets , unstlyed with offsets will be separately handled
  //     key: "d812f",
  //     text: "",
  //     type: "unstyled",
  //     depth: 0,
  //     inlineStyleRanges: [],
  //     data: {},
  //   },
  //   2: { //completely unordered-list-item pattern p2, no offsets , unstlyed with offsets will be separately handled
  //     key: "d812f",
  //     text: "",
  //     type: "unordered-list-item",
  //     depth: 0,
  //     inlineStyleRanges: [],
  //     data: {},
  //   }
  // }]

editEntry = (rawwithouttitdate) =>
{

  //this.validateUNSAFEBASIC("bees");
//console.log(this.state.refStates);
  const client = Stitch.defaultAppClient;
  let buildJSObjectForSubmission = [//array with object inside as first element

    {
    node: this.state.refStates.nodeInput,
    //timespent: "1",
    title: this.state.refStates.titleInput,
    dataentry: "",
    private: this.state.refStates.privateCheckbox,
    favorite: this.state.refStates.favCheckbox,
    username: this.props.custom_data.username,
    top10: this.state.refStates.top10Checkbox,
    //forbidcomments: this.state.refStates.privateCheckbox ? true : this.state.refStates.forbidCommentsCheckbox,
    //containscode: false,
    parent: this.state.refStates.parentInput,
    owner_id: this.props.user.id,
    }
  ];


//console.log(JSON.stringify(rawwithouttitdate));

buildJSObjectForSubmission[0].dataentry = patternizedCompressor(rawwithouttitdate, this.props.patterns)//JSON.stringify(this.compressedKeyObjectMaker(rawwithouttitdate));


//console.log("jsonstringified:")
//console.log(JSON.stringify(buildJSObjectForSubmission[0].dataentry));

//console.log(JSON.stringify(this.compressedKeyObjectMaker(rawwithouttitdate)));


//console.log(JSON.stringify(rawwithouttitdate))

//console.log();

//PATTERNIZED COMPRESSOR AND DECOMPRESSOR TESTS
//console.log(JSON.stringify(this.patternizedCompressor(rawwithouttitdate)));
//console.log(JSON.stringify(this.patternizedDecompressor(JSON.stringify(this.patternizedCompressor(rawwithouttitdate)))))
//console.log(JSON.stringify(this.props.dataentries[0].lzw));


// for(let x = 0; x < this.props.dataentries[12].lzw.blocks.length; x++)
// {
//   this.props.dataentries[12].lzw.blocks[x]
// }
//
// console.log(buildJSObjectForSubmission[0].dataentry)
// return;
//console.log(rawwithouttitdate);



      {
        //console.log(this.state.currentlyActiveID)
        let myObjectId = new BSON.ObjectId(this.state.currentlyActiveID);


        //console.log("trying to update with", buildJSObjectForSubmission);
          client.callFunction("opdateerEntry", [myObjectId,buildJSObjectForSubmission]).then(echoedResult => {
            if(echoedResult === 0)
            {


              //console.log(buildJSObjectForSubmission)
            //console.log(`Echoed result: `, echoedResult);
            this.setState({showSuccessfullyEditedEntryMessage: true, articleMode: false, newDataEntryMode: false, editMode: false, insertingNewEntry: false, savedEditor: false})
            //this.props.setLoadingOverlayControllerToTrueRef();
            this.appbarFuncClickedOnFavorites();

            // where to insert into dataentries.
            //console.log(this.props.dataentries_symboltable[buildJSObjectForSubmission.node.charAt(0)])
            buildJSObjectForSubmission[0]._id = this.state.currentlyActiveID.toString();

            buildJSObjectForSubmission[0].date = this.state.rowToEdit.date;
            let wheretoinsertindataentry = binarySearchForDataEntryEditingAndDeleting(this.props.dataentries_symboltable[buildJSObjectForSubmission[0].node.charAt(0)], buildJSObjectForSubmission[0])


            //console.log('WHERETOINSERTINDATAENTRY', wheretoinsertindataentry, this.props.dataentries_symboltable[buildJSObjectForSubmission[0].node.charAt(0)], buildJSObjectForSubmission[0])
            //buildJSObjectForSubmission[0].date = this.props.dataentries_symboltable[buildJSObjectForSubmission[0].node.charAt(0)][wheretoinsertindataentry].date


            //use the index to also put the date back

            buildJSObjectForSubmission[0].dataentry = rawwithouttitdate
            this.props.dataentries_symboltable[buildJSObjectForSubmission[0].node.charAt(0)][wheretoinsertindataentry] = buildJSObjectForSubmission[0]
            //..console.log(this.props.dataentries_symboltable[buildJSObjectForSubmission[0].node.charAt(0)])
            // now let's burn to idb.
            const db = openDB('DataEntriesOrganizedPPP', 1, {
            upgrade(db) {
              // Create a store of objects
              const store = db.createObjectStore('deo', {
                keyPath: 'id',
              });
            },
            });

            db.then((db, res)=> {
              this.props.dataentries_symboltable.id = 1;
              db.put("deo", this.props.dataentries_symboltable ).then(res=>{

              }).catch(err=>{console.log(err)});

            }).catch(err=>{console.log(err)})


            //console.log('THIS.PROPS.DATAENTRIES_SYMBOLTABLE', this.props.dataentries_symboltable)

            if(this.state.rowToEdit.top10 === true && buildJSObjectForSubmission[0].top10 != true)
            {
              client.callFunction("removeFromTop10", [myObjectId]).then(r => {
                this.getCustomUserData();
              })

            }
            if(this.state.rowToEdit.top10 != true && buildJSObjectForSubmission[0].top10 === true)
            {
              client.callFunction("addToTop10", [myObjectId]).then(r => {
              this.getCustomUserData();
              })


            }
            if(this.state.rowToEdit.favorite === true && buildJSObjectForSubmission[0].favorite != true)
            {
              this.getCustomUserData();
            }
            if(this.state.rowToEdit.favorite != true && buildJSObjectForSubmission[0].favorite === true)
            {
              this.getCustomUserData();

            }
            if(this.state.rowToEdit.favorite === true && buildJSObjectForSubmission[0].favorite === true)
            {
              this.getCustomUserData();
            }






           }
            if(echoedResult === 1)
            {
              this.setState({displayGeneralErrorMessge: true, generalerrormessage: "Sorry, the data entry failed to edit correctly. Did you perhaps use any strange characters?"});
              //console.log("Result 1", 1)
              //this.setState({ data: this.props.submitN, openUpdateFailed: true }, () => resolve('resolve 2'));
            //  resolve()
            }
          }).catch(err => {console.log(err)})

      }




}

transferUpdatedMemoryToIDB = async (nodes_index2modify) => {


  const dbnodesorganized = await openDB('NodesOrganized', 1, {
      upgrade(dbnodesorganized) {
        // Create a store of objects
        const store = dbnodesorganized.createObjectStore('no', {
          // The 'id' property of the object will be the key.
          keyPath: 'id',
          // If it isn't explicitly set, create a value by auto incrementing.
        });
        // Create an index on the 'date' property of the objects.
      },
    });
  //now that all possible cases have been made to memory, let's burn that memory to idb.
  // what needs to change? nodes need to be updated, nodes_symboltable needs to be updated, both the parent and node
  // should have been added there.
  this.props.nodes_symboltable.id = 1;

await dbnodesorganized.put("no", this.props.nodes_symboltable );

// await deleteDB(name, {
//   blocked() {
//     // …
//   },
// });

const db = await openDB('Nodes', 1, {
  upgrade(dbnodesorganized) {
    // Create a store of objects
    const store = dbnodesorganized.createObjectStore('no', {
      // The 'id' property of the object will be the key.
      keyPath: 'id',
      // If it isn't explicitly set, create a value by auto incrementing.
    });
    // Create an index on the 'date' property of the objects.
  },
  });

  await db.put("nodes", {id: 1, nodes: this.props.nodes});



//overwrite nodes now.


}

shouldGetPropagations = () => {
  this.setState({shoGetPro: true})
}


krySaves = () => {
  const client = Stitch.defaultAppClient;
  client.callFunction("cache_krySaves").then(ds => {


            let arr = []
            let objects = {} 
            for(let x = 0; x < ds.length; x++)
            {
    
              if(objects[ds[x].result[0].datatype] === undefined)
              {
                objects[ds[x].result[0].datatype] = []
              }
              objects[ds[x].result[0].datatype].push(ds[x].result[0]) 
              arr.push(ds[x].result[0])
            }


    this.props.setEntriesForeignRef(arr,[])

    this.props.changeToSpecificModeRef(3)


  })
}

classifyEntryQuality = async (vote, rowid, dircall) => {

  //this.handleClose();

const client = Stitch.defaultAppClient;

let c = {1: "u", 2: 'e', 3: "f", 4: "s", 5: "l"}
let byebye = c[vote] === "s" || c[vote] === "l"
if(byebye)
{
  this.props.removeFromDataentriesAfterDeleteRef(rowid);
}

let s = await client.callFunction("classifyEntryQuality", [vote, new BSON.ObjectId(rowid)])




if(s > 0)
{
  if(this.state.BBMode)
  {
    //this.props.fetchLatestEntriesMode3Ref(2);
    //this.forceUpdate()
    if(dircall)
    {
      return true
    }
  //this.forceUpdate()
  this.shouldGetPropagations()

  }


  if(!byebye)
  {
  this.props.changeADataEntryVoteRef(rowid, c[vote], s, vote)
  }


}
  //blah blah blah
}


updateSymbolTrees = async (nodename, dataentry) => {


  if(this.props.dataentries_symboltable[nodename.charAt(0)] === undefined)
  {
  this.props.dataentries_symboltable[nodename.charAt(0)]  = []; // to make sure things like -DASH can be added if no array exists yet.
  }
  if(this.props.nodes_symboltable[nodename.charAt(0)] === undefined)
  {
  this.props.nodes_symboltable[nodename.charAt(0)]  = [[]]; // to make sure things like -DASH can be added if no array exists yet.
  }
  if(this.props.nodes_symboltable[dataentry[0].parent.charAt(0)] === undefined)
  {
  this.props.nodes_symboltable[dataentry[0].parent.charAt(0)]  = [[]]; // to make sure things like -DASH can be added if no array exists yet.
  }

//console.log(nodename, dataentry);
  const client = Stitch.defaultAppClient;
  let latestparent = undefined;
  let latestnode = undefined;
  let latestdataentry = dataentry;


  const db = await openDB('DataEntriesOrganizedPPP', 1, {
  upgrade(db) {
    // Create a store of objects
    const store = db.createObjectStore('deo', {
      keyPath: 'id',
    });
  },
  });

let indexOfNodeName = binarySearchFindNodeThatMatchesPefectly(this.props.nodes_symboltable[nodename.charAt(0)], nodename)



//This was ONLY for if it exists, this was NOT for any insert case, we need a generalized form for all cases,
//wheter a node exists or not,




let indexOfDataEntry = binarySearchFindWhereToInsertDataentryPerformant(this.props.dataentries_symboltable[nodename.charAt(0)], nodename)
//console.log('INDEXOFDATAENTRY', indexOfDataEntry)
//console.log("dataentry[0]", dataentry[0])
this.props.dataentries_symboltable[nodename.charAt(0)].splice(indexOfDataEntry, 0, dataentry[0]);//modify memory directly.

this.props.dataentries_symboltable.id = 1;
await db.put("deo", this.props.dataentries_symboltable );
//note that this could just be ONE of the entries and will not necessarily be the newest entry. need to go left to get the earliest one for sure.
// for(let x = indexOfDataEntry; x > 0; x--)
// {
// if(this.props.dataentries_symboltable[nodename.charAt(0)][x].node != nodename)
// {
//   indexOfDataEntry = x+1;//save
//   break;
// }
// }

//this.props.dataentries_symboltable[nodename.charAt(0)]= this.props.dataentries_symboltable[nodename.charAt(0)]






if(indexOfNodeName != undefined) //if found node , no need to add things, just return since parent and node already exist.
{

return // if the node already exists, just add the entry in memory and to idb and return, else if node is new, go through update node and parent process.
//this is done logically since it will not return here, the next code implies that a new node is made.
}

 // Case when node does not exist and therefore the idb nodes need to be updated.

    const fetch_retry = async (n) => {

      //console.log("Iteration number: ", n);
        try {
            let s = await client.callFunction("krySpesifiekeNodes", [[nodename, dataentry[0].parent]]);
            //console.log("response was ", s);
            if(s.length === 2)
            {
              let foundvalidchildren = undefined;
              //s.then(res=>{ //parent MUST include newly created dataentry as one of its children.
                //just check both since order can be strange due to indexes, also has to be the last entry into children.

                //determine which one of the two is the parent.
                let parent = (s[0].nodename === nodename) ? s[1] : s[0];
                let n = 0;
                for(let x = parent.children.length-1; x >= 0; x--)
                {
                  n++;
                  foundvalidchildren = parent.children[x] === nodename;

                  if(foundvalidchildren)
                  {
                  return s;
                  }

                }
                //console.log(s[0].children[s[0].children.length-1],s[1].children[s[1].children.length-1],nodename, foundvalidchildren, n)
                throw 1;

                //foundvalidchildren = (s[0].children[s[0].children.length-1] === nodename) || (s[1].children[s[1].children.length-1] === nodename)

            //  })


            }
            else {
              throw 1;
            }
            } catch(err) {
            if (n === 1) throw err;
            return await fetch_retry(n - 1);
        }
    };



  fetch_retry(30).then(res=>{
      //since order is scrambled up due to indexes, let's just get them.

      try { //if an error is detected during local insertion, resync with database
        console.log("At some point, we managed to enter fetch_retry(30) with new node and old parent")
        //console.log(res);

        latestnode = (res[0].nodename === nodename) ? res[0] : res[1];

        latestparent = (res[0].nodename === dataentry[0].parent) ? res[0] : res[1];

        //node.npdename should be in parents children.
        //node.parent ===parent.nodename

        latestnode._id = latestnode._id.toString();
        latestparent._id = latestparent._id.toString();


        //console.log(latestnode, latestparent, latestdataentry);


        //console.log("else: Case when node does not exist and therefore the idb nodes need to be updated.")




        //let currentindex = this.findIndexWhereToInsertInNodesOnlyIfSimilarNeighbourExists(x, nodename)



        let currentindex = this.props.nodes_symboltable[nodename.charAt(0)].length === 1 ? 1 : binarySearchFindWhereToInsertNode(this.props.nodes_symboltable[nodename.charAt(0)], nodename)


        console.log("currentIndex, latestnode", currentindex, latestnode, this.props.nodes_symboltable[nodename.charAt(0)])


        this.props.nodes_symboltable[nodename.charAt(0)].splice(currentindex, 0, latestnode);//modify memory directly. (nodes_symboltable)
        //(this.props.nodes_symboltable[nodename.charAt(0)][0].length === 1 ? currentindex )
        this.props.nodes_symboltable[nodename.charAt(0)][0].splice(currentindex-1, 0, latestnode.nodename);//modify special array (nodes_symboltable)
        //

        //let wheretoinsnode = this.props.nodes.length === 1 ? 0 : binarySearchFindWhereToInsertNode(this.props.nodes, nodename)

        //console.log("line 1225, wheretoinsertnode", wheretoinsnode)
        //only insert if the node actually does not exist yet.
        if(binarySearchFindNodeThatMatchesPefectly(this.props.nodes, nodename))
        {
          //if found do nothing
        }
        else {
          //insert node in nodes please
          //this.props.nodes.splice(currentindex-1, 0, latestnode)//modify nodes (nodes)

          //Find Where To Insert Normally passing this.props.nodes and -1
          let wheretoinsertInThisDotPropsDotNodes = binarySearchFindWhereToInsertNode(this.props.nodes, nodename)
          //will now yield 1 seeing as it needs to be inserted at one, still however need to manually check the first item of this.props.nodes

          wheretoinsertInThisDotPropsDotNodes = nodename < this.props.nodes[0].nodename ? 0 : wheretoinsertInThisDotPropsDotNodes
          this.props.nodes.splice(wheretoinsertInThisDotPropsDotNodes, 0, latestnode)


          // THE ABOVE LINE is nevessary because when we pass this.props.nodes, binarySearchFindWhereToInsertNode will only run from element 1 onwards, so need to make sure the first entry
          // is also compared, we will do that ourselves. For the case where in the beginning this.props.nodes ONLY CONTAINS ROOT,

        }

        console.log("currentIndex, latestnode", currentindex, latestnode, this.props.nodes_symboltable[nodename.charAt(0)], this.props.nodes)

        // let wheretoinsertinnodes = undefined;
        // try{ //Attempt to use the left node to find where to insert in the array
        //   let findnodenodenamebeforethisone = this.props.nodes_symboltable[nodename.charAt(0)][0][currentindex-2]; // -2 to account for the first special array and then one to the left (since that already exists)
        //   if(findnodenodenamebeforethisone === undefined)
        //   throw 1;
        //   console.log("findnodenodenamebeforethisone", findnodenodenamebeforethisone);
        //   wheretoinsertinnodes = binarySearchFindNodeThatMatchesPefectly(this.props.nodes, findnodenodenamebeforethisone)//find the node before the one we inserted in nodes
        //   console.log(wheretoinsertinnodes, "wheretoinsertinnodes");
        //   wheretoinsertinnodes = wheretoinsertinnodes + 1
        //   this.props.nodes.splice(wheretoinsertinnodes, 0, latestnode)//modify nodes (nodes)
        // }
        // catch(e)
        // {
        //   let findnodenodenamebeforethisone = this.props.nodes_symboltable[nodename.charAt(0)][0][currentindex]; // -2 to account for the first special array and then one to the left (since that already exists)
        //   console.log("findnodenodenamebeforethisone", findnodenodenamebeforethisone);
        //   wheretoinsertinnodes = binarySearchFindNodeThatMatchesPefectly(this.props.nodes, findnodenodenamebeforethisone)//find the node before the one we inserted in nodes
        //   console.log(wheretoinsertinnodes, "wheretoinsertinnodes");
        //   this.props.nodes.splice(wheretoinsertinnodes, 0, latestnode)//modify nodes (nodes)
        // }

        //console.log(this.props.nodes_symboltable)
        //console.log(this.props.nodes)

        //REMOVE AFTER TESTING
        if(this.props.nodes[currentindex-1].nodename === nodename)
        {
          console.log("inserted successfully", "inserted ", nodename, " at position ", currentindex-1, this.props.nodes[currentindex-1], this.props.nodes)
        }
        else {
          console.log("this.props.nodes[wheretoinsertinnodes].nodename", this.props.nodes[currentindex-1].nodename, nodename, this.props.nodes[currentindex-1])
          console.log("insert failed, now brute force inserting");
          //brute force insert
        }

        //handle the parent: Two cases, if the parent does exist already, update with latest parent, if does not exist, insert.
        //the update would just override, the insert would be more complex, let's do the update first.
        let parentexistsalready = binarySearchFindNodeThatMatchesPefectly(this.props.nodes, dataentry[0].parent);
        //binarySearchFindWhereToInsertNode can ONLY be used for determining where to insert left or right, NOT for
        //overwriting a node that exists already with new children

        console.log("1277 parentexistsalready", parentexistsalready, this.props.nodes, dataentry[0].parent);

        let wheretoinsertinnodes2 = undefined;

        //console.log(parentexistsalready, dataentry[0]);

        if(parentexistsalready >= 0) // found
        {

          console.log("entered if parentexistsalready >= 0 ")

          //update/overwrite nodes
          this.props.nodes[parentexistsalready] = latestparent
          let wherelocatedinsymboltable = binarySearchFindNodeThatMatchesPefectly(this.props.nodes_symboltable[dataentry[0].parent.charAt(0)], dataentry[0].parent)

          console.log("1285 wherelocatedinsymboltable", wherelocatedinsymboltable);

          this.props.nodes_symboltable[dataentry[0].parent.charAt(0)][wherelocatedinsymboltable] = latestparent//plus one ot compensate for special array.
          console.log('parent existed already: THIS.PROPS.NODES_SYMBOLTABLE', this.props.nodes_symboltable)
          //update symbol tree

        }
        else { //no such parent exists

          let currentindex = this.props.nodes_symboltable[dataentry[0].parent.charAt(0)].length === 1 ? 1 : binarySearchFindWhereToInsertNode(this.props.nodes_symboltable[dataentry[0].parent.charAt(0)], dataentry[0].parent)
          console.log("current index in parent doesn't exist", currentindex, this.props.nodes_symboltable[dataentry[0].parent.charAt(0)])
          this.props.nodes_symboltable[dataentry[0].parent.charAt(0)].splice(currentindex, 0, latestparent);//modify memory directly. (nodes_symboltable)
          this.props.nodes_symboltable[dataentry[0].parent.charAt(0)][0].splice(currentindex-1, 0, latestparent.nodename);


          //lets now insert into nodes as well.

          //binarySearchFindWhereToInsertNode(this.props.nodes, )
          let wheretoinsertinnodes2 = binarySearchFindWhereToInsertNode(this.props.nodes, dataentry[0].parent)
          console.log("1313 wheretoinsertinnodes2", wheretoinsertinnodes2);
          wheretoinsertinnodes2 = dataentry[0].parent < this.props.nodes[0].nodename ? 0 : wheretoinsertinnodes2
          console.log("1313 wheretoinsertinnodes2", wheretoinsertinnodes2);
          // THE ABOVE LINE is nevessary because when we pass this.props.nodes, binarySearchFindWhereToInsertNode will only run from element 1 onwards, so need to make sure the first entry
          // is also compared, we will do that ourselves.

          this.props.nodes.splice(wheretoinsertinnodes2,0, latestparent)

          //console.log('parent found to not exist: props.dataentries_symboltable', this.props.dataentries_symboltable)

        }
        //console.log(this.props.nodes_symboltable);
        this.transferUpdatedMemoryToIDB(wheretoinsertinnodes2);





        // let abouthalf = Math.floor(this.props.nodes_symboltable[nodename.charAt(0)].length/2)
        // let searchFirstHalfOrSecondHalf = nodename < this.props.nodes_symboltable[nodename.charAt(0)][abouthalf]; //must search first half if true
        //
        // let counter = searchFirstHalfOrSecondHalf ? abouthalf : this.props.nodes_symboltable[nodename.charAt(0)].length-1;
        //
        // console.log("counter before while", counter);
        //
        // while(counter >= 0)
        // {
        //
        //   if(nodename > this.props.nodes_symboltable[nodename.charAt(0)][counter].nodename)
        //   {
        //
        //     break;
        //   }
        //
        //   counter--;
        //
        //   //have to use the current index
        //
        //
        // }
        //
        // console.log("Should now display what counter is. ", counter)




    //console.log(indexOfNodeName);

     } catch (e) {
        //resync process
       this.setState({localLoadingOverlayControl: true})
       this.reburnDB();
       console.log(e)

     }





});

}
//new branch
moveToEditor = e =>{

  if(this.props.custom_data.top10.length >= 10 && this.top10Checkbox.current.checked)
  {
    this.setState({displayGeneralErrorMessge: true, generalerrormessage: "Sorry, you already have 10 entries in your top 10. Please remove another entry first before adding this one. Submit this entry first, then make the changes later."});
    return
  }

let ob = {newDataEntryMode: false, articleMode: true, editor: 1, refStates: {
  nodeInput: this.nodeInput.current.value.toUpperCase(),
  parentInput: this.parentInput.current.value.toUpperCase(),
  titleInput: this.titleInput.current.value,
  //timeInput: this.timeInput.current.value,
  privateCheckbox: this.privateCheckbox.current.checked,

  favCheckbox: this.favCheckbox.current.checked,
  top10Checkbox: this.top10Checkbox.current.checked}}

if(e === 1)
{
  this.setState(ob)

    //console.log(this.privateCheckbox);
}
if(e === 3)
{

  ob.editMode = false;
  ob.editor = 3;
  this.setState(ob)
}
if(e === 4) // For LISTS you also need to set refStates as well.
{
  ob.articleMode = false;
  this.setState(ob)
  console.log("HELLO", ob)
}

}

// const getCustomUserData = async () => {
//
//     const db = await openDB('CustomData', 1, {
//     upgrade(db) {
//       // Create a store of objects
//       const store = db.createObjectStore('custom', {
//         keyPath: 'id',
//         autoIncrement: true,
//       });
//     },
//     });
//
//
//
//     const app = Stitch.defaultAppClient
//     let result = await client.callFunction("getCustomUserData");
//
//     //console.log(result)
//   //  result.favorites =
//
//     await db.add('custom', result);
//     this.props.setCustomDataRef(result);
//
// }

getCustomUserData = async () => {
//async getCustomUserData(){


      const db = await openDB('CustomData', 1, {
      upgrade(db) {
        // Create a store of objects
        const store = db.createObjectStore('custom', {
          keyPath: 'id',
          autoIncrement: true
        });
      },
      });



      const app = Stitch.defaultAppClient
      let result = await app.callFunction("getCustomUserData");
      result.favorites.reverse();

      for(let x = 0; x < result.favorites.length; x++)
      {
        result.favorites[x]._id = result.favorites[x]._id.valueOf() + '';

      }

      //console.log(result)
    //  result.favorites =
      //console.log("store cleared")

      await db.clear('custom');
      result.id = 1;
      await db.put('custom', result);
      this.props.setCustomDataRef(result);


  //blah blah blah
}

async claimYTKarma(karmacode){

  const client = Stitch.defaultAppClient;

let s = await client.callFunction("claimYTKarma", [karmacode])

if(s === 0)
{
  this.handleClose();
  this.dispGenMes("You claimed +5 karma");
}


  //blah blah blah
}

dispGenMes = m => {
  this.setState({displayGeneralMessage: true, generalmessage: m})
}

viewUser = (row) => {
  this.props.changeToMode2Ref(row.owner_id)
  this.setState({profileMode: true})
}

getDataEntriesPinpointMode3 = async (clue) => {

  const client = Stitch.defaultAppClient;

let s = await client.callFunction("kryDataEntriesPinpointMode3", [clue])


this.props.setPinpointedNodesRef(s)
// s.then(res => {
//   console.log(res)
//   this.props.setPinpointedNodes(clue);
// })


  //blah blah blah
}

getDataEntriesForMeModeMode3 = async () => {

  const client = Stitch.defaultAppClient;

let s = await client.callFunction("kryForMe_ForMeMode", [])


this.props.setEntriesForeignRef(s)
// s.then(res => {
//   console.log(res)
//   this.props.setPinpointedNodes(clue);
// })


  //blah blah blah
}






fetchYTStreamInfo = async () => {
const client = Stitch.defaultAppClient;

let s = await client.callFunction("fetchYTStreamData")


  this.props.setEntriesForeignRef(s, [])


  //blah blah blah
}


// async function getCustomUserData() {
//
//
//   console.log("hello")
// }

submitNewEntry = (dataentryraw, advancedData) =>{

//this.validateUNSAFEBASIC('some value');
//console.log('THIS.STATE.VALIDATIONSUCCESSFUL ', this.state.validationsuccessful );



//let indexOfDataEntry = binarySearchFindWhereToInsertDataentryPerformant(this.props.dataentries_symboltable["T"], "THE 22 IMMUTABLE LAWS OF MARKETING")
// let whichDEtodelete = binarySearchForDataEntryEditingAndDeleting(this.props.dataentries_symboltable["T"], this.props.dataentries_symboltable["T"][34])
//
//
// this.props.dataentries_symboltable["T"].splice(whichDEtodelete, 1);
//
// console.log(whichDEtodelete);
//
// return
// console.log('INDEXOFDATAENTRY', indexOfDataEntry)
//
// return
console.log(this.state)

let objForUpdatingState = {}
let fullvalidationcheckOK = true;



// console.log(dataentryraw, dataentryraw.length)
// return;
// if(!(Object.keys(this.state.clickedonindividualneurotagwheninsertingentry).length === 0) && (this.state.clickedonindividualneurotagwheninsertingentry.constructor === Object) )
// {
// // if clicked on neurotag we need to check that they didn;t change it after clicking on the tag. not empty
// console.log("not empty");
//
// if(this.state.clickedonindividualneurotagwheninsertingentry.nodename === this.nodeInput.current.value)//current node value is same as one that was clicked on, in other words the node already exists and the user at least kept the node the same
// {
//   //clicked and did not change, may pass validation
//
//
// }
// else {
// //user clicked on the node but then changed the node name to something else.
// }
// //Object.keys(empty).length === 0 && empty.constructor === Object
//
//
// }
// else { //neurotag not clicked on
//   console.log("empty", this.state);
//
//
// this.nodeInput.current = this.state.refStates[0];
// this.parentInput.current = this.state.refStates[1];
// this.titleInput.current = this.state.refStates[2];
// this.timeInput.current = this.state.refStates[3];






// let x = this.props.nodes.find(element => element.nodename === this.nodeInput.current.value);
//
// console.log("Found,", x);
const result = this.props.filtered_nodesFULLBODY.filter(elem => elem.nodename === this.state.refStates.nodeInput); //now we have the full id of what we want.
//console.log('RESULT', result)

let modifyablecomparison = false;
if(result.length !== 0) // user is using an already devlared node
{
  modifyablecomparison = (this.state.refStates.parentInput !== result[0].parent);
  console.log('MODIFYABLECOMPARISON', modifyablecomparison)

}

if(modifyablecomparison)
{
  console.log("failed at the third if, validationsuccessful should still be false? ", this.state.validationsuccessful);
  objForUpdatingState.validationsuccessful = false;// but it is false by default anyway.
  objForUpdatingState.validatePARENT = false;
  objForUpdatingState.showErrorMsgLogicalInconsistency = true;

  if(this.state.validatePARENT != objForUpdatingState.validatePARENT) //Previous state passed validation but no longer does.
  {
    this.setState(objForUpdatingState);
  }
}
else {

  //check for use of old node with ANOTHER or NeW parent. NOT ALLOWED.

  let typednode = this.state.refStates.nodeInput;
  let typedparent = this.state.refStates.parentInput;

  if(typednode.toUpperCase() === "ROOT")
  {
    this.setState({displayGeneralErrorMessge: true, generalerrormessage: "ROOT is the topmost node and is special. We cannot make entries on ROOT since it only represents the root access to all your brain's content. All special messages from Nearcher will be left for you here, so ROOT is the only one node that is reserved."});
    return
  }

  let index = binarySearchFindNodeThatMatchesPefectly(this.props.nodes, typedparent)


  //console.log(bees);
  // if(this.recursiveFindChild(this.props.nodes[index], typednode) === 1) // if found it means there is a circular logic attempt being made
  // {
  //   this.setState({displayGeneralErrorMessge: true, generalerrormessage: "Sorry, you may not set the parent of this node to one of its children or its children's descendents."});
  //   return
  // }


  //if(binarySearchFindNodeThatMatchesPefectly(this.props.nodes_symboltable[typedparent.charAt(0)], typedparent) === undefined)

  const client = Stitch.defaultAppClient;
  var datum = new Date()
  //datum = datum.toISOString();

  //let lzw = lzwCompress.pack(dataentryraw);//dataentryraw is an escaped string

  //console.log("dataentry raw after applying compression", dataentryraw, lzw)

if(typeof dataentryraw === 'string')
{
  console.log("dataentryraw is a string");
}



let buildJSObjectForSubmission = [//array with object inside as first element

  {
  node: typednode,
  date: datum,
  title: this.state.refStates.titleInput,
  parent: typedparent,
  owner_id: this.props.user.id,
  private: this.state.refStates.privateCheckbox,
  favorite: this.state.refStates.favCheckbox,
  top10: this.state.refStates.top10Checkbox,
  //forbidcomments: this.state.refStates.privateCheckbox ? true : this.state.refStates.forbidCommentsCheckbox,
  username: this.props.custom_data.username,

  }

];


if(advancedData !== undefined)//
{

  buildJSObjectForSubmission[0] = {...buildJSObjectForSubmission[0], ...advancedData}
  buildJSObjectForSubmission[0].dataentry = {"b":[{"k":"d812f","t":"HERE IS THE LINK TO THE LIST","y":"unstyled","d":0,"i":[],"n":[{"offset":0,"length":43,"key":0}],"a":{},"p":5}],"e":{"0":{"type":"LINK","mutability":"MUTABLE","data":{"url":"https://"+buildJSObjectForSubmission[0].node+".nearch.com/"+"brains/"+ this.props.user.id + "/lists/" + strictUriEncode(buildJSObjectForSubmission[0].title),"targetOption":"_blank"}}}}
  buildJSObjectForSubmission[0].dlink = "https://"+buildJSObjectForSubmission[0].node+".nearch.com/"+"brains/"+ this.props.user.id + "/lists/" + strictUriEncode(buildJSObjectForSubmission[0].title)

}
else { // if just a normal entry do the patternizedcompression else don't
buildJSObjectForSubmission[0].dataentry = patternizedCompressor(dataentryraw, this.props.patterns)
}









  // if(buildJSObjectForSubmission[0].top10)
  // {
  //   client.callFunction("addToTop10", [new BSON.ObjectId("600fe10f2d095b04b6f5e022")])
  // }
  // return
  //console.table(buildJSObjectForSubmission);
  //console.log(dataentryraw);
  // for (let y=2; y < dataentryraw.blocks.length; y++)
  // {
  //   buildJSObjectForSubmission[0].dataentry += dataentryraw.blocks[y].text + " "
  // }

  //buildJSObjectForSubmission[0].dataentry.replace(/&/g, "&").replace(/</g, "<").replace(/>/g, ">").replace(/"/g, """).replace(/'/g, "'");
//console.table(buildJSObjectForSubmission);
// client.callFunction("validateSomeSchema", buildJSObjectForSubmission).then(echoedResult => {
//   console.log(echoedResult)
// })
// return

//console.log("came up to here");

// buildJSObjectForSubmission[0].dataentry = "唐揚げ";

console.log(buildJSObjectForSubmission)
  //console.table(client.userId);

  client.callFunction("maakNuweNeurotag", buildJSObjectForSubmission).then(echoedResult => {
    //console.log(`Echoed result: `, echoedResult);
    //buildJSObjectForSubmission[0].lzw = dataentryraw;
    //console.log(buildJSObjectForSubmission);
    //console.log(echoedResult)

    if(echoedResult.successful === 0)
    {
      buildJSObjectForSubmission[0].dataentry = dataentryraw;
      this.props.setLoadingOverlayControllerToTrueRef();
      this.props.fetchlatestentriesRef();
      this.setState({showSuccessMsg: true,newDataEntryMode: false, articleMode: false, savedEditor: false}, this.props.fetch);
      //this.props.fetchNodesRef();
      this.props.fetchAnomaliesRef();
      //this.props.updateLocalNodesAfterAddingANewOneRef({nodename: buildJSObjectForSubmission[0].node, parent: buildJSObjectForSubmission[0].parent, children: [], owner_id: buildJSObjectForSubmission[0].owner_id});

      buildJSObjectForSubmission[0]._id = echoedResult.insertedID.toString();
      this.updateSymbolTrees(buildJSObjectForSubmission[0].node, buildJSObjectForSubmission);


      if(buildJSObjectForSubmission[0].favorite)
      {

        this.getCustomUserData();
      }

      return

    }
    if(echoedResult.successful === 1 && echoedResult.message)
    {
      this.setState({displayGeneralErrorMessge: true, generalerrormessage: echoedResult.message});
    }
    else {
      throw 'p';
    }
  }).catch(err => {console.log(err); this.setState({displayGeneralErrorMessge: true, generalerrormessage: "Sorry, the data entry failed to submit correctly. Did you perhaps use any strange characters in the node, title or parent?"})})

}


}


//ARROW function examples:
// onClick with localState update and after that callback function with redux state update
//onChange={(event) => this.setState({grade: event.target.value}, () => this.props.selectGradeRef(event))}

// setState + console.log callback
// this.setState({numberOfQuestions: event.target.value}, () => {
// console.log(this.state.numberOfQuestions, 'numberOfQuestions after calling callback func')});


// <div className={classes.testwrapper}>
// <strong className={classes.test}> I <TextField onChange={(e) => {this.text1(e)} } inputProps={{className: classes.textField}} className={classes.textField}>dasdas</TextField> a bees vir <TextField autoFocus={this.textbox1} inputProps={{className: classes.textField}} className={classes.textField}>dasdas</TextField> </strong>
//
// </div>
componentDidMount()
{
  //console.log(this.props.alerts)
  //this.setState({data: this.props.alerts_transformed})
//  setTimeout(() => Prism.highlightAll(), 0)

  // this.setState({
  //     //labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
  //   });

const client = Stitch.defaultAppClient;


this.appbarFuncClickedOnFavorites()

// try {
//   client.callFunction("getLatestInfo").then(echoedResult => {
//   this.setState({ytlivestreamimminent: echoedResult[0].YTStreamWithin1Hour})
//   });
// } catch (e) {
//   console.error(e)
//
// }

this.hackme()//vir na kope etc.







}

hackme = async () => {

  //1. kyk of custom_data dieselfde is as aanlyn.

//2. indien nie, herbrand db OF herbrand custom_data. herbrand db is nuttig as die entries verskil van die lokale weergawe
// agv nearch goldmine submissions etc.




const client = Stitch.defaultAppClient;
//console.log(typednodename, typedparent);
client.callFunction("getCustomDataOnly").then(async (r) => {
let localCD = this.props.custom_data
console.log('comparing ', localCD.power, ' to ', r[0].power)

if(localCD.power != r[0].power || localCD.class != r[0].class)
{
this.getCustomUserData()
}
else {
  console.log(localCD.power+"was the same as ", r.power)
}

})




}








findChildrenOfParent = (event, parent, clickedNode) =>
{
  let locparent = parent;
  let locclickedNode = clickedNode;

  // console.log(this.props.nodes);
  //
  //   for (let y in this.props.nodes) {
  //     if(this.props.nodes[y].nodename === "REACT")
  //     { console.log("found react")
  //   }
  //   }
//Gets all the children of the node you clicked on
    let alltehchildren = this.props.nodes.filter(function(element) {
          return element.parent === locparent;
        });

  let finalchildren = []
  let grandchildren = []

  //console.log("What is allthechildren", alltehchildren);
alltehchildren.forEach(buildTree);

function buildTree(item, index) {

//first let's go get the parent and its list of children shall we.


  if(item.children.length < 1 || item.children == undefined){//the child does not have children
      //empty

      if(item.nodename === locclickedNode)
      {
        //console.log("SUCCESSFULLY PUSHED RED NODE CSS")
        finalchildren.push({name: item.nodename,
    gProps: {
			className: 'red-node',
		}, pathProps: {className: 'selectedpath'},});
      }
      // else {
      //   finalchildren.push({name: item.nodename});
      // }
  }
  else { // if the node does seem to have children of some sort.


    for(let x in item.children)//run through the entire children section
    {
      grandchildren.push({name: item.children[x]})

    }
    //console.log(grandchildren);

    if(item.nodename === locclickedNode)
    {
      finalchildren.push({name: item.nodename, gProps: {
  			className: 'red-node',
  		}, pathProps: {className: 'selectedpath'}, children: grandchildren});
    }
    // else {
    //   finalchildren.push({name: item.nodename, children: grandchildren});
    // }

  }

  grandchildren = [];


}

//console.log(finalchildren);

this.setState({dataForTree: {name: locparent, children: finalchildren}, treemode: true})

finalchildren = [];

//   let data = {
// name: 'REACT'
// children: [{
// name: 'Child One'
// }, {
// name: 'Child Two'
// },
// {
// name: 'Child Three',
// gProps: {
//   className: 'red-node',
//   onClick: (event, node) =>
//     alert(`Clicked ${node}!`)
// }
// },
// ]
// };
}


// if(this.props.filterfinish === true)
// {
//   //go and find the relevant thing
//   this.props.fetchentriesRef();
//
// }

newEntryClicked = (event, callback) =>
{



if(this.state.newDataEntryMode)
{
  this.props.resetFilteredNodesForNewEntryRef();//Reset the filteredNodesFirst
  this.nodeInput.current.value = "Please type in node name here.";
  this.parentInput.current.value = "Please type in the parent node here.";

  //DEBUGGING PURPOSES




}


if(this.state.editMode) // exclusively for solving the edit to new entry confliction bug
{
this.nodeInput.current.value = "Please type in node name here.";
this.titleInput.current.value = "Please type in the title here.";
//this.timeInput.current.value = "Please type in minutes spent here.";
this.parentInput.current.value = "Please type in the parent node here.";
//this.dataentryInput.current.value = "Please type in your data entry here.";
}

if(this.state.lockInNodeAndParentAfterClickOnIndividualNeurotag)
{
  this.setState({lockInNodeAndParentAfterClickOnIndividualNeurotag: false});
}


  if(typeof callback == "function")//Then run the callback
  callback();
}


clickedToDeepChangeNodeOrParent = () =>
{
  //console.log("Clicked to deep change the node or parent", this.nodeInput.current.value, this.parentInput.current.value);
console.log()

  let typedparent = this.parentInput.current.value.toUpperCase();
  if(binarySearchFindNodeThatMatchesPefectly(this.props.nodes_symboltable[typedparent.charAt(0)], typedparent) === undefined)
  {
    console.log("Sorry, you are only allowed to select parents that already exist."); // replace with some error message.
    this.setState({displayGeneralErrorMessge: true, generalerrormessage: "Sorry, you are only allowed to select parents that already exist."});
    return;
  }

  let oldNodeAndParent = this.state.nodeClickedDuringEditNode;
  if(oldNodeAndParent === undefined)
  {
    alert("Please use the filter to click on the node you want to change!");
    return;
  }

  let typednodename = this.nodeInput.current.value.toUpperCase();

  if(typednodename === typedparent)
  {
  this.setState({displayGeneralErrorMessge: true, generalerrormessage: "A node cannot be its own parent!! Daz some alabama shit right deh."});
  return
  }
  if(typednodename === "ROOT")
  {
  this.setState({displayGeneralErrorMessge: true, generalerrormessage: "ROOT is special and cannot be edited. This is your top level node, the entrance to your brain. All other nodes fall under root in some fashion."});
  return
  }
  const client = Stitch.defaultAppClient;
  //console.log(typednodename, typedparent);
client.callFunction("RLTP", [typedparent]).then(echoedResult => {

  //console.log(echoedResult[0]["ANCESTORS"])

if(echoedResult[0]["ANCESTORS"].includes(typednodename) === true)
{
this.setState({displayGeneralErrorMessge: true, generalerrormessage: "Sorry, a database insertion error occured."});
return

}
else {
  let nodenamechanged = oldNodeAndParent.nodename != typednodename ? true: false;
  let parentchanged = oldNodeAndParent.parent != typedparent ? true: false;
  if(parentchanged === true && nodenamechanged === false) // only the parent changed, perform deepChange(2)
  {
    client.callFunction("deepChange", [typednodename, typedparent, 2, oldNodeAndParent.parent, oldNodeAndParent.nodename]).then(echoedResult => {

    if(echoedResult === 0){
      //display success message.

      this.setState({localLoadingOverlayControl: true})

      this.reburnDB();
      //reburn phase
      //this.reburnDB();
    }
    else if(echoedResult.successful === 1) {
    this.setState({displayGeneralErrorMessge: true, generalerrormessage: echoedResult.message});
    }
    })
  }
  if(parentchanged === false && nodenamechanged === true) // user gave a new nodename only, wanted to fix a spelling mistake in nodename for example.
  {
    client.callFunction("deepChange", [typednodename, typedparent, 3, oldNodeAndParent.parent, oldNodeAndParent.nodename]).then(echoedResult => {

    if(echoedResult === 0){
      //display success message.
      this.setState({localLoadingOverlayControl: true})
      this.reburnDB();
      //reburn phase
      //this.reburnDB();
    }
    else if(echoedResult.successful === 1)
    {
      this.setState({displayGeneralErrorMessge: true, generalerrormessage: echoedResult.message});
    }
    })

  }
  if(parentchanged === true && nodenamechanged === true)
  {

    client.callFunction("deepChange", [typednodename, typedparent, 4, oldNodeAndParent.parent, oldNodeAndParent.nodename]).then(echoedResult => {

    if(echoedResult === 0){
      //display success message.
      this.setState({localLoadingOverlayControl: true})
      this.reburnDB();
      //reburn phase
      //this.reburnDB();
    }
    })

  }
  if(parentchanged === false && nodenamechanged === false)
  {
  this.setState({displayGeneralErrorMessge: true, generalerrormessage: "You haven't edited anything yet. :)"});
  }
}



  })
  // let index = binarySearchFindNodeThatMatchesPefectly(this.props.nodes, typedparent)
  // //console.log(bees);
  // if(this.recursiveFindChild(this.props.nodes[index], typednodename) === 1) // if found it means there is a circular logic attempt being made
  // {
  //   this.setState({displayGeneralErrorMessge: true, generalerrormessage: "Sorry, you may not set the parent of this node to one of its children or its children's descendents."});
  //   return
  // }


  //needs to update all nodes with new nodename and the parent
  //needs to change the node to the new name, and change its parent.
  //changed to another parent so the old parent needs to have the reference to the old node removed
  //new parent needs the new reference added to its children.



}
  // this.nodeInput.current.value
  // this.parentInput.current.value
setLocalStatesToEntryMode = () =>
{
  //console.log(event.target.value);
  //console.log("callback worked like a fucking bomb")
  this.setState({aM: false, profileMode: false, newDataEntryMode: true,viewAnomaliesMode: false, insertingNewEntry: true, savedEditor: false, articleMode: false, editMode: false, editNode:false, validateNODE: false, validateTITLE: false, validatePARENT: false, validateDATAENTRY: false, favoriteMode:false, latestEntriesMode: false});


}

// setLocalStatesToEditMode = () =>
// {
//   this.setState({validateNODE: true, validateTITLE: true, validateTIMESPENT: true, validatePARENT: true, validateDATAENTRY: true});
//
// }

handleClickOnIndividualNeurotag = (whichone, index) =>
{
  this.props.filterEntriesOnChipClickRef(whichone);
}

handleClickOnBox2Summary = (event)=> {
  if (!event) var event = window.event;
event.cancelBubble = true;
if (event.stopPropagation) {event.stopPropagation(); console.log("stopped propagation")}else{
//will execute this if clicked on inner div
console.log("didn't stop propagation")
}

}

handleClickOnIndividualNeurotagWhenInsertingNewEntry = (whichone) =>
{
  //console.log( "whichone",whichone)
  //this.props.filterNodesRef(whichone, 0);
//console.log(whichone);
  //before we filter out the neurtag to make the things in the box disappear
  //this.props.filterNodesRef(whichone, 0);//wait we don't know when this will finish doe...

  //already filtered section must be further filtered to produce the parent of what we are looking for. (using whichone)
  // let thechosenone = this.props.filtered_nodesFULLBODY.filter(function(element) {//lucky contains the full node list and must also be saved in order to be accessible by the insert form
  //   return (element.nodename === whichone);
  // });
  //console.log("whichone", whichone);
  //console.log("this.props.filtered_nodesFULLBODY", this.props.filtered_nodesFULLBODY);
  //let fullnodeinfo = binarySearchFindNodeThatMatchesPefectly(this.props.filtered_nodesFULLBODY, whichone);


let nodetouse = undefined;

//console.log(whichone);

  for(let x = 0; x < this.props.filtered_nodesFULLBODY.length; x++)
  {
    if(this.props.filtered_nodesFULLBODY[x].nodename === whichone)
    {
      nodetouse = x;
      break;

    }
  }

  //console.log("fullnodeinfo", fullnodeinfo);
  //this.props.filterNodesRef("]", 3)
  this.nodeInput.current.value = this.props.filtered_nodesFULLBODY[nodetouse].nodename;
  this.parentInput.current.value = this.props.filtered_nodesFULLBODY[nodetouse].parent;
  this.validateUNSAFEBASICNode();
  this.validateUNSAFEBASICParent();
  //this.validateUNSAFEBASICParent();
  //this.handleDelete2(whichone)

  this.props.filterNodesRef("moetalmalwegvatbehalwelaasteeen", 1);

  //disable parent and
  this.setState({lockInNodeAndParentAfterClickOnIndividualNeurotag: true, nodeClickedDuringEditNode: this.props.filtered_nodesFULLBODY[nodetouse]});
  //this.setState({clickedonindividualneurotagwheninsertingentry: thechosenone[0]});


}
handleClickOnEntryNeurotag = (event, whichone) =>
{
  // if(this.props.mode === 1)
  // {
  //
  // }
  if(this.props.mode === 3)
  {
  return
  }

  if(this.props.mode === 2)
  {
  this.props.fetchOneRef(whichone.node, this.props.another_owner_id, this.props.mode)
  }


  this.findChildrenOfParent(event, whichone.parent, whichone.node)
  this.setState({nodeThatWeWantToSeeTheFamilyOf: whichone, selectedNodeOnTree: whichone.node, profileMode: false, favoriteMode: false});






}
// handleClose = (event, reason) => {
//   console.log("handleClose() fired")
//    // if (reason === 'clickaway') {
//    //   return;
//    // }
// this.setState({ showSuccessfullyDeletedMsg: false });
// }
handleDeleteNeurotag = (event, whichone) =>
{
  //Node information screen:
  const client = Stitch.defaultAppClient;
  //console.log("delete", whichone)




  //this.findChildrenOfParent(event, whichone.parent, whichone.node)
  //this.setState({nodeThatWeWantToSeeTheFamilyOf: whichone, selectedNodeOnTree: whichone.node});

let myObjectId = new BSON.ObjectId(whichone._id);
// console.log("deletes entry and this is the row ", whichone)
// return;

//console.log(myObjectId, myObjectId.toString());
client.callFunction("deleteEntry", [myObjectId, whichone.node]).then(echoedResult => {

  //console.log(`Echoed result: `, echoedResult);
  this.handleCloseShouldDeleteDialog();
if(echoedResult === 1)
{
  //console.log(`Echoed result: `, echoedResult);


  this.setState({displayGeneralErrorMessge: true, generalerrormessage: "Sorry, we failed to delete this entry."});
}
if(echoedResult === 0)
{
//Successully deleted entrySearchMode
//this.props.fetchlatestentriesRef();
this.setState({showSuccessfullyDeletedMsg: true, openShouldDelete: false})

this.props.removeFromDataentriesAfterDeleteRef(whichone._id);



//let's find the place that needs to be edited. ai
// iImnsadnaoao iasdjassda uhaskdua ja j

whichone._id = whichone._id.toString()

let whichDEtodelete = binarySearchForDataEntryEditingAndDeleting(this.props.dataentries_symboltable[whichone.node.charAt(0)], whichone)

//if(this.props.dataentries_symboltable[whichone.node.charAt(0).length === whichDEtodelete)
  /*
  EXTRENELY IMPORTANT:
  Needed to add this compensation since we are using this insert search or reusing it for not its intended purpose.
  The edge case where it stumbles upon the final element must be compensated for, or it will try to delete something out of bounds.
  **/
//console.log(whichDEtodelete)
this.props.dataentries_symboltable[whichone.node.charAt(0)].splice(whichDEtodelete, 1);

this.props.deleteFromReduxTooRef()
this.props.removeFromDataentriesAfterDeleteRef(whichone._id);

const db = openDB('DataEntriesOrganizedPPP', 1, {
upgrade(db) {
  // Create a store of objects
  const store = db.createObjectStore('deo', {
    keyPath: 'id',
  });
},
});

db.then((db, res)=> {
  this.props.dataentries_symboltable.id = 1;
  //console.log(this.props.dataentries_symboltable)
  db.put("deo", this.props.dataentries_symboltable ).then(res=>{


  }).catch(err=>{console.log(err)});

}).catch(err=>{console.log(err)})

//You can use splice(x, y) where x is the starting element where you want to remove and y is the number of elements to remove from that index.

this.getCustomUserData();
}

}).catch(err => {console.log(err)})


//this.props.setLoadingOverlayControllerToTrueRef();
//this.props.fetchlatestentriesRef();
this.setState({articleMode: false, newDataEntryMode: false, insertingNewEntry: false});




}

handleEdit = (event, whichon, index) =>
{







//this.setState({editMode: true, currentlyActiveID: whichon._id, rowToEdit: whichon});
if(whichon.private === undefined)
{
  whichon.private = true
}
// if(whichon.favorite === undefined)
// {
//   whichon.favorite = true
// }
//console.log(JSON.parse(whichon.dataentry));
this.setState({editMode: true, savedEditor: false, currentlyActiveID: whichon._id, rowToEdit: whichon, wantToEdit: whichon.lzw ? whichon.lzw : whichon.dataentry ,rowIndex:index, editor: 3, latestEntriesMode: false, favoriteMode: false})

}
handleDelete = (whichone) =>

{

if(true) // if idb and all necessary dbs exists
{
  this.props.handleDeleteChipDuringSearchRef(whichone);
}
else {
  this.props.filterNodesRef(whichone, 2);
}

}

handleEditorChange = (state) => {

  //console.log("editorState", state);
  this.setState({editorState: state});

  this.convertContentToHTML();
}
convertContentToHTML = () => {
  let currentContentAsHTML = convertToHTML(this.state.editorState.getCurrentContent());
  //console.log("currentContentAsHTML", currentContentAsHTML);
  this.setState({ConvertedContent: currentContentAsHTML});
}

createMarkup = (html) => {
    return  {
      __html: DOMPurify.sanitize(html)
    }
  }


// minutesInputClicked = (event) =>
//
// {
//
// //this.setState({typedInMinutesFromInput: ''}, this.validateUNSAFEBASIC)
// this.timeInput.current.value = "";
// this.validateUNSAFEBASICTime();
// }

nodeInputClicked = (event) =>

{

//this.setState({typedInNodeFromInput: ''}, this.validateUNSAFEBASIC);
if(this.state.lockInNodeAndParentAfterClickOnIndividualNeurotag)
{
  // if a user clicked on a neurotag this will become activated and set to true, we need this if to stop it from reacting and resetting the input
  // even after it is locked in. It seems that the onClick still resets it.
  return;
}
this.nodeInput.current.value = "";

// console.log(binarySearchFindWhereToInsertNode(this.props.nodes, "BOX"))
//console.log(binarySearchFindWhereToInsertNode(this.props.nodes, "PUSSY"))
// this.props.nodes.splice(binarySearchFindWhereToInsertNode(this.props.nodes, "PUSSY"),0,"pussy")
// //console.log(binarySearchFindWhereToInsertNode(this.props.nodes, "PBB"))
// this.props.nodes.splice(binarySearchFindWhereToInsertNode(this.props.nodes, "PBB"),0,"pbb")
// //console.log(binarySearchFindWhereToInsertNode(this.props.nodes, "CRABS"))
// this.props.nodes.splice(binarySearchFindWhereToInsertNode(this.props.nodes, "CRABS"),0,"crabs")
// //console.log(binarySearchFindWhereToInsertNode(this.props.nodes, "AAAAAAA"))
// this.props.nodes.splice(binarySearchFindWhereToInsertNode(this.props.nodes, "AAAAAAA"),0,"aaaaa")
// //console.log(binarySearchFindWhereToInsertNode(this.props.nodes, "-DASH"))
// this.props.nodes.splice(binarySearchFindWhereToInsertNode(this.props.nodes, "-DASH"),0,"-dash")




// console.log(binarySearchFindWhereToInsertNode(this.props.nodes, "PBB"))
// this.props.nodes.splice(binarySearchFindWhereToInsertNode(this.props.nodes, "PBB"),0,"pbb")
// console.log(binarySearchFindWhereToInsertNode(this.props.nodes, "CRABS"))
// this.props.nodes.splice(binarySearchFindWhereToInsertNode(this.props.nodes, "CRABS"),0,"crabs")
// console.log(binarySearchFindWhereToInsertNode(this.props.nodes, "AAAAAAA"))
// this.props.nodes.splice(binarySearchFindWhereToInsertNode(this.props.nodes, "AAAAAAA"),0,"aaaaa")
// console.log(binarySearchFindWhereToInsertNode(this.props.nodes, "-DASH"))
// this.props.nodes.splice(binarySearchFindWhereToInsertNode(this.props.nodes, "-DASH"),0,"-dash")
// console.log(binarySearchFindWhereToInsertNode(this.props.nodes, "ZZZ"))
// this.props.nodes.splice(binarySearchFindWhereToInsertNode(this.props.nodes, "ZZZ"),0,"zzz")

//DEBUGGING PURPORSES START

//console.log(this.props.dataentries_symboltable["N"]);
//console.log(binarySearchFindWhereToInsertDataentryPerformant(this.props.dataentries_symboltable["N"], "NXXX"))

//console.log(this.props.nodes);



//this.checkIfOnline();
//this.reburnDB();
//let whichone = this.props.dataentries_symboltable["P"][33]
//console.log(binarySearchForDataEntryEditingAndDeleting(this.props.dataentries_symboltable["P"], whichone))
if(!this.state.editNode)// for testing
{
//   this.nodeInput.current.value = "BOX"
//   this.parentInput.current.value = "TESTING"
// this.titleInput.current.value = "Test old node new old parent"
//   this.timeInput.current.value = 1;


  this.nodeInput.current.value = ""
  this.parentInput.current.value = "ROOT"
  //this.timeInput.current.value = 1;

this.validateUNSAFEBASICNode();
this.validateUNSAFEBASICParent();
this.validateUNSAFEBASICTitle();
//this.validateUNSAFEBASICTime();
//DEBUGGING END


this.validateUNSAFEBASICNode();
}

}

parentInputClicked = (event) =>

{
  if(this.state.lockInNodeAndParentAfterClickOnIndividualNeurotag)
{
  return;
}
this.parentInput.current.value = "";
this.validateUNSAFEBASICParent(event);
}

titleInputClicked = (event) =>

{

// this.setState({typedInTitleFromInput: ''}, this.validateUNSAFEBASIC)
this.titleInput.current.value = "";
this.validateUNSAFEBASICTitle();
}
missingparentInputClicked = (event) =>

{

this.missingparentInput.current.value = "";
this.setState({validateMISSINGPARENT: false});
}


validateUNSAFEBASICMissingParent = (entrytocheck, c) => {

  if((this.missingparentInput.current.value.length === 0) || (this.missingparentInput.current.value.length < 30) || (this.missingparentInput.current.value === "Please type in a parent to solve this anomaly."))
  {
    this.setState({validateMISSINGPARENT: true});
    return;
  }
  this.setState({validateMISSINGPARENT: false});



}

validateUNSAFEBASICNode = () => {

if(this.nodeInput.current.value.length === 1)
{
  this.props.filterNodesRef(this.nodeInput.current.value, 1)
}
else {
  this.props.filterNodesRef(this.nodeInput.current.value, 0)
}



  let objForUpdatingState = {}
  let fullvalidationcheckOK = true;


//Check for logical inconsistency validation.


//Basic length, default value checks.
  if((this.nodeInput.current.value.length === 0) || (this.nodeInput.current.value.length > 66) || (this.nodeInput.current.value === "Please type in title here..."))
  {
    //console.log("failed at the second if, validationsuccessful should still be false? ", this.state.validationsuccessful);
    objForUpdatingState.validationsuccessful = false;// but it is false by default anyway.
    objForUpdatingState.validateNODE = false;



    //program context logical inconsistencies checks.

    //If there was a changed state setState + re-render, otherwise just keep it as is.
    if(this.state.validateNODE != objForUpdatingState.validateNODE) //Previous state passed validation but no longer does.
    {
      this.setState(objForUpdatingState);
    }

  }
  else {
    objForUpdatingState.validateNODE = true;

    if(this.state.validateNODE != objForUpdatingState.validateNODE) //Was previously false but now true
    {
      this.setState(objForUpdatingState);
    }

  }


}

validateUNSAFEBASICTitle = () => {

  let objForUpdatingState = {}
  let fullvalidationcheckOK = true;

  //console.log("ran, validateUNSAFEBASIC")

  if((this.titleInput.current.value.length === 0) || (this.titleInput.current.value.length > 90) || (this.titleInput.current.value === "Please type in title here..."))
  {
    //console.log("failed at the second if, validationsuccessful should still be false? ", this.state.validationsuccessful);
    objForUpdatingState.validationsuccessful = false;// but it is false by default anyway.
    objForUpdatingState.validateTITLE = false;

    //If there was a changed state setState + re-render, otherwise just keep it as is.
    if(this.state.validateTITLE != objForUpdatingState.validateTITLE) //Previous state passed validation but no longer does.
    {
      this.setState(objForUpdatingState);
    }

  }
  else {
    objForUpdatingState.validateTITLE = true;

    if(this.state.validateTITLE != objForUpdatingState.validateTITLE) //Was previously false but now true
    {
      this.setState(objForUpdatingState);
    }

  }


}

// validateUNSAFEBASICTime = () => {
//
//   let objForUpdatingState = {}
//   let fullvalidationcheckOK = true;
//
//   if( (isNaN(parseInt(this.timeInput.current.value))) || (this.timeInput.current.value.length === 0) || (this.timeInput.current.value.length > 4) || (this.timeInput.current.value === "Please type in minutes spent on this entry here..."))
//   {
//     //console.log("failed at the second if, validationsuccessful should still be false? ", this.state.validationsuccessful);
//     objForUpdatingState.validationsuccessful = false;// but it is false by default anyway.
//     objForUpdatingState.validateTIMESPENT = false;
//
//     if(this.state.validateTIMESPENT != objForUpdatingState.validateTIMESPENT) //Previous state passed validation but no longer does.
//     {
//       this.setState(objForUpdatingState);
//     }
//
//   } //
//   else {
//     objForUpdatingState.validateTIMESPENT = true;
//     if(this.state.validateTIMESPENT != objForUpdatingState.validateTIMESPENT) //Previous state passed validation but no longer does.
//     {
//       this.setState(objForUpdatingState);
//     }
//   }
//
// }

validateUNSAFEBASICParent = (event) => {

  //if (event.keyCode === 13 || event.which === 13) {

//}

  let objForUpdatingState = {}
  let fullvalidationcheckOK = true;


  if((this.parentInput.current.value.length === 0) || (this.parentInput.current.value.length > 30) || (this.parentInput.current.value === "Please type in the parent node here..."))
  {
    //console.log("failed at the third if, validationsuccessful should still be false? ", this.state.validationsuccessful);
    objForUpdatingState.validationsuccessful = false;// but it is false by default anyway.
    objForUpdatingState.validatePARENT = false;

    if(this.state.validatePARENT != objForUpdatingState.validatePARENT) //Previous state passed validation but no longer does.
    {
      this.setState(objForUpdatingState);
    }


  }
  else {
    objForUpdatingState.validatePARENT = true;

    if(this.state.validatePARENT != objForUpdatingState.validatePARENT) //Previous state passed validation but no longer does.
    {
      this.setState(objForUpdatingState);
    }

  }






}

validateUNSAFEBASICDataEntry = () => {

  let objForUpdatingState = {}
  let fullvalidationcheckOK = true;

  if((this.dataentryInput.current.value.length === 0) || (this.dataentryInput.current.value.length > 20000) || (this.dataentryInput.current.value === "Please type in your data entry here."))
  {
    //console.log("failed at the third if, validationsuccessful should still be false? ", this.state.validationsuccessful);
    objForUpdatingState.validationsuccessful = false;// but it is false by default anyway.
    objForUpdatingState.validateDATAENTRY = false;
    if(this.state.validateDATAENTRY != objForUpdatingState.validateDATAENTRY) //Previous state passed validation but no longer does.
    {
      this.setState(objForUpdatingState);
    }
  }
  else {
    objForUpdatingState.validateDATAENTRY = true;
    if(this.state.validateDATAENTRY != objForUpdatingState.validateDATAENTRY) //Previous state passed validation but no longer does.
    {
      this.setState(objForUpdatingState);
    }
  }

}

// checkIfOnline = () => {
// var condition = navigator.onLine ? 'online' : 'offline';
//     if (condition === 'online') {
//       console.log('ONLINE');
//         fetch('https://www.google.com/', { // Check for internet connectivity
//             mode: 'no-cors',
//             })
//         .then(() => {
//             console.log('CONNECTED TO INTERNET');
//             this.setState({isOnline: true})
//         }).catch(() => {
//            alert('Internet connectivity issue. You will not be able to edit or ')
//            this.setState({isOnline: false})
//         }  )
//
//     }else{
//        console.log('OFFLINE')
//     }
//   }

validateUNSAFEBASIC = (arg) => {



 let objForUpdatingState = {}
 let fullvalidationcheckOK = true;

//console.log("validateBASIC ran")
 //console.log('THIS.STATE.TYPEDINNODEFROMINPUT.LENGTH', this.state.typedInNodeFromInput.length);
  if((this.state.typedInNodeFromInput.length === 0) || (this.state.typedInNodeFromInput.length > 30) || (this.state.typedInNodeFromInput === "Please type in node name here..."))
  {
    //console.log("failed at the first if, validationsuccessful should still be false? ", this.state.validationsuccessful);
    objForUpdatingState.validationsuccessful = false;// but it is false by default anyway.
    objForUpdatingState.validateNODE = false;
  }
  else {
    objForUpdatingState.validateNODE = true;
  }

  if((this.state.typedInMissingParent.length === 0) || (this.state.typedInMissingParent.length > 30) || (this.state.typedInMissingParent === "PLease type in a parent to solve this anomaly."))
  {
    //console.log("failed at the first if, validationsuccessful should still be false? ", this.state.validationsuccessful);
    objForUpdatingState.validationsuccessful = false;// but it is false by default anyway.
    objForUpdatingState.validateMISSINGPARENT = false;
  }
  else {
    objForUpdatingState.validateMISSINGPARENT = true;
  }


  if((this.titleInput.current.value.length === 0) || (this.titleInput.current.value.length > 66) || (this.titleInput.current.value === "Please type in title here..."))
  {
    //console.log("failed at the second if, validationsuccessful should still be false? ", this.state.validationsuccessful);
    objForUpdatingState.validationsuccessful = false;// but it is false by default anyway.
    objForUpdatingState.validateTITLE = false;
  }
  else {
    objForUpdatingState.validateTITLE = true;
  }

  if((this.state.typedInParentFromInput.length === 0) || (this.state.typedInParentFromInput.length > 30) || (this.state.typedInParentFromInput === "Please type in the parent node here..."))
  {
    //console.log("failed at the third if, validationsuccessful should still be false? ", this.state.validationsuccessful);
    objForUpdatingState.validationsuccessful = false;// but it is false by default anyway.
    objForUpdatingState.validatePARENT = false;
  }
  else {
    objForUpdatingState.validatePARENT = true;
  }

  // if( (isNaN(parseInt(this.state.typedInMinutesFromInput))) || (this.state.typedInMinutesFromInput.length === 0) || (this.state.typedInMinutesFromInput.length > 4) || (this.state.typedInMinutesFromInput === "Please type in minutes spent on this entry here..."))
  // {
  //   //console.log("failed at the second if, validationsuccessful should still be false? ", this.state.validationsuccessful);
  //   objForUpdatingState.validationsuccessful = false;// but it is false by default anyway.
  //   objForUpdatingState.validateTIMESPENT = false;
  // } //
  // else {
  //   objForUpdatingState.validateTIMESPENT = true;
  // }

  if((this.state.typedInDataEntryFromInput.length === 0) || (this.state.typedInDataEntryFromInput.length > 20000) || (this.state.typedInDataEntryFromInput === "Please type in your data entry here."))
  {
    //console.log("failed at the third if, validationsuccessful should still be false? ", this.state.validationsuccessful);
    objForUpdatingState.validationsuccessful = false;// but it is false by default anyway.
  }
  else {
    objForUpdatingState.validateDATAENTRY = true;
  }

  if(objForUpdatingState.validateNODE && objForUpdatingState.validateTITLE && objForUpdatingState.validatePARENT && objForUpdatingState.validateDATAENTRY)
  {
    objForUpdatingState.validationsuccessful = true
  }

  this.setState(objForUpdatingState);



  // if((this.state.typedInNodeFromInput.length === 0) || (this.state.typedInNodeFromInput.length > 22) || (this.state.typedInNodeFromInput === "Please type in node name here..."))
  // {
  //   this.setState({validationsuccessful: false});
  // }

  //console.log(arg);



}
wantToRead = (row, index) =>
{
// let iftextandcodearticleisempty = "";
// for (let y in this.props.dataentries)
// {
//
//   if(this.props.dataentries[y]._id === row._id)
//   {
//     //console.log("row.title", row.title);
//     let options = { year: 'numeric', month: 'long', day: 'numeric' };
//
//      //this.setState({wantToRead: row.dataentry,wantToReadTitle: row.title, wantToReadDate: row.date.toLocaleDateString("en-US", options), articleMode: true}); break;
//      this.setState({wantToRead: row.textandcodearticle, wantToReadTitle: row.title, wantToReadDate: row.date.toLocaleDateString("en-US", options), articleMode: true, wantToReadContainsCode: row.containscode, wantToReadContentWithoutCode: row.dataentry});
//
//    }
//  }
this.setState({editor: 2, articleMode: true, wantToRead: (row.lzw) ? row.lzw : row.dataentry, rowIndex:index, rowToEdit/*bad naming convention here*/: row, savedEditor: false, favoriteMode:false, latestEntriesMode: false, wasFavoriteBeforeClickedToRead: this.state.favoriteMode, profileMode: false, NEMode: false, ForMeMode: false, BBMode: false })

}

wantToReadTop10 = (row, index) =>
{
  //let's decompress first

  if (typeof row.dataentry === 'string' || row.dataentry instanceof String)
  {
    row.dataentry = patternizedDecompressor(row.dataentry, this.props.patterns);
  }
  this.wantToRead(row, index)

}
// insertCode = (event) => {
//
//   console.log(this.dataentryInput.current.value[this.input.selectionStart]);
//   var output = `${this.input.value.substring(0, this.input.selectionStart) + `]k6ddxJv]F\n`+this.input.value.substring(this.input.selectionStart, this.input.selectionEnd) + `\n/]k6ddxJv]F` + this.input.value.substring(this.input.selectionEnd)   }`;
//
//   this.input.value = output;
//
//   //this.setState({typedInDataEntryFromInput: output}, this.validateUNSAFEBASIC)//`\n]k6ddxJv]F \n\n /]k6ddxJv]F`
// }

handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
};

handleClick = () => {
    this.setState({ showSuccessMsg: true });
  };

  handleClickOpenYTKD = () => {
      this.setState({ openYTKarmaDialog: true });
    };

// keyPressedAtParentInput = (event) => {
//
//   console.log("keypressed")
//
//   };

fixSingleAnomaly = (nodeWhosParentWillBeUpdated, event) => {

console.log("entered fix anomalies")

this.props.setLoadingOverlayControllerToTrueRef();

let typedparent = this.missingparentInput.current.value.toUpperCase();
if(binarySearchFindNodeThatMatchesPefectly(this.props.nodes_symboltable[typedparent.charAt(0)], typedparent) === undefined)
{
  this.setState({displayGeneralErrorMessge: true, generalerrormessage: "Sorry, you are only allowed to select parents that already exist."});
  this.props.setLoadingOverlayControllerToFalseRef();
  return;
}
const client = Stitch.defaultAppClient;

console.log("reached down to RLTP for some reason.")
try {

  client.callFunction("RLTP", [typedparent]).then(echoedResult => {


  if(echoedResult[0]["ANCESTORS"].includes(nodeWhosParentWillBeUpdated.nodename) === true)
  {
  this.setState({displayGeneralErrorMessge: true, generalerrormessage: "Sorry, a database insertion error occured."});
  return

  }
  else {

    return client.callFunction("deepChange", [nodeWhosParentWillBeUpdated.nodename, typedparent, 1]).then(echoedResult => {

    if(echoedResult === 0){
      //display success message.

      this.props.setLoadingOverlayControllerToFalseRef();

      this.setState({displayGeneralMessage: true, generalmessage: "Anomaly successfully fixed.", localLoadingOverlayControl: true})

      if(this.state.currentlyshownanomaly+1 < this.props.anomalies.length){this.setState({currentlyshownanomaly: (this.state.currentlyshownanomaly+1), validateMISaSINGPARENT: false}) }
      this.missingparentInput.current.value = "Please type in a parent to solve this anomaly.";

      //reburn phase
      this.reburnDB();

    }
    else {
      this.props.setLoadingOverlayControllerToFalseRef();
    }
    })
  }



    })

} catch (e) {
  this.props.setLoadingOverlayControllerToFalseRef();
}

//client.callFunction("maakNuweNeurotag", buildJSObjectForSubmission).then(echoedResult => {



//   let clonednode = {...nodeWhosParentWillBeUpdated}
//   clonednode.parent =  typedparent;
//   let myObjId = new BSON.ObjectId(clonednode._id);
//
// delete clonednode['_id'];
// //console.log("clonednode after using js delete", clonednode);
//
//   let buildJSObjectForSubmission = [clonednode];
//   this.props.fixSingleAnomalyRef(buildJSObjectForSubmission[0].nodename, buildJSObjectForSubmission[0].parent)


}

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ displayGeneralMessage: false,displayGeneralErrorMessge:false, showSuccessMsg: false, showErrorMsg: false, showSuccessfullyDeletedMsg: false, showSuccessfullyEditedEntryMessage: false, showErrorMsgLogicalInconsistency: false, showErrorMsgMustProvideExistingParentForAnom: false, openYTKarmaDialog: false, openEQVoteDialog: false, shoGetPro: false });
  };

onClickedOnSomeNodeInTree = (event, node) => {






let tempnode = node.toUpperCase();
if(tempnode !== "SUPERROOT")
{
//console.log('TEMPNODE', tempnode)
if(this.props.mode === 2)
{
 this.props.fetchOneRef(tempnode, this.props.another_owner_id, this.props.mode);
 this.props.dataarimasuyo.arimasu = false
}


  for (let y in this.props.nodes) {
    //console.log('THIS.PROPS.NODES[Y].NODENAME', this.props.nodes[y].nodename)
    if(this.props.nodes[y].nodename === tempnode)
    {


      this.findChildrenOfParent(event, this.props.nodes[y].parent, tempnode) // event, parent, /thisnode
      break;
  }

  }
  this.setState({selectedNodeOnTree: tempnode});



  if(this.props.mode === 1)
  {
    try {
      let entries = this.props.dataentries_symboltable[node.charAt(0)]


      let startingposition = false
      let endingposition = false
      for(let x = 0; x < entries.length; x++)
      {
        if(entries[x].node === node)
        {

          startingposition = endingposition === false ? x: startingposition;
          endingposition = x;
        }
      }
      //console.log(startingposition, endingposition+1, node);
      if(startingposition === false && endingposition === false )
      {
        this.props.setEntriesRef([], []);
        return;
      }
      let news = entries.slice(startingposition,endingposition+1)


      this.props.setEntriesRef(news, []);
    } catch (e) {
    console.error(e)
    }


  }



}
else {
  console.log("ROOT is the superparent, you cannot go outside your brain!")
}




// this.props.fetchentriesRef(this.props.nodes, event.target.value); this.setState({typedin: event.target.value, newDataEntryMode: false, articleMode: false})}
//this.props.fetchentriesRef(this.props.nodes, tempnode);


}

updateTypedIn = (event) => {

  //this.setState({typedInNodeFromInput: event.target.value, insertingNewEntry: true}, this.validateUNSAFEBASIC);
  this.props.filterNodesRef(event.target.value, 1)
}
updateTypedInTitle = (event) => {

  //this.setState({typedInTitleFromInput: event.target.value}, this.validateUNSAFEBASIC)
}
updateTypedInMinutes = (event) => {

  this.setState({typedInMinutesFromInput: event.target.value}, this.validateUNSAFEBASIC)
}
updateTypedInParent = (event) => {

  this.setState({typedInParentFromInput: event.target.value}, this.validateUNSAFEBASIC)
}
updateTypedInDataEntry = (event) => {
  this.setState({typedInDataEntryFromInput: `${event.target.value}`}, this.validateUNSAFEBASIC)
}
updateTypedInMissingParent = (nodeWhosParentWillBeUpdated, c) => {
  //console.log('NODEWHOSPARENTWILLBEUPDATED', nodeWhosParentWillBeUpdated)



 this.validateUNSAFEBASICMissingParent(nodeWhosParentWillBeUpdated, c);
}


appbarFuncClickedOnBack = () => {
  //Navigation back FROM the editor itself
  if(this.state.wasFavoriteBeforeClickedToRead)
  {
    this.setState({articleMode: false, editMode: false,editNode:false, favoriteMode: true, wasFavoriteBeforeClickedToRead: false});
  }
  else {
    this.setState({articleMode: false, editMode: false,editNode:false});
  }

//Nativagation back via navbar.


}
infoPressed = () => {
  //Navigation back FROM the editor itself

  this.setState({editor: 2,aM: true, articleMode: false, savedEditor: false, favoriteMode:false, latestEntriesMode: false, newDataEntryMode: false, viewAnomaliesMode: false, editNode: false, profileMode: false })
//Nativagation back via navbar.


}

appbarFuncClickedOnProfile = () => {

this.setState({aM: false, profileMode: true, newDataEntryMode: false, articleMode: false,editNode:false, editMode:false, viewAnomaliesMode: false, currAppBarMode: "profileMode", latestEntriesMode:false, favoriteMode: false, favoriteMode:false  })

}

// appbarFuncClickedOnEditNode = () =>
// {
//
//   this.setState({aM: false,newDataEntryMode: false, articleMode: false, editNode:true, viewAnomaliesMode: false,insertingNewEntry: false, newDataEntryMode:false, profileMode: false, latestEntriesMode:false, favoriteMode:false});
//
// }

appbarFuncClickedOnAnomalies = () => {

  this.setState({aM: false,newDataEntryMode: false, articleMode: false,editNode:true, viewAnomaliesMode: true,insertingNewEntry: false, newDataEntryMode:false, profileMode: false, latestEntriesMode:false, favoriteMode:false});
}

appbarFuncClickedOnLatestEntries = () => {

  this.setState({aM: false,articleMode: false, newDataEntryMode: false, editMode: false,editNode:false, insertingNewEntry: false, viewAnomaliesMode: false, profileMode: false, currentlyviewedlatestentries: 0, latestEntriesMode: true, favoriteMode:false});
}

appbarFuncClickedOnFavorites = () => {
  //this.props.setLoadingOverlayControllerToTrueRef();

  this.setState({aM: false,articleMode: false, newDataEntryMode: false, editMode: false,editNode:false, insertingNewEntry: false, viewAnomaliesMode: false, profileMode: false, latestEntriesMode: false, favoriteMode: true});

}

appbarFuncClickedOnSomethingNewStyle = (mode) => {
  //this.props.setLoadingOverlayControllerToTrueRef();
let s = {aM: false,articleMode: false, newDataEntryMode: false, editMode: false,editNode:false, insertingNewEntry: false, viewAnomaliesMode: false, profileMode: false, latestEntriesMode: false, favoriteMode: false, NEMode: false, BBMode: false, ForMeMode: false}
s[mode] = true
if(s["BBmode"])
{
  s["latestEntriesMode"]=true
}

  this.setState(s);

}

// appbarFuncClickedOnAnomalies = () => {
//   this.setState({newDataEntryMode: false, articleMode: false,editNode:false, viewAnomaliesMode: true,insertingNewEntry: false, newDataEntryMode:false, });
// }

appbarFuncSearchedEntry = () => {

  this.setState({aM: false, newDataEntryMode: false, articleMode: false,editNode:false, editMode: false, viewAnomaliesMode: false, profileMode: false, latestEntriesMode:false, favoriteMode:false, NEMode: false, BBMode: false, ForMeMode: false})

}

signout = async () => {

const client = Stitch.defaultAppClient;
const {auth} = client
const logouts = await auth.logout();
//console.log(logouts);

//this.props.setLoadingOverlayControllerToTrueRef();
await this.props.resetMemRef();

this.setState({reburnDB: true})
this.reburnDB();
//this.setState({logouttriggered: true})
// await deleteDB("Nodes", {
//   blocked() {
//     // …
//   },
// });
//
// await deleteDB("NodesOrganized", {
//   blocked() {
//     // …
//   },
// });
//
// await deleteDB("CustomData", {
//   blocked() {
//     // …
//   },
// });
//
// await deleteDB("DataEntriesOrganizedPPP", {
// });
//
// this.setState({logouttriggered: true, reburnDB: true})

}


openShouldDeleteDialog = (row) => {
    this.setState({ openShouldDelete: true, rowToDelete: row });
  };

   calEntVotDial = (rowid, index, wantToUseCard) => {
      this.setState({openEQVoteDialog: true, rowid: rowid, entryindex: index, wantToUseCard})
    };



  handleCloseShouldDeleteDialog = () => {
    this.setState({ openShouldDelete: false });
  };
  handleCloseManageFeedDialog= () => {
    this.setState({ openManageFeedDialog: false });
  };

reburnDB = async () => {

  this.props.resetMemRef();


  deleteDB("DataEntriesOrganizedPPP", {
    blocked() {
      // …
    },
  });

  deleteDB("Nodes", {
    blocked() {
      // …
    },
  });


  deleteDB("NodesOrganized", {
    blocked() {
      // …
    },
  });

  deleteDB("CustomData", {
    blocked() {
      // …
    },
  });

  deleteDB("Anomalies", {
    blocked() {
      // …
    },
  });
// Table deletion phase complete

this.setState({reburnDB: true});
console.log("reburnDB triggered")

}


recursiveFindChild = (node, lookingfor) => {

/*
Usage: careful binarySearch returns an index

FIRST ARGUMENT is the node in the entry box,
SECOND ARGUMENT IS THE parent the user is erroneously trying to set to.

let bees = binarySearchFindNodeThatMatchesPefectly(this.props.nodes, "JAVASCRIPT")
console.log(this.recursiveFindChild(this.props.nodes[bees], "PROGRAMMING"))
**/

//console.log("node", node);
  //base case expression:
// if we get to root just return 1;


if(node.nodename === "ROOT" || node.parent === "")
{
  return 0; //could not find
}

if(node.nodename === lookingfor)
{
  return 1;
}
else {
  return this.recursiveFindChild(this.props.nodes[binarySearchFindNodeThatMatchesPefectly(this.props.nodes, node.parent)], lookingfor)
}

}

brainBridgeWith = async () => {

    const client = Stitch.defaultAppClient;

  let s = await client.callFunction("brainBridgeWith", [this.props.another_owner_id])

try {

  if(s === 0)
  {
//this.setState({displayGeneralMessage: true, generalmessage: "Brain bridged! Note there is a 30 min wait time for the system to register the change. "})
this.dispGenMes("Brain bridged! Note there is a 30 min wait time for the system to register the change. ");
//Must also reburn
  }

} catch (e) {

}
  }

  propagate = async (DEid) => {

    const client = Stitch.defaultAppClient;

  let s = await client.callFunction("propageer", [DEid])

  try {

  if(s.successful === 0)
  {
  this.dispGenMes("You have propgated this entry to your followers for consideration. Click on the middle block to read this entry. ")
  }

  } catch (e) {

  }
}

editorBackButton = (savededitor) => {
  if(this.state.editor === 2)
  {
    if(this.state.wasFavoriteBeforeClickedToRead)
    {
      this.setState({articleMode: false, editMode: false,editNode:false,latestEntriesMode:false, wasFavoriteBeforeClickedToRead: false, favoriteMode: true});
    }
    else {
      this.setState({articleMode: false, editMode: false,editNode:false,latestEntriesMode:false}, ()=>{this.listRef.current.scrollToItem(this.state.rowIndex, "center")});
    }

    return
  }
  if(this.state.editor === 3){
    this.setState({editMode: true, articleMode: false, savedEditor: savededitor});
    return
  }
  if(this.state.editor === 1){


    this.setState({aM: false, articleMode: false, newDataEntryMode: true, savedEditor: savededitor}, ()=>{
      this.nodeInput.current.value = this.state.refStates.nodeInput; //Careful if changed to loadable, when going back a new entry box component will be created and for some reason it seems to to be able to SEE that reference here.
      this.parentInput.current.value = this.state.refStates.parentInput
    this.titleInput.current.value = this.state.refStates.titleInput
      //this.timeInput.current.value = 1;
      //console.log("value", this.state.refStates.privateCheckbox)
      this.privateCheckbox.current.checked = this.state.refStates.privateCheckbox;
      this.favCheckbox.current.checked = this.state.refStates.favCheckbox;
      this.top10Checkbox.current.checked = this.state.refStates.top10Checkbox;



    });

    return
  }
}


  render() {


    //this.getDataEntriesForMeModeMode3()
//this.krySaves()
//this.getDataEntriesPinpointMode3()
//console.log(this.state

// let's determine how many normal cards we will generate
// 50 + random





  // let s = [["A", "B", "C", "D", "M", "T", "Z"], {nodename: "A"}, {nodename: "B"}, {nodename: "C"}, {nodename: "D"}, {nodename: "M"}, {nodename: "T"}, {nodename: "Z"}];
  //
  // console.log(binarySearchFindWhereToInsertNodeNonPerformant(s, "U"))
  //   console.log(binarySearchFindWhereToInsertNodeNonPerformant(s, "0"))
  //     console.log(binarySearchFindWhereToInsertNodeNonPerformant(s, "E"))

//nsole.log(this.state.currentlyviewedlatestentries);

    //In this case we are looking for programming.
//console.log(this.state)



//console.log("dataentry[0]", dataentry[0])
//this.props.dataentries_symboltable[nodename.charAt(0)].splice(indexOfDataEntry, 0, dataentry[0]);//modify memory directly.





if(this.state.reburnDB === true || this.props.user === "default")
{
return <Redirect to="/signin" />
}
// if(this.state.profileMode)
// {
//   return <Redirect to="/profile" />
// }

//
// let students = ['Adam', 'Brad', 'Deepa', 'Hannes', 'Johnny', 'Roger', 'Sarah', 'Yuki'];
// let students2 = ['Adam', 'Deepa', 'Hannes','Jacques', 'Johnny', 'Roger', 'Sarah', 'Yuki'];
//
// students = students.length % 2 === 0 ? students : students2
// //let record = {};
//
// //console.log(Math.floor(Math.random() * students.length));
//
//
//
// let stopper = 100;
//
// for(let x = 0; x < students.length; x++)
// {
//   let startingposition = Math.floor(Math.random() * students.length);
//   let foundafriend = false;
//   let randomsomewhereinarray = false;
//
// while(foundafriend === false)
// {
// startingposition = Math.floor(Math.random() * students.length);
//
// randomsomewhereinarray = Math.floor(Math.random() * students.length);
//
// if(students[randomsomewhereinarray] != students[startingposition] && !Array.isArray(students[startingposition]) && !Array.isArray(students[randomsomewhereinarray]))
// {
//   let temp = students[startingposition];
// students[startingposition] = [students[startingposition], students[randomsomewhereinarray]];
// students[randomsomewhereinarray] = [students[randomsomewhereinarray], temp];
// foundafriend = true;
// }
// if(stopper===100)
// {
//   break;
// }
// //console.log(students)
// stopper++;
// }
// }
//
// console.log(students);




// let string = "KOTARO WA GYUUNYUU JANKEN GA TSUYOI";
// let p = ""
//
// for(let x =0; x < string.length; x++)
// {
//   console.log(string.charCodeAt(x) - 64)
//
//   if(string.charCodeAt(x)-64 === -32)
//   {
//     p += " ";
//   }
//   else {
//     p += (string.charCodeAt(x)-64).toString() + "*"
//   }
//
// }
// console.log(p)


    //this.props.fetchentriesbycontentRef("PWM", "f");
      // console.log(this.props.dataentries)
       //console.table(this.state);
      // console.table(this.state.wantToRead);

      //console.table(this.props.filtered_nodesFULLBODY)
      const { classes } = this.props;
      const client = Stitch.defaultAppClient;
      let isMod3 = this.props.mode === 3


      //let redirect = (this.state.logouttriggered) ? <Redirect to="/signin" /> : false;


       //console.log("show me logic", (!this.state.treemode && ((this.state.newDataEntryMode==true) || (this.state.editMode==true)) ));
      let newEntryBox = (!this.state.treemode && ((this.state.newDataEntryMode==true) || (this.state.editMode==true) || (this.state.editNode==true)) ) ?
      <MyNewEntryBox validateNODE={this.state.validateNODE} nodeInput={this.nodeInput} privateCheckbox={this.privateCheckbox} top10Checkbox={this.top10Checkbox} favCheckbox={this.favCheckbox} newEntryPrevCheckboxOrNew={this.state.savedEditor ? this.state.refStates.privateCheckbox : true} newEntryPrevFavCheckboxOrNew={this.state.savedEditor ? this.state.refStates.favCheckbox : false} newEntryPrev
      CheckboxOrNew={this.state.savedEditor ? this.state.refStates.top10Checkbox : false} prevPrivateCheckBox={this.state.rowToEdit ? this.state.savedEditor ? this.state.refStates.privateCheckbox : this.state.rowToEdit.private : true}
      prevFavCheckBox={this.state.rowToEdit ? this.state.savedEditor ? this.state.refStates.favCheckbox : this.state.rowToEdit.favorite : false}
      prevTop10CheckBox={this.state.rowToEdit ? this.state.savedEditor ? this.state.refStates.top10Checkbox : this.state.rowToEdit.top10 : false}
       nodeInputClicked={this.nodeInputClicked} validateUNSAFEBASICNode={this.validateUNSAFEBASICNode} validateTITLE={this.state.validateTITLE} titleInput={this.titleInput} titleInputClicked={this.titleInputClicked} validateUNSAFEBASICTitle={this.validateUNSAFEBASICTitle} dataentryInput={this.dataentryInput} validatePARENT={this.state.validatePARENT} parentInput={this.parentInput} parentInputClicked={this.parentInputClicked} validateUNSAFEBASICParent={this.validateUNSAFEBASICParent} insertCode={this.insertCode} clear={this.clear} validateDATAENTRY={this.state.validateDATAENTRY} validateUNSAFEBASICDataEntry={this.validateUNSAFEBASICDataEntry} editMode={this.state.editMode} editEntry={this.editEntry} submitNewEntry={this.submitNewEntry} rowToEdit={this.state.rowToEdit} lockInNodeAndParentAfterClickOnIndividualNeurotag={this.state.lockInNodeAndParentAfterClickOnIndividualNeurotag} editNode={this.state.editNode} clickedToDeepChangeNodeOrParent={this.clickedToDeepChangeNodeOrParent} moveToEditor={this.moveToEditor} editor={this.state.editor}/> : false;


       let shoulddelete =
       <Dialog
  open={this.state.openShouldDelete}
  onClose={this.handleCloseShouldDeleteDialog}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this entry?"}</DialogTitle>
  <DialogContent>
  </DialogContent>
  <DialogActions>
    <Button onClick={this.handleCloseShouldDeleteDialog} color="primary">
      No
    </Button>
    <Button onClick={(event)=>{this.handleDeleteNeurotag(event, this.state.rowToDelete)}} color="primary" autoFocus>
      Yes
    </Button>
  </DialogActions>
</Dialog>

      let successmessage = <Snackbar anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }} open={this.state.showSuccessMsg} autoHideDuration={3000}onClose={this.handleClose}>
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="success"
            message="Data entry successfully submitted."
          /></Snackbar>
          let generalmessage = <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }} open={this.state.displayGeneralMessage} autoHideDuration={8000}onClose={this.handleClose}>
              <MySnackbarContentWrapper
                onClose={this.handleClose}
                variant="success"
                message={this.state.generalmessage}
              /></Snackbar>

          let successmessageSingleAnomalyFixed = <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }} open={this.state.showSuccessfullyFixedSingleAnomMessage} autoHideDuration={6000}onClose={this.handleClose}>
              <MySnackbarContentWrapper
                onClose={this.handleClose}
                variant="success"
                message="Successfully fixed an anomaly."
              /></Snackbar>

              let successmessageDeleteEntry = <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }} open={this.state.showSuccessfullyDeletedMsg} autoHideDuration={6000} onClose={this.handleClose}>
                  <MySnackbarContentWrapper
                    onClose={this.handleClose}
                    variant="success"
                    message="Successfully deleted this entry"
                  /></Snackbar>

                  let successmessageEditedEntry = <Snackbar anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }} open={this.state.showSuccessfullyEditedEntryMessage} autoHideDuration={6000}onClose={this.handleClose}>
                      <MySnackbarContentWrapper
                        onClose={this.handleClose}
                        variant="success"
                        message="Successfully edited entry."
                      /></Snackbar>

                      let generalerrormessage2 = <Snackbar
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          open={this.state.displayGeneralErrorMessge}
                          autoHideDuration={6000}
                          onClose={this.handleClose}
                        >
                          <MySnackbarContentWrapper
                            onClose={this.handleClose}
                            variant="error"
                            message={this.state.generalerrormessage}
                          />
                        </Snackbar>

        let errormessage = <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={this.state.showErrorMsg}
            autoHideDuration={6000}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              variant="error"
              message={"Sorry, the data entry failed to submit correctly."}
            />
          </Snackbar>

          let errormessagelogicalinconsistency = <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={this.state.showErrorMsgLogicalInconsistency}
              autoHideDuration={6000}
              onClose={this.handleClose}
            >
              <MySnackbarContentWrapper
                onClose={this.handleClose}
                variant="error"
                message={"There seems to be a conflict. The node you entered likely already exists with another parent. Please ensure you enter the parent that correctly matches up with the node."}
              />
            </Snackbar>

            let errormessagenotexistingparent = <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                open={this.state.showErrorMsgMustProvideExistingParentForAnom}
                autoHideDuration={6000}
                onClose={this.handleClose}
              >
                <MySnackbarContentWrapper
                  onClose={this.handleClose}
                  variant="error"
                  message={"Sorry, you must provide a parent that already exists for an anomaly. If you want to use some node as parent that does not exist yet, go create an entry for that node via New Entry"}
                />
              </Snackbar>

    //           let editor = <div className="App">
    //   <header className="App-header">
    //     Rich Text Editor Example
    //   </header>
    //   <Editor
    //     editorState={this.state.editorState}
    //     onEditorStateChange={this.handleEditorChange}
    //     wrapperClassName="wrapper-class"
    //     editorClassName="editor-class"
    //     toolbarClassName="toolbar-class"
    //   />
    //   <div className="preview" dangerouslySetInnerHTML={this.createMarkup(this.state.ConvertedContent)}></div>
    // </div>

              let editor = (!this.state.treemode && (this.state.articleMode === true)) ?
              <EditorComponent savedEditor={this.state.savedEditor} editorBackButton = {this.editorBackButton} titleInput={this.titleInput} entry={this.state.rowToEdit} submitNewEntry={this.submitNewEntry} wantToRead={this.state.wantToRead} wantToEdit={this.state.wantToEdit} editor={this.state.editor} editEntry={this.editEntry} aM={this.state.aM} handleClickOpenYTKD={this.handleClickOpenYTKD} mode={this.props.mode}/>
              : false;

              let info = (!this.state.treemode && (this.state.aM === true)) ? <Info/> : false




              let favorites = (this.state.favoriteMode) ?
              this.props.custom_data.favorites.map((row, index) => {

                //if(row === false){return;} //if we used the delete chip buttontjie, memory will be set to false and we will force update
                //to update our latest entries.

              return (
                     <div key={row._id} className={classes.box}  >

                       <div className={classes.box1}>
                         <div className={classes.box11}>{row.date.toString().substring(4, 15)}</div>
                         <div className={classes.box12}></div>
                         <div className={classes.box13}>      <Chip
               label={row.node}
               className={classes.chip}
               color="#5a25de"
               onClick={(event) => {this.handleClickOnEntryNeurotag(event, row)}}
             /></div>
                       </div>
                       <div className={classes.box2} onClick={(event)=> {this.wantToRead(row, index)}}>{row.title}
                       </div>
                       <div className={classes.box3}>
                       <div className={classes.divaroundButtons}>

                       <div className={classes.divAroundACCOUNTTREE} onClick={(event)=> {this.handleClickOnEntryNeurotag(event, row)}}>
                       {!isWidthUp('md', this.props.width) ? <div id="isolateaccounttreecss"><i className="material-icons-round md-36" id="material-icons-round md-36mobile">account_tree_rounded</i></div> : <i className="material-icons-round md-36">account_tree_rounded</i>}

                       </div>
                       <div className={classes.divAroundPencil} onClick={(event)=> {this.handleEdit(event, row)}}>
                       {!isWidthUp('md', this.props.width) ? <i className="material-icons-round md-36" id="editsmall">editr</i> :  <i className="material-icons-round md-36" id="edit">editr</i>}

                       </div>

                       </div>

                  </div>

                     </div>
                   )}) : false;

                   let top10 = (this.state.profileMode && this.props.mode === 1) ?
                    this.props.custom_data.top10.map((row, index) => {

                     //if(row === false){return;} //if we used the delete chip buttontjie, memory will be set to false and we will force update
                     //to update our latest entries.

                   return (


                          <div key={row._id} className={classes.box}  >

                            <div className={classes.box1}>
                              <div className={classes.box11}>{row.date.toString().substring(4, 15)}</div>
                              <div className={classes.box12}></div>
                              <div className={classes.box13}>      <Chip
                    label={row.node}
                    className={classes.chip}
                    color="#5a25de"
                    onClick={(event) => {this.handleClickOnEntryNeurotag(event, row)}}
                  /></div>
                            </div>
                            <div className={classes.box2} onClick={(event)=> {this.wantToReadTop10(row, index)}}>{row.title}
                            </div>
                            <div className={classes.box3}>
                            <div className={classes.divaroundButtons}>

                            <div className={classes.divAroundACCOUNTTREE} onClick={(event)=> {this.handleClickOnEntryNeurotag(event, row)}}>
                            {!isWidthUp('md', this.props.width) ? <div id="isolateaccounttreecss"><i className="material-icons-round md-36" id="material-icons-round md-36mobile">account_tree_rounded</i></div> : <i className="material-icons-round md-36">account_tree_rounded</i>}

                            </div>
                            </div>

                       </div>

                          </div>
                        )}) : false;
                        let profmd2 = (this.state.profileMode && this.props.mode === 2)
                        let top10mode2 = profmd2 ?
                         this.props.custom_data_anotheruser.top10.map((row, index) => {

                          //if(row === false){return;} //if we used the delete chip buttontjie, memory will be set to false and we will force update
                          //to update our latest entries.

                        return (


                               <div key={row._id} className={classes.box}  >

                                 <div className={classes.box1}>
                                   <div className={classes.box11}>{row.date.toString().substring(4, 15)}</div>
                                   <div className={classes.box12}></div>
                                   <div className={classes.box13}>      <Chip
                         label={row.node}
                         className={classes.chip}
                         color="#5a25de"
                         onClick={(event) => {this.handleClickOnEntryNeurotag(event, row)}}
                       /></div>
                                 </div>
                                 <div className={classes.box2} onClick={(event)=> {this.wantToRead(row, index)}}>{row.title}
                                 </div>
                                 <div className={classes.box3}>
                                 <div className={classes.divaroundButtons}>

                                 <div className={classes.divAroundACCOUNTTREE} onClick={(event)=> {this.handleClickOnEntryNeurotag(event, row)}}>
                                 {!isWidthUp('md', this.props.width) ? <div id="isolateaccounttreecss"><i className="material-icons-round md-36" id="material-icons-round md-36mobile">account_tree_rounded</i></div> : <i className="material-icons-round md-36">account_tree_rounded</i>}
                                 </div>

                                   <div className={classes.divAroundSend} onClick={()=>{this.propagate(row._id)}}>
                                  <SendIcon className={classes.sendicon}/>
                                   </div>
                                 </div>

                            </div>

                               </div>
                             )}) : false;

      //let displayentries = (!this.state.treemode && (!(this.state.articleMode || this.state.aM || this.state.newDataEntryMode || this.state.editMode || this.state.editNode || this.state.viewAnomaliesMode || this.state.favoriteMode || this.state.profileMode)))

      //let display12orEverything = this.props.filtered_nodes.length>1 ? this.props.dataentries.slice(0,12) : this.props.dataentries
      //console.log(display12orEverything.length)
      let devicesize = !isWidthUp('md', this.props.width)
      let entries = (!this.state.treemode && (!(this.state.aM || this.state.articleMode || this.state.NEMode || (this.state.BBMode&&this.state.latestEntriesMode) || this.state.newDataEntryMode || this.state.editMode || this.state.editNode || this.state.viewAnomaliesMode || this.state.favoriteMode || this.state.profileMode))) ?
      <Entries removeFromDataentriesAfterDelete={this.props.removeFromDataentriesAfterDeleteRef} classifyEntryQuality={this.classifyEntryQuality} listRef={this.listRef} viewUser={this.viewUser} handleClickOnEntryNeurotag={this.handleClickOnEntryNeurotag} wantToRead={this.wantToRead} handleClickOnBox2Summary={this.handleClickOnBox2Summary} handleEdit={this.handleEdit} openShouldDeleteDialog={this.openShouldDeleteDialog} propagate={this.propagate} calEntVotDial={this.calEntVotDial} width={this.props.width} isNotDesktop={devicesize} itemCount= {this.props.dataentries.length} dataentries={this.props.dataentries} isMod3={isMod3} mode={this.props.mode} classes={classes}/> : false;

  // #f44336
      let boxfornodes = (!!this.props.filtered_nodes) && (!this.state.treemode && (!(this.state.aM || this.state.articleMode || this.state.newDataEntryMode || this.state.viewAnomaliesMode|| this.state.editNode || this.state.profileMode || this.state.favoriteMode || (isMod3)))) ?
       <div className={classes.boxForFoundNodes}>
       {this.props.filtered_nodes.map((row, index) => (
         <Chip
      key={index}
      name={row}
      label={row}
      className={classes.chip}
      clickable
      color="#5a25de"
      onClick={(event) => {this.handleClickOnIndividualNeurotag(row, index)}}
      onDelete={(event)=> {this.handleDelete(row)}}
      />
            ))}

       </div> : false;

      let boxfornodesifinsertnewentry = (!this.state.treemode && (this.state.insertingNewEntry && (this.state.entrySearchMode!=true) && (this.state.newDataEntryMode == true) )) || (this.state.editNode === true) ?
      <MyBoxForNodes filtered_nodes={this.props.filtered_nodes} insertingNewEntry={this.state.insertingNewEntry} classes={classes} handleClickOnIndividualNeurotagWhenInsertingNewEntry={this.handleClickOnIndividualNeurotagWhenInsertingNewEntry} editNode={this.state.editNode}/> : false;


      let boxForFoundNodesPinpointedNodes = (this.props.mode === 3 && !this.state.articleMode && !this.state.treemode && !this.state.NEMode && !this.state.ForMeMode && !this.state.BBMode) ? <div><MyBoxForPinpointedNodes pinpointednodes={this.props.pinpointednodes} mode={this.props.mode} fetchEntriesForPinpointedNodeRef = {this.props.fetchEntriesForPinpointedNodeRef} classes={classes} /><MyBoxForSuperFilters classes={classes} fetchEntriesForPinpointedNodeRef = {this.props.fetchEntriesForPinpointedNodeRef} nodedetail={this.props.pinpointNodeClicked} dataentries_cache={this.props.dataentries_cache} setEntriesRef={this.props.setEntriesRef} /></div> : false




      let appbar = (!this.state.treemode) ? <MyAppBar getDataEntriesForMeModeMode3={this.getDataEntriesForMeModeMode3} signout={this.signout} appbarFuncClickedOnBack={this.appbarFuncClickedOnBack} appbarFuncClickedOnProfile = {this.appbarFuncClickedOnProfile}articleMode={this.state.articleMode} editMode={this.state.editMode} appbarFuncClickedOnAnomalies={this.appbarFuncClickedOnAnomalies} appbarFuncClickedOnLatestEntries={this.appbarFuncClickedOnLatestEntries} newEntryClicked={this.newEntryClicked} setLocalStatesToEntryMode={this.setLocalStatesToEntryMode} anomalyCount={this.props.anomalyCount} appbarFuncSearchedEntry={this.appbarFuncSearchedEntry} appbarFuncClickedOnEditNode={this.appbarFuncClickedOnEditNode} appbarFuncClickedOnFavorites={this.appbarFuncClickedOnFavorites} width={devicesize} infoPressed={this.infoPressed} fetchYTStreamInfo={this.fetchYTStreamInfo} showTV={this.state.ytlivestreamimminent} getDataEntriesPinpointMode3={this.getDataEntriesPinpointMode3} appbarFuncClickedOnSomethingNewStyle={this.appbarFuncClickedOnSomethingNewStyle}/> : false;




      let tree =  (this.state.treemode) ? <div className="custom-container">
              <div className={classes.divForTwoButtonsAtTree}>
              <Button variant="contained" size="large" color="primary" className={classes.backbuttonfromtreemode} onClick={() => {
                this.setState({treemode: false})
              }}>
                     BACK
                   </Button>
                   <Button variant="contained" size="large" color="primary" disabled={this.props.mode === 2 ? !this.props.dataarimasuyo.arimasu : this.props.dataentries.length === 0 ? true : false} className={classes.backbuttonfromtreemode} onClick={() => {this.setState({treemode: false}); /*this.props.fetchOne(this.state.selectedNodeOnTree) */}}>
                          VIEW ENTRIES
                        </Button>
              </div>
              <div className={classes.TreeBorder}>
              { <Tree
              	data={this.state.dataForTree ? this.state.dataForTree: false}
              	height={900}
                nodeRadius={devicesize ? 5 : 15}
                animated={false}
              	width={devicesize ? 400: 900}
               margins={{ top: 20, bottom: 10, left: 20, right: 200 }}
               textProps = {{x: 0, y: -20}}
                gProps={devicesize ? {
                  className: 'nodemobile',
                  onClick: this.onClickedOnSomeNodeInTree
                } : { className: 'node', onClick: this.onClickedOnSomeNodeInTree}}
               svgProps={{
     	           className: 'custom',
      		}}/> }

          </div>
                </div> : false

                let anomalyentries = (this.state.viewAnomaliesMode && (this.props.anomalies.length > 0)) ?
                <div className={classes.anomalydivAllEntries}>
                <div className={classes.controlheader}>Fix Anomalies</div>
                <div className={classes.anomalydivOneEntry}>
                <div className={classes.anomalyEntryBox1}>
                {(this.props.anomalies.length > 0) ? this.props.anomalies[this.state.currentlyshownanomaly].nodename : false}
                </div>
                <div className={classes.anomalyEntryBox2}>
                {(this.state.validateMISSINGPARENT) ? <CheckBoxIcon className={classes.checkboxiconOK} /> : <ErrorIcon className={classes.erroricon} />}
                <InputBase className={classes.input} inputRef={this.missingparentInput} defaultValue="Please type in a parent to solve this anomaly." inputProps={{className: classes.input}} onClick={this.missingparentInputClicked} onChange={(e) => this.updateTypedInMissingParent(this.props.anomalies[this.state.currentlyshownanomaly], e)} />
                {(this.state.validateMISSINGPARENT) ? <CheckBoxIcon className={classes.checkboxiconOK} /> : <ErrorIcon className={classes.erroricon} />}
              </div>
                <div className={classes.anomalyEntryBox1}>
                <Button variant="contained" color="primary" disabled={!this.state.validateMISSINGPARENT} className={classes.button} onClick={(e) => {this.fixSingleAnomaly(this.props.anomalies[this.state.currentlyshownanomaly], e)}}>
                Fix
                </Button>
                </div>
                </div>
              }
              <div className={classes.nextpreviousbutndiv} >
              <Button variant="contained" color="primary" className={classes.nextAnom} onClick={() => {if(this.state.currentlyshownanomaly >= 1){this.setState({currentlyshownanomaly: (this.state.currentlyshownanomaly-1)}) }}}>
              PREV
              </Button>
              <div className={classes.anomaliesEntriesNumber}>
              {this.state.currentlyshownanomaly+1} / {this.props.anomalies.length}
              </div>
              <Button variant="contained" color="primary" className={classes.nextAnom} onClick={() => {if(this.state.currentlyshownanomaly+1 < this.props.anomalies.length){this.setState({currentlyshownanomaly: (this.state.currentlyshownanomaly+1)}) }}}>
              NEXT
              </Button>
              </div>
              <div>
              </div>
              <div className={classes.controlheader}>Force Database Refresh</div>
              <div className={classes.forceresync} >
              <Button variant="contained" color="primary" size="large" onClick={() => {this.reburnDB()}}>
              FORCE DATABASE RESYNC
              </Button>
              </div>
               </div> : false;

         let profile = (this.state.profileMode) ? <Profile handleClickOnEntryNeurotag={this.handleClickOnEntryNeurotag} onClickedOnSomeNodeInTree={this.onClickedOnSomeNodeInTree} appbarFuncClickedOnLatestEntries={this.appbarFuncClickedOnLatestEntries}/> : false


   //   this.props.anomalies.map((row,index) => (
   //   <div key={row.id} className={classes.anomalydivOneEntry}>
   //   <div className={classes.anomalyEntryBox1}>
   //   {row.nodename}
   //   </div>
   //   <div className={classes.anomalyEntryBox2}>
   //
   //
   //   {(this.state.validateMISSINGPARENT) ? <CheckBoxIcon className={classes.checkboxiconOK} /> : <ErrorIcon className={classes.erroricon} />}
   //   <InputBase value={this.state.typedInMissingParent} className={classes.input} defaultValue="PLease type in a parent to solve this anomaly." inputProps={{className: classes.input}} onClick={this.missingparentInputClicked} onChange={(e) => this.updateTypedInMissingParent(row, e)} />
   //   {(this.state.validateMISaSINGPARENT) ? <CheckBoxIcon className={classes.checkboxiconOK} /> : <ErrorIcon className={classes.erroricon} />}
   // </div>
   //   <div className={classes.anomalyEntryBox1}>
   //   <Button variant="contained" color="primary" className={classes.button} onClick={(e) => {this.fixSingleAnomaly(row, e)}}>
   //   Fix
   //   </Button>
   //   </div>
   //   </div>))

   //console.log("entries", entries, )

   let karmadialog =
   <div>
   <Dialog
     open={this.state.openYTKarmaDialog}
     onClose={this.handleClose}
     aria-labelledby="form-dialog-title"
   >
     <DialogTitle id="form-dialog-title">Claim YouTube Growth Karma</DialogTitle>
     <DialogContent>
       <DialogContentText>
         Please enter the code mentioned in the youtube lecture to claim today's karma.
       </DialogContentText>
       <TextField
         autoFocus
         margin="dense"
         id="name"
         label="Karma Code"
         type="email"
         fullWidth

         inputRef={this.karmaYTInput}
       />
     </DialogContent>
     <DialogActions>
       <Button onClick={this.handleClose} color="primary">
         Cancel
       </Button>
       <Button onClick={()=> { this.claimYTKarma(this.karmaYTInput.current.value)}} color="primary">
         OK
       </Button>
     </DialogActions>
   </Dialog>
 </div>


      let loadingscreen =
      <div className={this.state.aM ||this.state.articleMode ? classes.divAfterRootdivDuringArticle : classes.divAfterRootdiv}>
      <LoadingOverlay
  active={this.props.isActive || this.state.localLoadingOverlayControl}
  spinner
  text={this.props.isActive ? 'Preparing nodes...' : "Synchronizing database..."}
  className={classes.loadingoverlay}
  >

      <div className={this.state.aM ||this.state.articleMode ? classes.topDivDuringDisplayArticle : classes.topdiv} >

        {
          appbar
        }

        {
          profile
        }
        {
         boxfornodes
        }

          {
            boxfornodesifinsertnewentry
          }
          {
            boxForFoundNodesPinpointedNodes
          }
            {this.state.ForMeMode && <div className={classes.entryoptions}><div className={classes.hoverusername} onClick={() => {
              //setShouldDisplaySF(!shouldDisplaySF)

              this.setState({openManageFeedDialog: true})
              }}>Manage Your Feed</div></div>}          
            {
              entries
            }


            {
              favorites
            }
            {
              top10
            }
            {
              top10mode2
            }
            {
              profmd2 ? <div><div className={classes.hs}>Synergy</div><div className={classes.synergyctrls}><Button onClick={() => {this.brainBridgeWith()}} variant="contained" color="primary" size="large" className={classes.nextAnom}>BRAIN BRIDGE</Button></div></div> : false
            }

            {
              (this.state.latestEntriesMode && this.props.mode!=3 && !this.state.profileMode) ? <div className={classes.nextpreviousbutndiv} >
              <Button variant="contained" color="primary" className={classes.nextAnom} onClick={() => {

                if(this.props.mode === 1)
                {
                this.setState({currentlyviewedlatestentries: this.state.currentlyviewedlatestentries+1}, this.props.fetchlatestentriesRef(this.state.currentlyviewedlatestentries+1)  )
                }
                else {
                  this.setState({currentlyviewedlatestentries: this.state.currentlyviewedlatestentries+1}, this.props.fetchlatestentriesmode2Ref(this.state.currentlyviewedlatestentries+1, this.props.custom_data_anotheruser.owner_id) )
                }

              }}>
              BACK
              </Button>
              <Button variant="contained" color="primary" className={classes.nextAnom} onClick={() => {

                if(this.props.mode === 1)
                {
                  if(this.state.currentlyviewedlatestentries>=1){
                    this.setState({currentlyviewedlatestentries: this.state.currentlyviewedlatestentries-1}, this.props.fetchlatestentriesRef(this.state.currentlyviewedlatestentries-1) )}
                }
                else {

                  if(this.state.currentlyviewedlatestentries>=1){
                    this.setState({currentlyviewedlatestentries: this.state.currentlyviewedlatestentries-1}, this.props.fetchlatestentriesmode2Ref(this.state.currentlyviewedlatestentries-1, this.props.custom_data_anotheruser.owner_id) )}

                }


                } }>
              FORWARD
              </Button>
              </div> : false

            }

       </div>

          {
          this.state.viewAnomaliesMode ? <Control controlheader={classes.controlheader} customdata={this.props.custom_data}/> : false
          }
         {
           newEntryBox
         }
         {
           generalmessage
         }
         {
           generalerrormessage2
         }
         {
           successmessage
         }
         {
           successmessageDeleteEntry
         }
         {
           successmessageEditedEntry
         }
         {
           errormessage
         }
         {
           errormessagelogicalinconsistency
         }
         {
           errormessagenotexistingparent
         }
         {
           tree
         }
          {
          anomalyentries
          }
          {
            shoulddelete
          }

          {
            editor
          }
          {
            info
          }
          {
            karmadialog
          }
          {
            this.state.NEMode && this.props.mode===3 ? <Notifications wantToRead={this.wantToRead}/> : false
          }
          {
            this.state.BBMode && this.props.mode===3 ? <Propagation wantToRead={this.wantToRead} box={classes.box} box1={classes.box1} box11={classes.box11} box12={classes.box12}
            box13={classes.box13} box2={classes.box2} box3={classes.box3} chip={classes.chip} divaroundButtons={classes.divaroundButtons}
            handleClickOnEntryNeurotag={this.handleClickOnEntryNeurotag} divAroundSend={classes.divAroundSend} divAroundVote={classes.divAroundVote} classifyEntryQuality={this.classifyEntryQuality} calEntVotDial={this.calEntVotDial}
            box0={classes.box0} box01={classes.box01} box02={classes.box02} box03={classes.box03} box04={classes.box04} box05={classes.box05} classifyEntryQuality={this.classifyEntryQuality} shoGetPro={this.state.shoGetPro} handleClose={this.handleClose}/> : false
          }
          {
        //  this.state.openEQVoteDialog && <EntryQVotingDialog openEQ={this.state.openEQVoteDialog} handleClose={this.handleClose} rowid={this.state.rowid} classifyEntryQuality={this.classifyEntryQuality}/>
          }

          {
          this.state.openEQVoteDialog && <SuperPowers customdata={this.props.custom_data} getCustomUserData={this.getCustomUserData} handleCloseExternal={this.handleClose} openEQ={this.state.openEQVoteDialog} adsdialog={classes.adsdialog} rowid={this.state.rowid}  entryindex={this.state.entryindex} changeADataEntryRef={this.props.changeADataEntryRef} row={this.state.wantToUseCard} SFLookup={SFLookup}/>
          }

          {
          this.state.openManageFeedDialog && <ForMe openManageFeedDialog={this.state.openManageFeedDialog} handleCloseManageFeedDialog={this.handleCloseManageFeedDialog} classes={this.props.classes} custom_data={this.props.custom_data} getCustomUserData={this.getCustomUserData}/>
          }






       </LoadingOverlay>
</div>



//       <Button variant="contained" className={classes.button} onClick={this.submitNewAlert}>
//  Submit New Neurotag
// </Button>
      let placeholder = <div>u</div>;
      // <div className={classes.box1}>
      //   <div className={classes.box11}>{row.date}</div>
      //   <div className={classes.box12}>{row.timespent}</div>
      //   <div className={classes.box13}>sadasda</div>
      // </div>
      // <div className={classes.box2}>{row.title}
      // <p>{row.dataentry}</p></div>
      // <div className={classes.box3}>box3</div>

      return (
          <Aux>
              {loadingscreen}

          </Aux>
      );
  }
}
const mapStateToProps = state => {
    return {
       //alerts: state.signIn.myalerts,
       user: state.signIn.user,
       //alerts_transformed: state.signIn.myalerts_transformed,
       filtered_nodes: state.signIn.filtered_nodes,//Upon change in this function we know we need to fetch,
       dataentries:  state.signIn.dataentries,
       nodes: state.signIn.nodes,
       isActive: state.signIn.isActive,
       filtered_nodesFULLBODY: state.signIn.filtered_nodesFULLBODY,
       anomalyCount: state.signIn.anomalyCount,
       anomalies: state.signIn.anomalies,
       nodes_symboltable: state.signIn.nodes_symboltable,
       dataentries_symboltable: state.signIn.dataentries_symboltable,
       mode: state.signIn.mode,
       another_owner_id: state.signIn.another_owner_id,
       dataarimasuyo: state.signIn.dataarimasuyo,
       custom_data: state.signIn.custom_data,
       custom_data_anotheruser: state.signIn.custom_data_anotheruser,
       patterns: state.signIn.patterns,
       pinpointednodes: state.signIn.pinpointednodes,
       pinpointNodeClicked: state.signIn.pinpointNodeClicked,
       dataentries_cache: state.signIn.dataentries_cache

    }
  }

const mapDispatchToProps = dispatch => {
    return {

        //additionToTransformedAlertRef: (newAlert) => dispatch(actions.additionToTransformedAlert(newAlert)),
        //updateToTransformedAlertRef: (newAlert, oldAlert) => dispatch(actions.updateToTransformedAlert(newAlert, oldAlert)),
        //deleteToTransformedAlertRef: (oldAlert) => dispatch(actions.deleteToTransformedAlert(oldAlert)),
        //fetchNodesRef: () => dispatch(actions.fetchNodes()),
        deleteFromReduxTooRef: () => dispatch(actions.deleteFromReduxToo()),
        filterNodesRef: (fit, searchornot) => dispatch(actions.filterNodes(fit, searchornot)),
        setPinpointedNodesRef: (pinpointednodes) => dispatch(actions.setPinpointedNodes(pinpointednodes)),
        filterEntriesOnChipClickRef: (dataentry) => dispatch(actions.filterEntriesOnChipClick(dataentry)),
        fetchentriesRef: (nodedataentriestofetch, filter) => dispatch(actions.fetchEntries(nodedataentriestofetch, filter)),
        fetchlatestentriesmode2Ref: (whichtoget,ownerid) => dispatch(actions.fetchLatestEntriesMode2(whichtoget,ownerid)),
        fetchlatestentriesRef: (currentlyviewing) => dispatch(actions.fetchLatestEntries(currentlyviewing)),
        fetchOneRef: (oneToFetch, ownerid, mode) => dispatch(actions.fetchOne(oneToFetch, ownerid, mode)),//Used when clicked on a node in a tree, making sure you don't for example REACT TREE and REACT entries when you only want REACT entries.
        handleDeleteChipDuringSearchRef: (oneToFilterOut) => dispatch(actions.handleDeleteChipDuringSearch(oneToFilterOut)),
        resetFilteredNodesForNewEntryRef: () => dispatch(actions.resetFilteredNodesForNewEntry()),
        setLoadingOverlayControllerToTrueRef: () => dispatch(actions.setLoadingOverlayControllerToTrue()),
        setLoadingOverlayControllerToFalseRef: () => dispatch(actions.setLoadingOverlayControllerToFalse()),
        setEntriesRef: (entries, filterednodes) => dispatch(actions.setEntries(entries, filterednodes)),
        setEntriesForeignRef: (mo, p) => dispatch(actions.setEntriesForeign(mo, p)),
        setCustomDataRef: (st) => dispatch(actions.setCustomData(st)),
        changeToMode2Ref: (ownerid) => dispatch(actions.changeToMode2(ownerid)),
        fetchAnomaliesRef: () => dispatch(actions.fetchAnomalies()),
        //fixSingleAnomalyRef: (objid, nodetofix) => dispatch(actions.fixSingleAnomaly(objid, nodetofix)),
        fetchNodesRef: () => dispatch(actions.fetchNodes()),
        //fetchentriesbycontentRef: (texttosearch, filter, mode) => dispatch(actions.fetchEntriesByContent(texttosearch, filter, mode)),
        updateFilteredNodesF: (texttosearch, filter) => dispatch(actions.fetchEntriesByContent(texttosearch, filter)),
        updateLocalNodesAfterAddingANewOneRef: (newnodetoadd) => dispatch(actions.updateLocalNodesAfterAddingANewOne(newnodetoadd)),
        resetMemRef: () => dispatch(actions.resetMem()),
        removeFromDataentriesAfterDeleteRef: (id) => dispatch(actions.removeFromDataentriesAfterDelete(id)),
        fetchEntriesForPinpointedNodeRef: (nodeinfo, superfilters) => dispatch(actions.fetchEntriesForPinpointedNode(nodeinfo, superfilters)),
        changeADataEntryVoteRef: (dataentryid, votcha, vwei, vote) => dispatch(actions.changeADataEntryVote(dataentryid, votcha, vwei, vote)),
        changeADataEntryRef: (index, key, value) => dispatch(actions.changeADataEntry(index, key, value)),
        changeToSpecificModeRef: (mo) => dispatch(actions.changeToSpecificMode(mo)),



    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(withRoot(withStyles(styles)(withWidth()(Alerts)) )));


// <div className={classes.box} >
//   <div className={classes.box1}>
//     <div className={classes.box11}>5 Jan 2019 </div>
//     <div className={classes.box12}>5 hours</div>
//     <div className={classes.box13}>sadasda</div>
//   </div>
//   <div className={classes.box2}>The flex-basis property specifies the initial size of the flex item before any space distribution happens.
//   <p>The initial value for this property is auto. If the size is not hardcoded but something like auto it will take the max-content size as the flex basis, In other words they will just be the size of their content width if not specified.
//   The initial value for this property is auto. If the size is not hardcoded but something like auto it will take the max-content size as the flex basis, In other words they will just be the size of their content width if not specified.
//   The initial value for this property is auto. If the size is not hardcoded but something like auto it will take the max-content size as the flex basis, In other words they will just be the size of their content width if not specified. </p></div>
//   <div className={classes.box3}>box3</div>
// </div >
