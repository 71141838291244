import * as actionTypes from '../actions/actionTypes';
import { updateObject} from '../../shared/utility';
import { binarySearchForFindingDataEntryByNodeName, patternizedDecompressor } from '../../shared/utility';
//import DOMPurify from 'dompurify';
//import lzwCompress from 'lzwcompress';


const initialState = {
    user: 'default',
    filtered_nodes: [],
    filterfinish: false,
    filtered_nodesFULLBODY: [],
    pinpointednodes: [],
    dataentries: [],
    nodes: [],
    isActive: true,
    anomalies: [],
    anomalyCount: 0,
    dataentries_symboltable: {},
    nodes_symboltable: {},
    nodbISdetected: false,
    mode: 1,//1 localmode, 2: otherbrain 3: neurosphere.
    another_owner_id : "",
    dataarimasuyo: {arimasu: false},
    custom_data: {top10: [], impnodes: [], favorites: [], username: "defaultusername"},
    custom_data_anotheruser: {top10: [], impnodes: [], favorites: [], username: "defaultusername", karma: []},//
    patterns: [
      {//completely unstyled pattern p1, no offsets , unstlyed with offsets will be separately handled
        key: "d812f",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      { //completely unordered-list-item pattern p2, no offsets , unstlyed with offsets will be separately handled
        key: "d812f",
        text: "",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      { //completely unordered-list-item pattern p2, no offsets , unstlyed with offsets will be separately handled
        key: "d812f",
        text: "",
        type: "ordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      { //completely unordered-list-item pattern p2, no offsets , unstlyed with offsets will be separately handled
        key: "d812f",
        text: "",
        type: "code",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      }
    ],

};





const resetFilteredNodesForNewEntry = (state , action) =>
{

const updatedState = {
    filtered_nodes: [],

  }
return updateObject( state, updatedState );
}

const changeModeFrom1to3AndBack = (state , action) =>
{
const updatedState = {
    mode: state.mode === 1 ? 3 : 1,
    nodes: state.mode != 1 ? state.filtered_nodesFULLBODY : state.nodes,
    filtered_nodesFULLBODY: state.mode === 1 ? state.nodes : state.filtered_nodesFULLBODY,
    dataentries: [],
    pinpointednodes: []

  }
return updateObject( state, updatedState );
}

const changeToSpecificMode = (state , action) =>
{
const def = action.def
const updatedState = {
    mode: def,
  }
return updateObject( state, updatedState );
}

// const fixSingleAnomaly = (state , action) =>
// {
//
// const updatedState = {
//     filtered_nodes: [],
//
//   }
// return 1;
// }

const setLoadingOverlayControllerToTrue = (state , action) =>
{

const updatedState = {
    isActive: true,

  }
return updateObject( state, updatedState );
}

const setLoadingOverlayControllerToFalse = (state , action) =>
{

const updatedState = {
    isActive: false,

  }
return updateObject( state, updatedState );
}

// const fetchAlerts = (state , action) =>
// {
// const def = action.alerts
// const updatedState = {
//     myalerts: def,
//
//   }
// return updateObject( state, updatedState );
// }

// const callSomeFunctionFromStitch = (state , action) =>
// {
// const def = action.alerts
// const updatedState = {
//     myalerts: def,
//
//   }
// return updateObject( state, updatedState );
// }

const fetchNodes = (state , action) =>
{
const def = action.nodes

//
const updatedState = {
    nodes: def,

  }
return updateObject( state, updatedState );
}

const updateLocalNodesAfterAddingANewOne = (state , action) =>
{
const def = action.nodetoupdate;
let s = [...state.nodes];
s.push(def);



//
const updatedState = {
    nodes: s,

  }
return updateObject( state, updatedState );
}

const fetchEntries = (state , action) =>
{
const def = action.nodes

//
const updatedState = {
    nodes: def,


  }
return updateObject( state, updatedState );
}

const removeFromDataentriesAfterDelete = (state , action) =>
{
const def = action.def

let s = state.dataentries.filter(function(el) { return el._id != def; });

//
const updatedState = {
    dataentries: s,


  }
return updateObject( state, updatedState );
}

const fetchEntriesByContent = (state , action) =>
{
const def = action.nodes

const updatedState = {
    nodes: def,


  }
return updateObject( state, updatedState );
}

const fetchEntriesForPinpointedNode = (state , action) =>
{

const updatedState = {
  }
return updateObject( state, updatedState );
}


// const fetchEntriesByContent = (state , action) =>
// {
// const updatedState = {
//   nodes: [],
//   }
// return updateObject( state, updatedState );
// }

const fetchAnomalies = (state , action) =>
{
//const def = action.nodes

//
const updatedState = {
    //nodes: def,


  }
return updateObject( state, updatedState );
}

const fetchLatestEntries = (state , action) =>
{
//const def = state.nodes

//
const updatedState = {
    //nodes: def,

  }
return updateObject( state, updatedState );
}

const fetchLatestEntriesMode2 = (state , action) =>
{
//const def = state.nodes

//
const updatedState = {
    //nodes: def,

  }
return updateObject( state, updatedState );
}

const fetchLatestEntriesMode3 = (state , action) =>
{
//const def = state.nodes

//
const updatedState = {
    //nodes: def,

  }
return updateObject( state, updatedState );
}

// const fetchFavorites = (state , action) =>
// {
// //const def = state.nodes
//
// //
// const updatedState = {
//     //nodes: def,
//
//   }
// return updateObject( state, updatedState );
// }

const fetchOne = (state , action) =>
{
const def = action.nodes

//
const updatedState = {
    nodes: def,


  }
return updateObject( state, updatedState );
}
// const fetchAlertsClosed = (state , action) =>
// {
// const def = action.alerts
//
// //
// const updatedState = {
//     myalerts: def,
//
//   }
// return updateObject( state, updatedState );
// }

// const additionToTransformedAlert = (state , action) =>
// {
// const def = action.newAlert
// const oldArray = state.myalerts_transformed
// oldArray.push(def)
// const updatedState = {
//     myalerts_transformed: oldArray,
//   }
// return updateObject( state, updatedState );
// }

// const updateToTransformedAlert = (state , action) =>
// {
//
// //console.log("updateToTransformedAlert ----------------------------------------");
//   //const data = this.state.data;
//   //const index = data.indexOf(oldData);
//   //data[index] = newData;
//   //this.setState({ data }, () => resolve());
//
// const def = action.newAlert
// const oldAlert = action.oldAlert
// const oldArray = state.myalerts_transformed
//
// const index = oldArray.indexOf(oldAlert);
// oldArray[index] = def;
//
// //console.table("oldArray in signin.js", oldArray);
//
// const updatedState = {
//     myalerts_transformed: oldArray,
//   }
// return updateObject( state, updatedState );
// }
// const deleteToTransformedAlert = (state , action) =>
// {
//
// //console.log("deleteToTransformedAlert ----------------------------------------");
//
// const oldAlert = action.oldAlert
// const oldArray = state.myalerts_transformed
// const index = oldArray.indexOf(oldAlert);
// oldArray.splice(index, 1)//Splice 1 item from this starting point
// const updatedState = {
//     myalerts_transformed: oldArray,
//   }
// return updateObject( state, updatedState );
// }
const setUser = (state , action) =>
{
const def = action.user
const updatedState = {
    user: def,
  }
return updateObject( state, updatedState );
}

const changeToMode2 = (state , action) =>
{
const def = action.newownerid
const updatedState = {
    another_owner_id: def,
    mode: 2
  }
return updateObject( state, updatedState );
}


const deleteFromReduxToo = (state , action) =>
{
const updatedState = {
    dataentries_symboltable: state.dataentries_symboltable,
  }
return updateObject( state, updatedState );
}

// const setAlerts = (state , action) =>
// {
// const def = action.alerts
//
// console.log("Linked? : ", def);
// const lookupTablePairs = ['AUDUSD','AUDJPY','AUDNZD','AUDCAD','CADJPY', "EURCAD", 'EURJPY','EURUSD','EURAUD', 'EURNZD',
//  'EURZAR', 'GBPJPY', 'GBPAUD',  'GBPNZD',  'GBPUSD',  'GBPZAR','NZDJPY',  'USDCAD',  'USDCHF',  'USDNZD', 'USDZAR','USDJPY','ZARJPY'  ]
// const lookupTableFXPAIRPROPERTY = ['price', '4 hour candle', 'daily candle', 'weekly candle','monthly candle' ]
// const lookupTableFXPAIRPROPERTYBEHAVIOUR = ['breaks above','breaks below' ]
// const lookupTableDOACTION = ['send me an email',]
//   //can use the closing candle logic here
//
//  console.log("lookupTablePairs",lookupTablePairs[1] )
//  //Go and parse the ISO date
//
// const newArr = state.myalerts_transformed.slice();
// def.forEach((element) => {
//   var date = new Date(element.date);
//
//   newArr.push({'_id': element._id, 'fxpair': lookupTablePairs.indexOf(element.fxpair)+1, 'fxpairproperty': lookupTableFXPAIRPROPERTY.indexOf(element.fxpairproperty)+1,
//   'fxpairpropertybehaviour': lookupTableFXPAIRPROPERTYBEHAVIOUR.indexOf(element.fxpairpropertybehaviour)+1, 'price': element.price, 'doaction': lookupTableDOACTION.indexOf(element.doaction)+1, 'dateadded': date.toLocaleString(undefined, {
//     day: '2-digit',
//     month: '2-digit',
//     year: 'numeric'
// }) })
//   console.log("newArr", newArr)
// });
//
//
// const updatedState = {
//     myalerts: def,
//     myalerts_transformed: newArr
//   }
// return updateObject( state, updatedState );
// }

const setNodes = (state , action) =>
{
const def = action.nodes




const updatedState = {
    nodes: def,
    isActive: false,
  }
return updateObject( state, updatedState );
}

const setPinpointedNodes = (state , action) =>
{
const def = action.pinpointednodes
const updatedState = {
    pinpointednodes: def
  }
return updateObject( state, updatedState );
}



const setDataEntriesSymbolTable= (state , action) =>
{
const def = action.symboltable

const updatedState = {
    dataentries_symboltable: def
  }
return updateObject( state, updatedState );
}

const setCustomData= (state , action) =>
{
const def = action.customdata

try{


for(let x in def.favorites)
{
def.favorites[x].dataentry = patternizedDecompressor(def.favorites[x].dataentry, state.patterns)
}
}
catch(e){
//console.error(e)
}

if(state.mode != 2)
{
  const updatedState = {
      custom_data: def
    }
  return updateObject( state, updatedState );
}
if(state.mode === 2)
{
  const updatedState = {
      custom_data_anotheruser: def
    }
  return updateObject( state, updatedState );
}

}

const setNodesSymbolTable= (state , action) =>
{
const def = action.nodesymboltable

const updatedState = {
    nodes_symboltable: def,
  }
return updateObject( state, updatedState );
}
const resetMem = (state , action) =>
{

const updatedState = {
  myalerts: [],
  user: 'default',
  filtered_nodes: [],
  filterfinish: false,
  filtered_nodesFULLBODY: [],
  dataentries: [],
  nodes: [],
  isActive: true,
  anomalies: [],
  anomalyCount: 0,
  dataentries_symboltable: {},
  nodes_symboltable: {},
  nodbISdetected: false,
  mode: 1,//1 localmode, 2: otherbrain 3: neurosphere.
  another_owner_id : "",
  dataarimasuyo: {arimasu: false},
  custom_data: {top10: [], impnodes: [], favorites: [], username: "defaultusername"},
  custom_data_anotheruser: {top10: [], impnodes: [], favorites: [], username: "defaultusername"},//
  }
return updateObject( state, updatedState );
}
const setEntries = (state , action) =>
{
const def = action.entries

//try
// {
// if(def[0].lzw != undefined)
// {
//
//     for(let x in def)
//     {
//     def[x].lzw = lzwCompress.unpack(def[x].lzw);
//     //console.error("def[x].lzw",   def[x].lzw)
//     def[x].lzw = DOMPurify.sanitize(def[x].lzw);
//     //console.error("DOMPurify.sanitize(def[x].lzw)", DOMPurify.sanitize(def[x].lzw))
//     def[x].lzw = JSON.parse(def[x].lzw);
//     //console.error("JSON.parse(def[x].lzw)", def[x].lzw)
//     }
//
// }
//
// }
// catch(e){
//   console.error(e)
// }



if(state.mode != 1 ) //if not local
{
  // try
  // {
  //   for(let x in def)
  //   {
  //   //def[x].lzw = lzwCompress.unpack(def[x].lzw);
  //   //console.error("def[x].lzw",   def[x].lzw)
  //   //def[x].lzw = DOMPurify.sanitize(def[x].lzw);
  //   //console.error("DOMPurify.sanitize(def[x].lzw)", DOMPurify.sanitize(def[x].lzw))
  //   //def[x].lzw = JSON.parse(def[x].lzw);
  //   //console.error("JSON.parse(def[x].lzw)", def[x].lzw)
  //
  //   //def[x].dataentry = patternizedDecompressor(def[x].dataentry, state.patterns)
  //   }
  // }
  // catch(e){
  //   console.error(e)
  // }

  const filt = action.filterednodes

  const updatedState = {
      dataentries: def,
      filtered_nodes: filt,
      isActive: false,
      dataarimasuyo: typeof def === 'object' ? {arimasu: true} : {arimasu: false},

    }

    return updateObject( state, updatedState );

  }


// if local
const filt = action.filterednodes
let s = [...def];
const updatedState = {
    dataentries: s,
    filtered_nodes: filt,
    isActive: false,


  }
return updateObject( state, updatedState );
}

const setEntriesForeign = (state , action) =>
{
  //used for entries we know will need to be decompressed for suer.
const def = action.entries


    for(let x in def)
    {
      try
      {

    def[x].dataentry = patternizedDecompressor(def[x].dataentry, state.patterns)
      }
      catch(e){
        console.error(e)
      }
    }



const filt = action.filterednodes
let s = [...def];
const updatedState = {
    dataentries: s,
    filtered_nodes: filt,
    isActive: false,
    dataarimasuyo: typeof def === 'object' ? {arimasu: true} : {arimasu: false}
  }
return updateObject( state, updatedState );
}

const setEntriesForeignPlusExtraState = (state , action) =>
{
  //used for entries we know will need to be decompressed for suer.
const def = action.entries


    for(let x in def)
    {
      try
      {

    def[x].dataentry = patternizedDecompressor(def[x].dataentry, state.patterns)
      }
      catch(e){
        console.error(e)
      }
    }

const filt = action.filterednodes
const nodename = action.newstatevalue
let s = [...def];
const updatedState = {
    dataentries: s,
    filtered_nodes: filt,
    pinpointNodeClicked: nodename,
    dataentries_cache: s
  }
return updateObject( state, updatedState );
}

const setAnomalies = (state , action) =>
{
const def = action.anomalies
const anomalyCount = def.length;

const updatedState = {
    anomalies: def,
    anomalyCount: anomalyCount,

  }
return updateObject( state, updatedState );
}

const filterEntriesOnChipClick = (state , action) =>
{
  /*
  Used during search function to filter out the dataentries and only display the ONE that you click on the Chip.
  */
const dataentryname = action.dataentry

//let's check if if it falls right in the middle.
let dataentries = state.dataentries;

let x = Math.round(dataentries.length/2);
let searchfromfirstorsecondhalf = dataentries[x-1].node;


let isInFirstHalf = searchfromfirstorsecondhalf > dataentryname; // if true it will mean that x is in first half


let startingindex = isInFirstHalf ? 0 : x;
let endingindex = isInFirstHalf ? x : dataentries.length;
let storage = [];

if(searchfromfirstorsecondhalf === dataentryname) // if there are several entries overlapping over the middle, we will just go through until we can;t find no more.
{
  startingindex = 0;
  endingindex = dataentries.length;
}

//let shouldBreak = false; //used to stop filtering once all are gotten
for(let y = startingindex; y < endingindex; y++)
{
  if(dataentries[y].node === dataentryname)
  {
    storage.push(dataentries[y])
  }
  else {
    //(storage.length > 0) ? break : false;
    if(storage.length)
    {
      break;
    }
  }

}


  const updatedState = {
    dataentries: storage,
    filtered_nodes: [],

    }
  return updateObject( state, updatedState );
}
const filterNodes = (state , action) =>
{


try {

  const def = action.filter.toUpperCase();
  const SORNOT = action.searchornot
  //console.log('SORNOT', SORNOT)


// this.props.filterNodesRef("", 3)

  //const nodes = state.nodes

//this.props.filterNodesRef(this.nodeInput.current.value, 1)

var step2 = [];

if(1 === 1)
{
// function myFunction(item, index) {
//   step2.push(item.nodename);
// }
if(SORNOT === 1)//First character searched or first character selected for new entry box.
{
//    console.log("Search triggered")
//    console.log("node length", state.nodes.length);
    // var lucky = state.nodes_symboltable[def].filter(function(element) {//lucky contains the full node list and must also be saved in order to be accessible by the insert form
    //   //console.log(" element.nodename.charAt(0) === def", element.nodename.charAt(0),def )
    //   return element.nodename.charAt(0) === def;
    // });

    var lucky = [];

try {
  var stoor = state.nodes_symboltable[def.charAt(0)];

  for(let o = 1; o < stoor.length; o++)
  {
    if(stoor[o].nodename.charAt(0) === def)
    {
      lucky.push(stoor[o]);
      //fdnodes.push(state.nodes_symboltable[def.charAt(0)][o])
    }
  }


  if(def === "MOETALMALWEGVATBEHALWELAASTEEEN")
  {
    step2 = [];
  }
  else {
    step2 = stoor[0];
  }


} catch (e) {

}


    //lucky.forEach(myFunction);

    //let chosennodesymbolbatch = binarySearchForFindingDataEntryByNodeName()

    const updatedState = {
        filtered_nodes: step2,
        filtered_nodesFULLBODY: lucky,

      }
    return updateObject( state, updatedState );
}
if(SORNOT === 0)//after that just filter the Result
{

  //console.log("!SORNOT")
  // lucky = state.nodes.filter(function(element) {
  //   return element.nodename === def;
  // });// Right now is supposed to filter the nodes but does not so filternodes is just kimd of hanging in the air.
  var step2 = [];
  //lucky.forEach(myFunction);



  let newlocaldataentries = state.dataentries.filter(function(element) {
      return (element.node.substring(0, def.length) === def);
    });

    let localnodes = state.filtered_nodesFULLBODY.filter(function(element) {
        if(element.nodename.substring(0, def.length) === def ){
          step2.push(element.nodename)
          return true;
        };
      });

    const updatedState = {
        filtered_nodesFULLBODY: localnodes,
        dataentries: newlocaldataentries,
        filtered_nodes: step2

      }
    return updateObject( state, updatedState );
}
if(SORNOT === 2)//after that just filter the Result
//This is done if a delete has been clicked on a chip, it will filter out all entries NOT equal to the
//the one you delete, leaving those behind.
{
  //console.log("SORNOT === 2")
  lucky = state.filtered_nodes.filter(function(element) {
    return (element != def);
  });
  var step2 = [];
  //lucky.forEach(myFunction);
  //console.log("lucky", lucky)




  //console.log("Not Search triggered")
  var newlocaldataentries = state.dataentries.filter(function(element) {
      return (element.node != def);
    });

    const updatedState = {
        filtered_nodes: lucky,
        dataentries: newlocaldataentries,
        filtered_nodesFULLBODY: lucky,

      }
    return updateObject( state, updatedState );
  }
}
else { //does have a db to use
  if(def.length === 1)
  {
    let nst = state.nodes_symboltable
    let nodes = state.nodes
    if(nst[def.charAt(0)] === undefined)
    {
      return updateObject( state, updatedState );
    }
    const node_st = nst[def.charAt(0)][0];// accessing the array all nodes in alphabetic order for quick use
//    console.log("filter nst", nst)
    //console.log("node_st", node_st);
    const updatedState = {
        filtered_nodes: node_st,
        //dataentries: newlocaldataentries,//what is this?
        filtered_nodesFULLBODY: nst[def.charAt(0)],

      }
    return updateObject( state, updatedState );
  }
  if(def.length > 1) // use binary search to extract relevant nodes.
  {
    let nst = state.nodes_symboltable

    if(nst[def.charAt(0)] === undefined)// tried to access an initial that does not exist.
    {
        return updateObject( state, updatedState );
    }
    const node_st = nst[def.charAt(0)];// accessing the array all nodes in alphabetic order for quick use
    //console.log(node_st, def);

    let foundsinglematchingnodeat = binarySearchForFindingDataEntryByNodeName(node_st, def, 1);
    //console.log('FOUNDSINGLEMATCHINGNODEAT', foundsinglematchingnodeat)



    let filtnodes = [];
    let keeptrackfiltnodes = {};
    let filtnodesfullbody = [];

    if(foundsinglematchingnodeat === undefined)
      {
        const updatedState = {
            //filtered_nodes: filtnodes,
            //dataentries: newlocaldataentries,//what is this?
            //filtered_nodesFULLBODY: nodes[0],

          }
        return updateObject( state, updatedState );
      }
//     console.log("found at index ", foundnodeatindex)

    //now let's find the entries that are actually applicable.
    let totheleft = (foundsinglematchingnodeat === 1) ? 1 : foundsinglematchingnodeat;
  //  console.log("totheleft", totheleft)
    let totheright =  (foundsinglematchingnodeat === node_st.length-1) ? node_st.length-1 : foundsinglematchingnodeat;
    //console.log("totheright", totheright)
    let startingelement = totheleft
    let endingelement = totheright
    let leftendflag = false;
    let rightendflag = false;


    while(true){

      if(leftendflag === false)
      {
      let leftcondition = node_st[totheleft].nodename.substring(0, def.length) === def
        if(leftcondition)
        {

          if(keeptrackfiltnodes[node_st[totheleft].nodename] === undefined)
          {filtnodes.push(node_st[totheleft].nodename); filtnodesfullbody.push(node_st[totheleft])}
          keeptrackfiltnodes[node_st[totheleft].nodename] = true;

          startingelement = totheleft;
          if(totheleft > 1)
          {
          totheleft--;
          }
          else {
            leftendflag = true;
          }


        }
        else {
          leftendflag = true;
          //filtnodes.push(chosensymbolbatch[totheleft].node);
        }
      }
      if(rightendflag === false)
      {
        let rightcondition = node_st[totheright].nodename.substring(0, def.length) === def;
        if(rightcondition)
        {

          if(keeptrackfiltnodes[node_st[totheright].nodename] != true)
          {filtnodes.push(node_st[totheright].nodename); filtnodesfullbody.push(node_st[totheright])}
          keeptrackfiltnodes[node_st[totheright].nodename] = true;

          endingelement = totheright;

          if(totheright < node_st.length-1)
          {
          totheright++;
          }
          else {
            rightendflag = true;
          }
        }
        else {
          rightendflag = true;
          //filtnodes.push(chosensymbolbatch[totheleft].node);
        }
      }

      if(rightendflag === true && leftendflag === true)
      {
//          console.log("broke out of while at", startingelement, endingelement );
        break;
      }

    }

    filtnodes = (SORNOT === 0) ? [] : filtnodes;

    filtnodes = (SORNOT === 2 && filtnodes.length === 1) ? [] : filtnodes;

    if(SORNOT === 3)
    {
      filtnodes = [];
    }


  //filtnodes = [];


  //  console.log("filtbodes", filtnodes, SORNOT);


    const updatedState = {
        filtered_nodes: filtnodes,
        //dataentries: newlocaldataentries,//what is this?
        filtered_nodesFULLBODY: filtnodesfullbody,

      }
    return updateObject( state, updatedState );

    }
    //extract surrounding.
    const updatedState = {
        //filtered_nodes: filtnodes,
        //dataentries: newlocaldataentries,//what is this?
        //filtered_nodesFULLBODY: nodes[0],

      }
    return updateObject( state, updatedState );

  }

} catch (e) {
  console.log(e)
}

}


const handleDeleteChipDuringSearch = (state , action) =>
{
  /*
  Used during search function to filter out the dataentries and only display the ONE that you click on the Chip.
  */
const dataentryname = action.oneToFilterOut
const nodes = state.filtered_nodes;
let dataentries = state.dataentries;
let nodex = [];







// let found = false;


//let nodesxrecord = {}; //necessary to keep track if node exists already

// let counterfornodes = 0;
//
//
// for(let x = 0; x < state.dataentries.length; x++)
// {
//   if(state.dataentries[x].node === dataentryname)
//   {
//     state.dataentries[x] = false;
//     found = true; //set the flag to true,
//   }
//   else { // the node we are checking is different from the one we want to filter...:
//     if(found)// AND found was previously determined to be true, it means we have moved on and exhausted setting the necessaries to false.
//     {
//       break;
//     }
//   }
//
//
//
//
//
//   // use the entries for for nodes to save iterations
//
// }


//console.table(dataentrieswithouttheone);
//lucky.forEach(myFunction);
//console.log("lucky", lucky)
dataentries = dataentries.filter(function(element) {
  return (element.node != dataentryname);
});

nodex = nodes.filter(function(element) {
  return (element != dataentryname);
});

//console.log("Not Search triggered")
// var newlocaldataentries = state.dataentries.filter(function(element) {
//     return (element.node != def);
//   });
  const updatedState = {
  //    filtered_nodes: lucky,
      dataentries: dataentries,
      filtered_nodes: nodex,
  //    filtered_nodesFULLBODY: lucky,

    }
  return updateObject( state, updatedState );

}


const changeADataEntryVote = (state , action) =>
{

const dataentryid = action.oneToFilterOut
let dataentries = [...state.dataentries];
let votcha = action.votcha
let vwei = action.vwei

for(let x = 0; x < dataentries.length; x++)
{
  if(dataentries[x]._id === dataentryid)//found
  {
    //console.log("Found at index", x)
    let p = {...dataentries[x]}
    p[votcha] = dataentries[x][votcha] === undefined ? (vwei) : (dataentries[x][votcha] + vwei);
    dataentries[x] = p
    dataentries[x].qclass = action.vote


    //console.log(p, vwei)

  }
}




//console.log("Not Search triggered")
// var newlocaldataentries = state.dataentries.filter(function(element) {
//     return (element.node != def);
//   });
  const updatedState = {
  //    filtered_nodes: lucky,
      dataentries: dataentries,
  //    filtered_nodesFULLBODY: lucky,

    }
  return updateObject( state, updatedState );

}

const changeADataEntry = (state , action) =>
{

let dataentries = [...state.dataentries];
let p = {...dataentries[action.index]}

console.log(p)
p[action.key] = action.value
console.log(p)
dataentries[action.index] = p
  const updatedState = {
      dataentries: dataentries,
    }
  return updateObject( state, updatedState );

}

// const setAndAppendClosedAlertsToo = (state , action) =>
// {
// const def = action.alerts
//
// console.log("Linked? : ", def);
// const lookupTablePairs = ['AUDUSD','AUDJPY','AUDNZD','AUDCAD','CADJPY', "EURCAD", 'EURJPY','EURUSD','EURAUD', 'EURNZD',
//  'EURZAR', 'GBPJPY', 'GBPAUD',  'GBPNZD',  'GBPUSD',  'GBPZAR','NZDJPY',  'USDCAD',  'USDCHF',  'USDNZD', 'USDZAR','USDJPY','ZARJPY'  ]
// const lookupTableFXPAIRPROPERTY = ['price', '4 hour candle', 'daily candle', 'weekly candle','monthly candle' ]
// const lookupTableFXPAIRPROPERTYBEHAVIOUR = ['breaks above','breaks below', 'closes above', 'closes below' ]
// const lookupTableDOACTION = ['send me an email',]
//   //can use the closing candle logic here
//
//  console.log("lookupTablePairs",lookupTablePairs[1] )
//  //Go and parse the ISO date
//
// const newArr = state.myalerts_transformed.slice();
// def.forEach((element) => {
//   var date = new Date(element.date);
//
//   newArr.push({'_id': element._id, 'fxpair': lookupTablePairs.indexOf(element.fxpair)+1, 'fxpairproperty': lookupTableFXPAIRPROPERTY.indexOf(element.fxpairproperty)+1,
//   'fxpairpropertybehaviour': lookupTableFXPAIRPROPERTYBEHAVIOUR.indexOf(element.fxpairpropertybehaviour)+1, 'price': element.price, 'doaction': lookupTableDOACTION.indexOf(element.doaction)+1, 'dateadded': date.toLocaleString(undefined, {
//     day: '2-digit',
//     month: '2-digit',
//     year: 'numeric'
// }) })
//   console.log("newArr", newArr)
// });
//
//
// const updatedState = {
//     myalerts_transformed: newArr
//   }
// return updateObject( state, updatedState );
// }



const reducer = ( state = initialState, action ) => {
//console.log(action.type)
    switch ( action.type ) {
        //case actionTypes.FETCH_ALERTS: return fetchAlerts( state, action );
        //case actionTypes.FETCH_ALERTS_CLOSED: return fetchAlertsClosed( state, action );
        case actionTypes.SET_USER: return setUser( state, action );
        //case actionTypes.SET_ALERTS: return setAlerts( state, action );
        //case actionTypes.SET_AND_APPEND_CLOSED_ALERTS_TOO: return setAndAppendClosedAlertsToo( state, action );
        //case actionTypes.ADDITION_TO_TRANSFORMEDALERT: return additionToTransformedAlert( state, action );
        //case actionTypes.UPDATE_TO_TRANSFORMEDALERT: return updateToTransformedAlert( state, action );
        //case actionTypes.DELETE_TO_TRANSFORMEDALERT: return deleteToTransformedAlert( state, action );
        case actionTypes.FETCH_NODES: return fetchNodes( state, action );
        case actionTypes.SET_NODES: return setNodes( state, action );
        case actionTypes.FILTER_NODES: return filterNodes( state, action );
        case actionTypes.FETCH_ENTRIES: return fetchEntries( state, action );
        case actionTypes.SET_ENTRIES: return setEntries( state, action );
        case actionTypes.FETCH_LATESTENTRIES: return fetchLatestEntries( state, action );
        case actionTypes.RESET_FILTEREDNODES: return resetFilteredNodesForNewEntry( state, action );
        case actionTypes.ACTIVATE_LOADINGOVERLAY: return setLoadingOverlayControllerToTrue( state, action );
        case actionTypes.DEACTIVATE_LOADINGOVERLAY: return setLoadingOverlayControllerToFalse( state, action );
        case actionTypes.FETCH_ONE: return fetchOne( state, action );
        case actionTypes.SET_ANOMALIES: return setAnomalies( state, action );
        case actionTypes.FETCH_ANOMALIES: return fetchAnomalies( state, action );
        //case actionTypes.FIX_SINGLE_ANOMALY: return fixSingleAnomaly( state, action );
        //case actionTypes.CALL_SOME_FUNCTION_FROM_STITCH: return callSomeFunctionFromStitch( state, action );
        case actionTypes.FETCH_ENTRIES_BY_CONTENT: return fetchEntriesByContent( state, action );
        case actionTypes.UPDATE_NODE_AFTER_NEW_NODE_CREATED: return updateLocalNodesAfterAddingANewOne( state, action );
        case actionTypes.SET_DATAENTRIES_SYMBOL_TABLE: return setDataEntriesSymbolTable( state, action );
        case actionTypes.SET_NODES_SYMBOL_TABLE: return setNodesSymbolTable( state, action );
        case actionTypes.FILTER_ENTRIES_ON_CHIP_CLICK: return filterEntriesOnChipClick( state, action );
        case actionTypes.HANDLE_DELETE_CHIP_DURING_SEARCH: return handleDeleteChipDuringSearch( state, action );
        case actionTypes.DELETE_FROM_REDUX_TOO: return deleteFromReduxToo( state, action );
        //case actionTypes.FETCH_FAVORITES: return fetchFavorites( state, action );
        case actionTypes.SET_CUSTOMDATA: return setCustomData( state, action );
        case actionTypes.CHANGE_TO_MODE_2: return changeToMode2( state, action );
        case actionTypes.FETCH_LATESTENTRIES_MODE2: return fetchLatestEntriesMode2( state, action );
        case actionTypes.CHANGEMODEFROM1TO3ANDBACK: return changeModeFrom1to3AndBack( state, action );
        case actionTypes.CHANGE_TO_SPECIFIC_MODE: return changeToSpecificMode( state, action );

        case actionTypes.RESETMEM: return resetMem( state, action );
        case actionTypes.REMOVE_FROM_DE_AFTER_DELETE: return removeFromDataentriesAfterDelete( state, action );
        case actionTypes.SET_ENTRIES_FOREIGN: return setEntriesForeign( state, action );
        case actionTypes.SET_ENTRIES_FOREIGN_PLUS_EXSTRA_STATE: return setEntriesForeignPlusExtraState( state, action );
        case actionTypes.SET_NODES_PINPOINTMODE3: return setPinpointedNodes( state, action );
        case actionTypes.FETCH_ENTRIES_FOR_PINPOINTEDNODE: return fetchEntriesForPinpointedNode( state, action );
        case actionTypes.FETCH_LATESTENTRIES_MODE3: return fetchLatestEntriesMode3( state, action );
        case actionTypes.CHANGE_A_DATAENTRY_VOTE: return changeADataEntryVote( state, action );
        case actionTypes.CHANGE_A_DATAENTRY: return changeADataEntry( state, action );











      //  case actionTypes.GET_DEFAULT2: return setDefaultGrade2Questions( state, action );
      //  case actionTypes.START_PRACTICE: return startPractice( state, action );
      //  case actionTypes.SELECT_GRADE: return selectGrade( state, action );
        //case actionTypes.SET_STUDYTOPS: return setStudyTops( state, action );
        //case actionTypes.SELECT_QUESTIONTOPIC: return selectQuestionTopic( state, action );
        //case actionTypes.SELECT_QUESTIONLIST: return selectQuestionNumber( state, action );
        default: return state;
    }
};

export default reducer;
